import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Location } from "@app/models";
import { UIModule } from "@app/shared/ui/ui.module";
import { TranslocoModule } from "@ngneat/transloco";
import { Observable, defer } from "rxjs";
import { map, startWith, tap } from "rxjs/operators";
import { UiKitModule } from "ui-kit";
import { AccountLocationAssignedItem } from "../account-location-assigner/account-location-assigner.component";
import { LocationAutocompleteSelectComponent } from "../location-autocomplete-select";

export type LocationAssignmentModalOptions = {} & {
  locations: Location[];
  assignedItems: AccountLocationAssignedItem[];
  selectedAccountId: number;
  hasUseForAllLocations?: boolean;
};

@Component({
  selector: "app-location-assignment-modal",
  templateUrl: "./location-assignment-modal.component.html",
  styleUrls: ["./location-assignment-modal.component.scss"],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslocoModule, UIModule, UiKitModule, LocationAutocompleteSelectComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationAssignmentModalComponent implements OnInit {
  locations: Location[];
  assignedItems: AccountLocationAssignedItem[];
  selectedLocationIds: number[] = [];
  hasUseForAllLocations = true;

  form = new FormGroup({
    selectedAccountId: new FormControl<number>(null),
    useForAllLocations: new FormControl<boolean>(false)
  });

  accountLocationList$: Observable<Location[]> = defer(() =>
    this.form.controls.selectedAccountId.valueChanges.pipe(
      startWith(this.form.controls.selectedAccountId.value),
      tap((selectedAccountId) => {
        const items = this.assignedItems.find((accountLocations) => accountLocations.accountId === selectedAccountId);
        if (items) {
          this.selectedLocationIds = [...items.locationIds];
          if (this.hasUseForAllLocations) {
            this.form.patchValue({ useForAllLocations: items.useForAllLocations }, { emitEvent: false });
          }
        }
      }),
      map((selectedAccountId) => this.locations.filter((location) => location.accountId === selectedAccountId))
    )
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: LocationAssignmentModalOptions,
    private dialogRef: MatDialogRef<LocationAssignmentModalComponent>
  ) {}

  get isUsedForAllLocations(): boolean {
    return this.form.controls.useForAllLocations.value;
  }

  ngOnInit() {
    const { locations, selectedAccountId, assignedItems, hasUseForAllLocations } = this.data;

    this.locations = locations;
    this.assignedItems = assignedItems;
    this.hasUseForAllLocations = hasUseForAllLocations ?? this.hasUseForAllLocations;
    this.form.patchValue({ selectedAccountId });

    if (assignedItems.length === 1) {
      this.form.controls.selectedAccountId.disable({ emitEvent: false });
    }
  }

  onUseForAllLocations(useForAllLocations: boolean): void {
    const selectedAccountId = this.form.controls.selectedAccountId.value;
    const selectedLocationsIds = [];

    this.locations.forEach((location) => {
      if (location.accountId === selectedAccountId) {
        location.selected = true;
        selectedLocationsIds.push(location.id);
      }
    });

    this.assignedItems.find((item) => item.accountId === selectedAccountId).locationIds = selectedLocationsIds;
    this.selectedLocationIds = selectedLocationsIds;
    this.applyUseForAllLocations(useForAllLocations);
  }

  onSelectedLocationsChanged(locations: Location[]): void {
    this.mapLocationsToAccount(locations);
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onReset(): void {
    this.onClose();
  }

  onSave(): void {
    this.dialogRef.close(this.assignedItems);
  }

  private applyUseForAllLocations(useForAllLocations: boolean): void {
    const currentAccount = this.form.controls.selectedAccountId.value;
    this.assignedItems = this.assignedItems.map((accountLocations) => {
      if (currentAccount === accountLocations.accountId) {
        accountLocations.useForAllLocations = useForAllLocations;
      }

      return accountLocations;
    });
  }

  private mapLocationsToAccount(locations: Location[]): void {
    const currentAccountId = this.form.controls.selectedAccountId.value;

    this.selectedLocationIds = locations.map((location) => location.id);
    this.assignedItems = this.assignedItems.map((accountLocations) => {
      if (currentAccountId === accountLocations.accountId) {
        accountLocations.locationIds = this.selectedLocationIds;
      }

      return accountLocations;
    });
  }
}
