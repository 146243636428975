<ng-select
  #select
  [ngModel]="selectedOptions"
  (ngModelChange)="selectionChanged($event)"
  [clearable]="clearable"
  [disabled]="disabled"
  [multiple]="true"
  [closeOnSelect]="false"
  [searchable]="true"
  [dropdownPosition]="dropdownPosition"
  [appendTo]="appendTo"
  [placeholder]="'common.placeholder.pleaseSelect' | transloco"
>
  <!-- Input item content: -->
  <ng-template ng-label-tmp let-item="item" let-label="label" let-clear="clear">
    <span class="ng-value-label">{{ label }}</span>

    <span class="ng-value-icon right" (click)="clear(item); handleDropdownPosition(select)" aria-hidden="true">
      <img alt="" src="assets/images/close.svg" width="16" height="16" />
    </span>
  </ng-template>

  <!-- Dropdown item content: -->
  <ng-option *ngIf="isAllSelectionEnabled" [value]="allValue"> {{ "common.components.all" | transloco }} </ng-option>
  <ng-option *ngFor="let item of items" [value]="item[bindValue]"> {{ bindLabelFn(item) }} </ng-option>
</ng-select>
