import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidatorFn, Validators } from "@angular/forms";
import {
  SetReminderExpiration,
  SetReminderFrequency,
  SetReminderWidgetSettings
} from "@app/pages/components/widget-settings/set-reminder-widget-settings/set-reminder-widget-settings";
import { CouponService, EnumRepresentationService, WidgetService } from "@app/services";
import { Patterns } from "@app/shared/validators";
import { Brand, Location, CouponInfo, WidgetType } from "../../../../models";
import { WidgetSettingsPreviewMessageComponent } from "../WidgetSettingsPreviewMessageComponent";
import { TranslocoService } from "@ngneat/transloco";
import { AuthService } from "@app/core";

enum SettingsControl {
  FrequencyType = "frequencyType",
  SendingTime = "sendingTime",
  SetReminderExpiration = "setReminderExpiration",
  SetReminderUrl = "setReminderUrl",
  SelectedCouponId = "selectedCouponId",
  CampaignId = "campaignId"
}

@Component({
  selector: "app-set-reminder-widget-settings",
  templateUrl: "./set-reminder-widget-settings.component.html",
  styleUrls: ["./set-reminder-widget-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: SetReminderWidgetSettingsComponent
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SetReminderWidgetSettingsComponent),
      multi: true
    }
  ]
})
export class SetReminderWidgetSettingsComponent extends WidgetSettingsPreviewMessageComponent implements OnInit, OnDestroy {
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private enumRepresentationService: EnumRepresentationService,
    public couponService: CouponService,
    public widgetService: WidgetService,
    private translateSvc: TranslocoService,
    public authService: AuthService
  ) {
    super(couponService, widgetService, authService);
  }

  _location: Location;
  @Input() set location(value: Location) {
    if (value) this._location = value;
  }

  get location(): Location {
    return this._location;
  }
  @Output() widgetSettingsReady = new EventEmitter();

  @Input() brand: Brand;

  @Input() set widgetSettingsJson(value: string) {
    if (value) {
      this.widgetSetting = JSON.parse(value) as SetReminderWidgetSettings;
    } else {
      this.setCouponsAndPreviewMessage({
        widgetType: WidgetType.SetReminder,
        brandName: this.brand?.name,
        frequencyLabel: this.getFrequencyLabel(),
        locationId: this.location?.id
      });
    }
  }

  public frequencyNames = [
    SetReminderFrequency.Weekly,
    SetReminderFrequency.OncePerTwoWeeks,
    SetReminderFrequency.Monthly,
    SetReminderFrequency.Quarterly
  ];
  public reminderExpirations = [
    SetReminderExpiration.ThreeMonth,
    SetReminderExpiration.SixMonth,
    SetReminderExpiration.TwelveMonth,
    SetReminderExpiration.TwentyFour,
    SetReminderExpiration.ThirtySix,
    SetReminderExpiration.FortyEight
  ];
  public validators: Array<ValidatorFn> = [Validators.pattern(Patterns.url), Validators.maxLength(2000)];

  public reminderFrequencyNameRepresentation: { [status: number]: string };
  public reminderExpirationRepresentation: { [status: number]: string };

  private _widget: SetReminderWidgetSettings;
  private defaultFrequencyType = SetReminderFrequency.Weekly;
  private defaultFrequencyTypeCode = "enums.weekly";

  settingsControl = SettingsControl;

  get frequencyTypeControl(): UntypedFormControl {
    return this.form?.controls[SettingsControl.FrequencyType] as UntypedFormControl;
  }

  public set widgetSetting(value: SetReminderWidgetSettings) {
    if (value && !this._widget) {
      setTimeout(() => {
        this.frequencyTypeControl.setValue(value.frequencyType);
        this.form.controls[SettingsControl.SendingTime].setValue(value.sendingTime);
        this.form.controls[SettingsControl.SetReminderExpiration].setValue(value.setReminderExpiration);
        this.form.controls[SettingsControl.SetReminderUrl].setValue(value.setReminderUrl);
        this.form.controls[SettingsControl.CampaignId].setValue(value.campaignId);

        this.setCouponsAndPreviewMessage({
          widgetType: WidgetType.SetReminder,
          brandName: this.brand?.name,
          selectedCouponId: value.selectedCouponId,
          frequencyLabel: this.getFrequencyLabel(),
          locationId: this.location?.id
        });
      });
    }

    this.onChange(value);
    this.changeDetectorRef.markForCheck();
  }

  ngOnInit(): void {
    this.enumRepresentationService.init();

    this.reminderFrequencyNameRepresentation = this.enumRepresentationService.eventSetReminderFrequencyName;
    this.reminderExpirationRepresentation = this.enumRepresentationService.eventSetReminderExpiration;

    this.form = new UntypedFormGroup(
      {
        [SettingsControl.FrequencyType]: new UntypedFormControl(this.defaultFrequencyType),
        [SettingsControl.SendingTime]: new UntypedFormControl(""),
        [SettingsControl.SetReminderExpiration]: new UntypedFormControl(SetReminderExpiration.ThreeMonth),
        [SettingsControl.SetReminderUrl]: new UntypedFormControl("", this.validators),
        [SettingsControl.SelectedCouponId]: new UntypedFormControl(""),
        [SettingsControl.CampaignId]: new UntypedFormControl("")
      },
      [this.formValidator()]
    );

    this.widgetSettingsReady.emit();
  }

  onFrequencyNameChanged(_: Event): void {
    this.otherControlChanged({
      widgetType: WidgetType.SetReminder,
      brandName: this.brand?.name,
      frequencyLabel: this.getFrequencyLabel()
    });
  }

  onCouponListChanged(coupon: CouponInfo): void {
    this.couponListChanged({
      widgetType: WidgetType.SetReminder,
      brandName: this.brand?.name,
      selectedCouponId: coupon?.id,
      frequencyLabel: this.getFrequencyLabel()
    });
  }

  private formValidator(): ValidatorFn {
    const elseControlNameList: string[] = [
      SettingsControl.FrequencyType,
      SettingsControl.SendingTime,
      SettingsControl.SetReminderExpiration,
      SettingsControl.SelectedCouponId
    ];

    return this.getFormValidator(SettingsControl.SetReminderUrl, elseControlNameList);
  }

  private getFrequencyLabel(): string {
    const frequencyType: SetReminderFrequency = this.frequencyTypeControl?.value || this.defaultFrequencyType;

    return this.enumRepresentationService.eventSetReminderFrequencyLabel[frequencyType]
      ? this.enumRepresentationService.eventSetReminderFrequencyLabel[frequencyType]
      : this.translateSvc.translateObject(this.defaultFrequencyTypeCode);
  }

  // destroy:
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
