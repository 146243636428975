import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../core";
import {
  AccountTemplateGetDto,
  DisplayTypeDto,
  GeneratedTemplateGetDto,
  GlobalTemplateGetDto,
  SystemTemplatePutDto,
  TemplateAssignedAccountsDto,
  TemplateGetDto,
  TemplatePostDto,
  TemplatePutDto,
  TemplateValidationResultDto
} from "../dto";
import { generateApiParams } from "./api.utils";

@Injectable({ providedIn: "root" })
export class TemplateService {
  // todo: add rest api and models and reuse it all over the apps
  serviceUrl = "template";

  constructor(private api: ApiService) {}

  getAll(locationId: number): Observable<TemplateGetDto[]> {
    return this.api.get(`${this.serviceUrl}/bylocation/${locationId}`);
  }

  addTemplate(accountId: number, template: TemplatePostDto): Observable<AccountTemplateGetDto> {
    return this.api.post(`${this.serviceUrl}/${accountId}`, template);
  }

  updateTemplate(template: TemplatePutDto): Observable<number> {
    return this.api.put(`${this.serviceUrl}/${template.id}`, template);
  }

  deleteTemplate(templateId: number): Observable<void> {
    return this.api.delete(`${this.serviceUrl}/${templateId}`);
  }

  enableTemplate(templateId: number) {
    return this.api.post(`${this.serviceUrl}/${templateId}/enable`);
  }

  disableTemplate(templateId: number) {
    return this.api.post(`${this.serviceUrl}/${templateId}/disable`);
  }

  validateTemplates(): Observable<TemplateValidationResultDto[]> {
    return this.api.get(`${this.serviceUrl}/validate`);
  }

  // Global templates:
  getAllGlobalTemplates(): Observable<GlobalTemplateGetDto[]> {
    return this.api.get(`${this.serviceUrl}/global`);
  }

  getGlobalTemplate(templateId: number): Observable<GlobalTemplateGetDto> {
    return this.api.get(`${this.serviceUrl}/global/${templateId}`);
  }

  addGlobalTemplate(template: TemplatePostDto): Observable<GlobalTemplateGetDto> {
    return this.api.post(`${this.serviceUrl}/global`, template);
  }

  updateGlobalTemplate(template: TemplatePostDto): Observable<GlobalTemplateGetDto> {
    return this.api.put(`${this.serviceUrl}/global`, template);
  }

  deleteGlobalTemplate(templateId: number): Observable<void> {
    return this.api.delete(`${this.serviceUrl}/global/${templateId}`);
  }

  // System templates:
  updateSystemTemplate(template: SystemTemplatePutDto): Observable<void> {
    return this.api.put(`${this.serviceUrl}/system`, template);
  }

  // Account templates:
  getAccountTemplate(templateId: number): Observable<AccountTemplateGetDto> {
    return this.api.get(`${this.serviceUrl}/${templateId}`);
  }

  getAllByAccount(accountId: number): Observable<AccountTemplateGetDto[]> {
    return this.api.get(`${this.serviceUrl}/byaccount/${accountId}`);
  }

  getFilteredAccountTemplates(locationId: number): Observable<AccountTemplateGetDto[]> {
    return this.api.get(`${this.serviceUrl}/filter/${locationId}`);
  }

  // Generated templates:
  getGeneratedTemplate(templateId: number, locationId: number | null): Observable<GeneratedTemplateGetDto> {
    // Uses for preview
    return this.api.get(`${this.serviceUrl}/generated/${templateId}?locationId=${locationId}`);
  }

  getGeneratedTemplates(
    locationId: number,
    cashRegisterId?: number,
    accountTemplateId?: number,
    displayTypeId?: number
  ): Observable<GeneratedTemplateGetDto[]> {
    return this.api.get(`${this.serviceUrl}/cfs/${locationId}`, generateApiParams({ cashRegisterId, accountTemplateId, displayTypeId }));
  }

  getMAppTemplate(
    widgetId: number,
    locationId: number,
    cashRegisterId: number,
    shortLink?: string,
    sessionId?: string
  ): Observable<GeneratedTemplateGetDto> {
    return this.api.get(
      `${this.serviceUrl}/cfs/${locationId}/widget/${widgetId}`,
      generateApiParams({ cashRegisterId, shortLink, sessionId })
    );
  }

  getGeneratedGlobal(templateId: number, locationId: number | null): Observable<GeneratedTemplateGetDto> {
    return this.api.get(`${this.serviceUrl}/generated-global/${templateId}?locationId=${locationId}`);
  }

  // Rest:
  getAssignedAccounts(templateId: number, isGlobal: boolean): Observable<TemplateAssignedAccountsDto> {
    return this.api.get(`${this.serviceUrl}/${templateId}/assigned/accounts?isGlobal=${isGlobal}`);
  }

  assignAccounts(templateId: number, isGlobal: boolean, data: TemplateAssignedAccountsDto): Observable<void> {
    return this.api.post(`${this.serviceUrl}/${templateId}/assigned/accounts?isGlobal=${isGlobal}`, data);
  }

  getContentByUrl(url: string): Observable<string> {
    return this.api.getTextByFullUrl(url);
  }

  getDisplayTypes(): Observable<DisplayTypeDto[]> {
    return this.api.get(`${this.serviceUrl}/displayTypes`);
  }
}
