import { User, TimeSpan } from "@app/models";

export enum LocationStatus {
  NotAvailable = "NotAvailable",
  Available = "Available"
}

export interface LocationAvailabilityRequestDto {
  widgetType?: number;
  externalCouponId?: string;
  templateId?: number;
  useOnCfs?: boolean;
  useOnPos?: boolean;
}
export interface LocationAvailabilityDto {
  location: Location;
  status: LocationStatus;
}

export interface LocationAvailability extends Location {
  status: LocationStatus;
}

export type TemplateLocation = LocationAvailability | Location;

export class Location {
  id: number;
  companyName: string;
  accountId: number;
  country?: number;
  city: string;
  state: string;
  address: string;
  website: string;
  manager?: User;
  managerId?: number;
  contactEmail: string;
  contactLastName: string;
  contactFirstName: string;
  email: string;
  longitude: number;
  latitude: number;
  zip: string;
  dealerCode: string;
  phoneNumber: string;
  longState: string;
  configured: boolean;
  widgetsConfigured: boolean;
  selected: boolean;
  salesAgentFullName: string;
  salesAgentId: number;
  fullAddress: string;
  fromOperationalHour: TimeSpan;
  toOperationalHour: TimeSpan;
  managerName: string;
  posLocationId: string;
  partnerId?: number;
  partnerName?: string;
}

export class LocationUser {
  locationId: number;
  userId: number;
}

export class LocationShort {
  id: number;
  name: string;
}

export class SimpleLocation {
  constructor(
    public id: number,
    public companyName: string,
    public address?: string,
    public city?: string,
    public state?: string,
    public zip?: string
  ) {}

  static getInstance(location: Location): SimpleLocation {
    return new SimpleLocation(location.id, location.companyName, location.address, location.city, location.state, location.zip);
  }
}

export interface LocationConfigurationStatusResultGetDto {
  location: Location;
  configured: boolean;
}

export interface LocationShortGetDto {
  id: number;
  name: string;
}
