<!-- ROWS 1 -->
<div *ngIf="!responsive">
  <div
    *ngIf="rows == 2 && coupons?.length > 5"
    [ngClass]="{ empty: withNothingFoundText && !coupons?.length && (!loading || !displayLoading), 'center-spinner': centerSpinner }"
    class="coupon-carousel small small-rows2"
  >
    <div *ngIf="coupons?.length" class="coupon-container">
      <ngx-simplebar [options]="scrollbarOptions">
        <div class="d-flex flex-column">
          <div class="d-flex flex-row">
            <app-coupon
              *ngFor="let item of coupons; index as i"
              class="coupon"
              [ngClass]="{ 'last-child': i === coupons.length - 1 }"
              [item]="item"
              [disabledNotActive]="disabledNotActive"
              [index]="i"
              [visible]="i % 2 == 0"
              [viewType]="'full'"
              [sizeType]="'small'"
              [selectionType]="'single'"
              [withPreview]="false"
              [config]="couponConfig"
              [selectedCoupon]="selectedCoupon"
              [removeSelected]="removeSelected"
              (selectCoupon)="clickSelectCoupon($event)"
              (closeQrCodeModal)="onCloseQrCodeModal()"
            >
            </app-coupon>
          </div>
        </div>

        <br />

        <div class="d-flex flex-column" *ngIf="coupons?.length > 1">
          <div class="d-flex flex-row">
            <app-coupon
              *ngFor="let item of coupons; index as i"
              class="coupon"
              [ngClass]="{ 'first-child': i === 1 }"
              [disabledNotActive]="disabledNotActive"
              [item]="item"
              [index]="i"
              [visible]="(i + 1) % 2 == 0"
              [viewType]="'full'"
              [sizeType]="'small'"
              [selectionType]="'single'"
              [withPreview]="false"
              [config]="couponConfig"
              [selectedCoupon]="selectedCoupon"
              [removeSelected]="removeSelected"
              (selectCoupon)="clickSelectCoupon($event)"
              (closeQrCodeModal)="onCloseQrCodeModal()"
            >
            </app-coupon>
          </div>
        </div>
      </ngx-simplebar>
    </div>

    <div *ngIf="withNothingFoundText && !coupons?.length && !loading">
      <h5 class="text-truncate pb-1">
        {{ "common.error.notFound" | transloco }}
      </h5>
    </div>

    <span *ngIf="loading && displayLoading" class="spinner-border spinner-border-sm mr-1"> </span>
  </div>

  <!-- ROWS 2 -->

  <div
    *ngIf="!rows || coupons?.length <= 5"
    class="coupon-carousel {{ viewType }}"
    [ngClass]="{ empty: withNothingFoundText && !coupons?.length && (!loading || !displayLoading), 'center-spinner': centerSpinner }"
  >
    <div *ngIf="coupons?.length" class="coupon-container">
      <ngx-simplebar [options]="scrollbarOptions">
        <app-coupon
          *ngFor="let item of coupons; index as i"
          class="coupon"
          [ngClass]="{ 'last-child': i === coupons.length - 1 }"
          [item]="item"
          [disabledNotActive]="disabledNotActive"
          [viewType]="'full'"
          [sizeType]="couponSizeType"
          [selectionType]="selectionType"
          [withPreview]="withPreview"
          [previewMode]="previewMode"
          [config]="couponConfig"
          [selectedCoupon]="selectedCoupon"
          [removeSelected]="removeSelected"
          (selectCoupon)="clickSelectCoupon($event)"
          (closeQrCodeModal)="onCloseQrCodeModal()"
        >
        </app-coupon>
      </ngx-simplebar>
    </div>
  </div>
</div>
<div *ngIf="responsive">
  <div class="row row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-2" *ngIf="coupons?.length">
    <app-coupon
      *ngFor="let item of coupons; index as i"
      class="coupon mb-3"
      [disabledNotActive]="disabledNotActive"
      [item]="item"
      [viewType]="'full'"
      [sizeType]="'small'"
      [selectionType]="'single'"
      [withPreview]="false"
      [config]="couponConfig"
      [selectedCoupon]="selectedCoupon"
      [removeSelected]="removeSelected"
      (selectCoupon)="clickSelectCoupon($event)"
      (closeQrCodeModal)="onCloseQrCodeModal()"
    >
    </app-coupon>
  </div>
</div>

<div *ngIf="withNothingFoundText && !coupons?.length && !loading">
  <h5 class="text-truncate pb-1">
    {{ "common.error.notFound" | transloco }}
  </h5>
</div>

<span *ngIf="loading && displayLoading" class="spinner-border spinner-border-sm mr-1"> </span>
