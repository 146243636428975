<form [formGroup]="form" *transloco="let t; read: 'brand'">
  <div class="row">
    <div class="col-xl-6 col-sm-12" *transloco="let tc; read: 'common'">
      <div class="col-md-12" hidden>
        <label class="col-form-label">{{ t("brand") }}<span class="text-danger">*</span></label>
        <ng-select
          [placeholder]="t('selectType')"
          [items]="brandsSubscription | async"
          bindLabel="name"
          [formControl]="formControl"
          (change)="select()"
        >
        </ng-select>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <div class="form-group">
            <label class="col-form-label">{{ t("industry") }}<span class="text-danger">*</span></label>
            <ng-select
              [placeholder]="t('industryPlaceholder')"
              [items]="industries"
              bindLabel="name"
              bindValue="id"
              formControlName="industryId"
              [class.is-invalid]="form.controls['industryId'].touched && form.controls['industryId'].errors"
            >
            </ng-select>
            <ng-container *ngIf="form.controls['industryId'].touched && form.controls['industryId'].errors">
              <ng-container *ngIf="form.controls['industryId'].errors.required">
                <div class="invalid-feedback position-absolute">
                  {{ tc("error.required") }}
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>

        <div class="col-lg-6 col-md-12">
          <div class="form-group">
            <label class="col-form-label">{{ t("country") }}<span class="text-danger">*</span></label>
            <ng-select
              [placeholder]="t('countryPlaceholder')"
              [items]="countries"
              bindLabel="name"
              bindValue="value"
              formControlName="country"
              [class.is-invalid]="form.controls['country'].touched && form.controls['country'].errors"
            >
            </ng-select>
            <ng-container *ngIf="form.controls['country'].touched && form.controls['country'].errors">
              <ng-container *ngIf="form.controls['country'].errors.required">
                <div class="invalid-feedback position-absolute">
                  {{ tc("error.required") }}
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <div class="form-group">
            <label class="col-form-label">{{ t("brand") }}<span class="text-danger">*</span></label>
            <input
              class="form-control"
              type="text"
              [placeholder]="t('namePlaceholder')"
              formControlName="name"
              [class.is-invalid]="form.controls['name'].touched && form.controls['name'].errors"
            />

            <ng-container *ngIf="form.controls['name'].touched && form.controls['name'].errors">
              <ng-container *ngIf="form.controls['name'].errors.required">
                <div class="invalid-feedback position-absolute">
                  {{ tc("error.required") }}
                </div>
              </ng-container>
              <ng-container *ngIf="form.controls['name'].errors.notUnique">
                <div class="invalid-feedback position-absolute">
                  {{ t("existed") }}
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="form-group">
            <label class="col-form-label position-relative"
              >{{ t("shortName") }}<span class="text-danger">*</span>
              <div
                class="font-size-18 widget-icon-tooltip"
                [ngbTooltip]="t('shortNameTooltip')"
                #tt="ngbTooltip"
                container="body"
                (mouseout)="tt.close()"
                triggers="click:blur"
                hidden
              >
                <i class="bx bx-question-mark mb-0 text-info"></i>
              </div>
            </label>

            <input
              class="form-control"
              type="text"
              [placeholder]="t('shortNamePlaceholder')"
              formControlName="shortName"
              [class.is-invalid]="form.controls['shortName'].touched && form.controls['shortName'].errors"
            />
            <ng-container *ngIf="form.controls['shortName'].touched && form.controls['shortName'].errors">
              <ng-container *ngIf="form.controls['shortName'].errors?.required">
                <div class="invalid-feedback position-absolute">
                  {{ tc("error.required") }}
                </div>
              </ng-container>
              <ng-container *ngIf="form.controls['shortName'].errors.maxlength && !form.controls['shortName'].errors.numberAndLetter">
                <div class="invalid-feedback position-absolute">
                  {{ tc("error.minmaxLenght", { label: t("shortName"), min: 1, max: 11 }) }}
                </div>
              </ng-container>
              <ng-container *ngIf="form.controls['shortName'].errors.numberAndLetter">
                <div class="invalid-feedback position-absolute">
                  {{ tc("error.numberAndLetter", { label: t("shortName") }) }}
                </div>
              </ng-container>
              <ng-container *ngIf="form.controls['shortName'].errors.notUnique">
                <div class="invalid-feedback position-absolute">
                  {{ t("existedShortName") }}
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="isAdmin">
        <div class="col-lg-6 col-md-12">
          <div class="form-group">
            <label class="col-md-3 col-form-label">{{ t("type") }}<span class="text-danger">*</span></label>
            <ng-select
              (change)="onTypeChange()"
              [class.is-invalid]="form.controls['brandType'].touched && form.controls['brandType'].errors"
              [placeholder]="t('typePlaceholder')"
              formControlName="brandType"
            >
              <ng-option *ngFor="let type of brandTypes" [value]="type">{{ brandTypeRepresentation[type] }}</ng-option>
            </ng-select>
            <ng-container *ngIf="form.controls['brandType'].touched && form.controls['brandType'].errors">
              <ng-container *ngIf="form.controls['brandType'].errors.required">
                <div class="invalid-feedback position-absolute">
                  {{ tc("error.required") }}
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div *ngIf="isPrivate" class="col-lg-6 col-md-12">
          <div class="form-group">
            <label class="col-md-3 col-form-label">{{ t("owner") }}<span class="text-danger">*</span></label>
            <ng-select
              [class.is-invalid]="form.controls['ownerId'].touched && form.controls['ownerId'].errors"
              [placeholder]="t('ownerPlaceholder')"
              formControlName="ownerId"
            >
              <ng-option *ngFor="let user of userSubscription | async" [value]="user.id">{{
                user.firstName +
                  "
              " +
                  user.lastName
              }}</ng-option>
            </ng-select>
            <ng-container *ngIf="form.controls['ownerId'].touched && form.controls['ownerId'].errors">
              <ng-container *ngIf="form.controls['ownerId'].errors.required">
                <div class="invalid-feedback position-absolute">
                  {{ tc("error.required") }}
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="row" [class.row]="isEditable">
        <div class="col-md-12">
          <div class="form-group">
            <label class="col-form-label">{{ t("slogan") }}</label>
            <input class="form-control" formControlName="slogan" [placeholder]="t('slogan')" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="col-form-label">{{ t("disc") }}</label>
            <input class="form-control" type="text" [placeholder]="t('disc')" formControlName="disclaimer" />
          </div>
        </div>
      </div>
      <div class="row form-group align-items-center">
        <app-url-form-control
          [isHideTooltip]="true"
          label="Website"
          [disable]="!isEditable"
          [isLinked]="brand?.website && !isEditable"
          formControlName="website"
          [placeholder]="!brand?.website ? t('site') : brand?.website"
          [validators]="validators"
        >
        </app-url-form-control>
      </div>

      <div class="row form-group align-items-center">
        <app-url-form-control
          [isHideTooltip]="true"
          [label]="t('terms')"
          [disable]="!isEditable"
          [isLinked]="brand?.termsAndConditionsUrl && !isEditable"
          formControlName="termsAndConditionsUrl"
          [placeholder]="!brand?.termsAndConditionsUrl ? t('terms') : brand?.termsAndConditionsUrl"
          [validators]="validators"
        >
        </app-url-form-control>
      </div>

      <div class="row form-group align-items-center">
        <app-url-form-control
          [isHideTooltip]="true"
          [label]="t('policy')"
          [disable]="!isEditable"
          [isLinked]="brand?.privacyPolicyUrl && !isEditable"
          formControlName="privacyPolicyUrl"
          [placeholder]="!brand?.privacyPolicyUrl ? t('policy') : brand?.privacyPolicyUrl"
          [validators]="validators"
        ></app-url-form-control>
      </div>

      <div class="row" *ngIf="form.controls.privacyPolicyUrl.value">
        <div class="col-md-12">
          <div class="form-group">
            <label class="col-form-label">{{ t("referralId") }}</label>
            <input
              class="form-control"
              oninput="this.value = this.value.toUpperCase()"
              [placeholder]="t('referralIdPlaceholder')"
              formControlName="referralId"
              [class.is-invalid]="form.controls['referralId'].touched && form.controls['referralId'].errors"
            />
            <ng-container *ngIf="form.controls['referralId'].touched && form.controls['referralId'].errors">
              <ng-container *ngIf="form.controls['referralId'].errors.required">
                <div class="invalid-feedback position-absolute">
                  {{ tc("error.required") }}
                </div>
              </ng-container>
              <ng-container *ngIf="form.controls['referralId'].errors.notUnique">
                <div class="invalid-feedback position-absolute">
                  {{ t("existedReferralId") }}
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
      <div class="row">
        <app-custom-image-uploader
          size="M"
          formControlName="logo"
          [label]="t('logo')"
          [isDisabled]="!isEditable"
          [isRequired]="true"
        ></app-custom-image-uploader>

        <div class="d-flex">
          <app-custom-image-uploader
            class="w-50"
            size="S"
            formControlName="qrCodeLogo"
            [label]="t('qrCodeLogo')"
            [isDisabled]="!isEditable"
            [isClearable]="true"
          ></app-custom-image-uploader>

          <app-custom-image-uploader
            class="w-50"
            size="S"
            formControlName="appIcon"
            [label]="t('appIcon')"
            [tooltip]="t('appIconTooltip')"
            [allowedFileSize]="appIconAllowedImageSize"
            [extensions]="appIconExtensions"
            [isDisabled]="!isEditable"
            [isClearable]="true"
          ></app-custom-image-uploader>
        </div>

        <app-custom-image-uploader
          size="L"
          formControlName="brandHeroImg"
          [label]="t('hero')"
          [isDisabled]="!isEditable"
          [isRequired]="false"
        ></app-custom-image-uploader>

        <app-social-links-form-control
          [isRuleBtn]="true"
          class="mt-5"
          (linksChanged)="onLinksChanged($event)"
          (linksValid)="onLinksValid($event)"
          [isEditable]="isEditable"
          [links]="links"
        ></app-social-links-form-control>
      </div>
    </div>
  </div>
</form>
