import { CommonModule } from "@angular/common";
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { TranslocoService } from "@ngneat/transloco";
import { AnimationItem } from "lottie-web";
import { AnimationLoader, AnimationOptions, LottieComponent, provideLottieOptions } from "ngx-lottie";
import { BehaviorSubject, Subject } from "rxjs";
import {
  AssetParamKeys,
  CfsQrCodeContentAsset,
  ContentSettingKeys,
  ContentSettings,
  ScreenSizesJsonAsset,
  TemplatePreviewOptions
} from "../../../models";
import { CfsTemplateUtils } from "../cfs-template.utils";
import { DigitalAssetGetDto, KeyValueDto, KeyValueAsset, DigitalAssetScreenSize } from "clearline-api";

type ResponsiveAssetsInfo = {
  [screenSize in DigitalAssetScreenSize]?: DigitalAssetGetDto | null;
};

type AnimationDataSizeAsset = {
  [screenSize in DigitalAssetScreenSize]?: any;
};

@Component({
  selector: "lib-cfs-template-preview",
  templateUrl: "./cfs-template-preview.component.html",
  styleUrls: ["./cfs-template-preview.component.scss"],
  standalone: true,
  imports: [CommonModule, LottieComponent],
  providers: [
    provideLottieOptions({
      player: () => import("lottie-web")
    }),
    AnimationLoader
  ]
})
export class CfsTemplatePreviewComponent implements AfterViewInit, OnDestroy {
  @Input() type: "html" | "lottie" = "lottie";
  @Input() fullScreenWrapper = false;
  @Input() isPrintView = false;

  @Input() set screenSize(screenSize: DigitalAssetScreenSize | undefined) {
    if (this.templateScreenSize && this.templateScreenSize === screenSize) {
      return;
    }

    this.templateScreenSize = screenSize ?? DigitalAssetScreenSize.Full;

    if (this.animationDataSizeAsset[screenSize || DigitalAssetScreenSize.Full]) {
      // set only if templateOptions is already set
      this.setAnimationToOptions();
    }
  }

  @Input() set options(options: TemplatePreviewOptions) {
    this.templateOptions = options;

    if (this.type === "lottie" && (options?.json || options.screenSizesJsonAsset)) {
      this.setLottieOptions(options);
    } else {
      this.htmlBody = options.htmlBody ? this.sanitizer.bypassSecurityTrustHtml(options.htmlBody) : "";
    }
  }

  @Output() printReady = new EventEmitter();
  @ViewChild("lottieContainer") lottieContainer: ElementRef | undefined;

  private colorSettingKeys = [ContentSettingKeys.BackgroundColor, ContentSettingKeys.ButtonColor];
  private backgroundColor?: string;
  private backgroundImage?: string;
  private qrCodeUrl?: string;
  private imageUrl?: string;
  private unsubscribe$ = new Subject<void>();

  templateOptions?: TemplatePreviewOptions;
  animationDataSizeAsset: AnimationDataSizeAsset = {
    [DigitalAssetScreenSize.Full]: null,
    [DigitalAssetScreenSize.Compact]: null,
    [DigitalAssetScreenSize.Vertical]: null
  };
  templateScreenSize?: DigitalAssetScreenSize;
  lottieOptions$ = new BehaviorSubject<AnimationOptions>(null as any);
  htmlBody: SafeHtml = "";

  constructor(private translateService: TranslocoService, private sanitizer: DomSanitizer) {}

  ngAfterViewInit(): void {
    this.setBackground();
  }

  animationCreated(animationItem: AnimationItem): void {
    if (this.isPrintView) {
      animationItem.goToAndStop(0);
    }
  }

  domLoaded(): void {
    if (this.isPrintView) {
      const waitingList = [];

      if (this.imageUrl) {
        waitingList.push(fetch(this.imageUrl));
      }

      if (this.qrCodeUrl) {
        waitingList.push(fetch(this.qrCodeUrl));
      }

      Promise.all(waitingList).then(() => {
        this.printReady.emit();
      });
    }
  }

  private setBackground(): void {
    if (this.lottieContainer) {
      const lottieContainerElement: HTMLElement = this.lottieContainer.nativeElement;

      if (this.backgroundColor) {
        lottieContainerElement.style.backgroundColor = this.backgroundColor;
        lottieContainerElement.style.backgroundImage = "";
      } else if (this.backgroundImage) {
        lottieContainerElement.style.backgroundImage = this.backgroundImage;
        lottieContainerElement.style.backgroundColor = "";
      }

      if (this.isPrintView) {
        lottieContainerElement.style.backgroundColor = "#fff";
        lottieContainerElement.style.backgroundImage = "";
      }
    }
  }

  private setLottieOptions(options: TemplatePreviewOptions): void {
    const { contentSettings, defaultParameters, json, screenSizesJsonAsset } = options;
    this.backgroundColor = contentSettings?.backgroundColor || CfsTemplateUtils.parseQrCodeBackgroundColor(defaultParameters);
    this.backgroundImage = this.getBackgroundImage(options);
    const assetsInfo: ResponsiveAssetsInfo = this.getResponsiveAssetsInfo(options);

    this.setBackground();

    this.animationDataSizeAsset = Object.keys(assetsInfo).reduce((acc: ResponsiveAssetsInfo, itemScreenSize, index: number) => {
      const assetItem: DigitalAssetGetDto | null | undefined = assetsInfo[itemScreenSize as DigitalAssetScreenSize];
      const itemDefaultParams: KeyValueDto[] = assetItem?.configuration?.defaultParameters || [];
      const itemJson: string = this.getItemJsonByScreenSize(screenSizesJsonAsset, itemScreenSize as DigitalAssetScreenSize, json);

      return {
        ...acc,
        [itemScreenSize]: this.getAnimationData(itemJson, itemDefaultParams as any, options)
      };
    }, {} as any);

    this.setAnimationToOptions();
  }

  private getItemJsonByScreenSize(
    screenSizesJsonAsset?: ScreenSizesJsonAsset,
    itemScreenSize?: DigitalAssetScreenSize,
    json?: string
  ): string {
    let result = "";

    if (screenSizesJsonAsset) {
      result = screenSizesJsonAsset[itemScreenSize as DigitalAssetScreenSize] || "";
    } else if (itemScreenSize === DigitalAssetScreenSize.Full) {
      result = json || "";
    }

    return result;
  }

  private getBackgroundImage(options: TemplatePreviewOptions): string {
    const params: KeyValueAsset = this.getAllKeyValueAssetParams(options, []);

    return Object.keys(params).reduce((acc: string, key: string) => {
      const value: string = params[key];

      if (key === CfsTemplateUtils.backgroundImageKey) {
        acc = value;
      }

      return acc;
    }, "");
  }

  private setAnimationToOptions(): void {
    const animationData = this.animationDataSizeAsset[this.templateScreenSize || DigitalAssetScreenSize.Full];
    const lottieOptions: AnimationOptions = { loop: true, autoplay: true, animationData };

    this.lottieOptions$.next(lottieOptions);
  }

  private getAnimationData(jsonText: string | undefined, digitalDefaultParams: KeyValueDto[], options: TemplatePreviewOptions): any {
    if (!jsonText) return null;

    const { contentSettings, mediaContent } = options || {};
    const isOldLottie: boolean = !!contentSettings?.displayContent || !!contentSettings?.templateCategory;

    if (mediaContent && !isOldLottie) {
      const allParams: KeyValueAsset = this.getAllKeyValueAssetParams(options, digitalDefaultParams);

      if (allParams[CfsQrCodeContentAsset.qrCodeUrl]) {
        this.qrCodeUrl = allParams[CfsQrCodeContentAsset.qrCodeUrl];
      }

      if (allParams[CfsQrCodeContentAsset.imageUrl]) {
        this.imageUrl = allParams[CfsQrCodeContentAsset.imageUrl];
      }

      Object.keys(allParams).forEach((key: string) => {
        jsonText = jsonText?.replace(key, allParams[key]);
      });

      return JSON.parse(jsonText);
    } else {
      return contentSettings
        ? this.getLottieJson(jsonText, digitalDefaultParams, contentSettings)
        : this.getLottieJsonByAssetParams(jsonText, digitalDefaultParams);
    }
  }

  private getAllKeyValueAssetParams(options?: TemplatePreviewOptions, digitalDefaultParams: KeyValueDto[] = []): KeyValueAsset {
    const { defaultParameters, mediaContent } = options || {};

    const assetParameters: KeyValueAsset = CfsTemplateUtils.getKeyValueAsset(digitalDefaultParams);
    const mediaParams: KeyValueAsset = CfsTemplateUtils.getKeyValueAsset(mediaContent?.configuration.defaultParameters);

    return { ...assetParameters, ...mediaParams, ...defaultParameters };
  }

  private getResponsiveAssetsInfo(options: TemplatePreviewOptions): ResponsiveAssetsInfo {
    const { digitalAsset, mediaContent } = options;

    if (mediaContent) {
      const digitalAssets = mediaContent.digitalAssets;

      return Object.values(DigitalAssetScreenSize).reduce((acc: ResponsiveAssetsInfo, size: string) => {
        const itemDigitalAsset: DigitalAssetGetDto | undefined = digitalAssets.find((item: DigitalAssetGetDto) => {
          const itemScreenSize = item.configuration?.screenSize;
          return itemScreenSize === size || (!itemScreenSize && size === DigitalAssetScreenSize.Full);
        });

        return {
          ...acc,
          [size]: itemDigitalAsset
        };
      }, {} as ResponsiveAssetsInfo);
    } else {
      return {
        [DigitalAssetScreenSize.Full]: digitalAsset,
        [DigitalAssetScreenSize.Compact]: null,
        [DigitalAssetScreenSize.Vertical]: null
      };
    }
  }

  private getLottieJsonByAssetParams(jsonText: string, params: KeyValueDto[] = []): any {
    params?.forEach((item: KeyValueDto) => {
      jsonText = jsonText.replace(item.key, item.value);
    });

    return JSON.parse(jsonText);
  }

  private getLottieJson(text: string, params: KeyValueDto[] | undefined, settings: ContentSettings): any {
    Object.keys(CfsTemplateUtils.contentAssetByKeys).forEach((key: string) => {
      let value: string = this.tryGetPlaceholder(key, settings);

      if (!value) {
        if (key === AssetParamKeys.Param1) {
          let displayContent: string = CfsTemplateUtils.getValueWithFirstUpperLetter(settings.displayContent);

          if (!displayContent) displayContent = CfsTemplateUtils.getValueWithFirstUpperLetter(settings.templateCategory);

          value = displayContent;
        } else {
          value = this.getJsonValueDependOnKey(key, params as KeyValueDto[], settings);
        }
      }

      text = text.replace(key, value.replace(/"/g, '\\"'));
    });

    return JSON.parse(text);
  }

  private tryGetPlaceholder(key: string, settings: ContentSettings) {
    if (key === AssetParamKeys.Param1 && !settings.displayContent)
      return this.translateService.translateObject("templates.templateTopPlaceholder" + (settings.isPredefined ? "Select" : "Enter"));
    if (key === AssetParamKeys.Param3 && !settings.templateCategory)
      return this.translateService.translateObject("templates.templateButtonPlaceholder");
    if (key === AssetParamKeys.Param2 && !settings.campaignSchedule)
      return this.translateService.translateObject("templates.campaignSchedule" + (settings.isPredefined ? "Select" : "Enter"));
    return null;
  }

  private getJsonValueDependOnKey(key: string, params: KeyValueDto[], settings: ContentSettings): string {
    let value: string;
    const contentSettingKey = CfsTemplateUtils.contentAssetByKeys[key as AssetParamKeys];
    const valueBySettings: string = contentSettingKey ? (settings as any)[contentSettingKey] : null;
    value = valueBySettings ? valueBySettings : (this.getContentDefaultValue(params, key) as string);

    if (valueBySettings && contentSettingKey === ContentSettingKeys.CampaignSchedule) {
      value = value.toUpperCase();
    } else if (valueBySettings && this.colorSettingKeys.some((colorKey: string) => colorKey === contentSettingKey)) {
      value = CfsTemplateUtils.getLottieColorByHex(value);
    } else {
      value = CfsTemplateUtils.getValueWithFirstUpperLetter(value);
    }

    return value;
  }

  private getContentDefaultValue(assetParams: KeyValueDto[] | undefined, key: string): string {
    return assetParams?.find((item: KeyValueDto) => item.key === key)?.value || "";
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
