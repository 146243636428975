<div id="customCarousel" class="carousel slide" data-bs-ride="carousel">
  <div #carouselInner class="carousel-inner">
    <div *ngFor="let chunk of (chunkedItems$ | async) as items; let i = index" class="carousel-item" [class.active]="i === currentIndex">
      <div class="d-flex with-gap" [class.justify-content-end]="config.startWithLastSlide" [class.justify-content-center]="items.length === 1">
        <div *ngFor="let item of chunk" class="carousel-item-inner">
          <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"></ng-container>
        </div>
      </div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" (click)="goToPreviousSlide()" [disabled]="isPreviousButtonDisabled">
    <span class="carousel-control-icon" aria-hidden="true"><i class="ri-arrow-left-s-line"></i></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" (click)="goToNextSlide()" [disabled]="isNextButtonDisabled">
    <span class="carousel-control-icon" aria-hidden="true"><i class="ri-arrow-right-s-line"></i></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
