import { DeliveryProviderType } from "../enums";

export interface DeliveryProviderGetDto {
  id: DeliveryProviderType;
  name: string;
  code: string;
  configuration: DeliveryProviderConfiguration;
  active: boolean;
}

export interface DeliveryProviderConfiguration {
  authorizationData: DeliveryProviderConfigurationAuthorization;
  smsSenderConfiguration: DeliveryProviderSmsSenderConfiguration;
  code: string;
  configuration: {};
  active: boolean;
}

export interface DeliveryProviderConfigurationAuthorization {
  labels: { key: string; value: string }[];
}

export interface DeliveryProviderSmsSenderConfiguration {
  defaultGenericNumber: string;
  defaultGenericTollfreeNumber: string;
}
