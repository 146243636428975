export interface SendEmailWithRegistrationUrlRequestDto {
  registrationUrl: string;
  userId: number;
}

export enum QuickSetupWizardSteps {
  BrandDetails = 0,
  BrandColors = 1,
  MessageDeliveryOptions = 2,
  Location = 3,
  PricingPlan = 4,
  Apps = 5,
  Done = 6
}

export enum QuickSetupIntegrationWizardSteps {
  BrandDetails = 0,
  BrandColors = 1,
  MessageDeliveryOptions = 2,
  Location = 3,
  Apps = 4,
  Done = 5
}

export interface QuickSetupPredefinedSettingsGetDto {
  country?: number;
  companyName?: string;
  locationQuantity?: number;
  email?: string;
  phoneNumber?: string;
  state?: string;
  address?: string;
  city?: string;
  zip?: string;
  website?: string;
}

export interface CreateManagerAccountResponseDto {
  subscriptionId?: number;
}
