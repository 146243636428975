import { Injectable } from "@angular/core";
import { DataService } from "@app/core/services/data.service";
import {
  Campaign,
  CustomizedCampaign,
  DefaultWidgetCampaign,
  LocationWidgetSettings,
  LocationWithDefaultWidget,
  QRCodeSettings
} from "@app/models";
import { AddPromoWidgetSettings } from "@app/pages/components/widget-settings/add-promo-widget-settings/add-promo-widget-settings";
import { AddToWaitlistWidgetSettings } from "@app/pages/components/widget-settings/add-to-waitlist-widget-settings/add-to-waitlist-widget-settings";
import { AnniversaryClubWidgetSettings } from "@app/pages/components/widget-settings/anniversary-club-widget-settings/anniversary-club-widget-settings";
import { ConnectSocialNetworkWidgetSettings } from "@app/pages/components/widget-settings/connect-social-network-widget-settings/connect-social-network-widget-settings";
import { DownloadMobileAppWidgetSettings } from "@app/pages/components/widget-settings/download-mobile-app-widget-settings/download-mobile-app-widget-settings";
import { EnrollLoyaltyWidgetSettings } from "@app/pages/components/widget-settings/enroll-loyalty-widget-settings/enroll-loyalty-widget-settings";
import { ReferralProgramWidgetSettings } from "@app/pages/components/widget-settings/referral-program-widget-settings/referral-program-widget-settings";
import { RequestReviewWidgetSettings } from "@app/pages/components/widget-settings/request-review-widget-settings/review-request-widget-settings";
import { ScheduleAppointmentWidgetSettings } from "@app/pages/components/widget-settings/schedule-appointment-widget-settings/schedule-appointment-widget-settings";
import { SeeMenuWidgetSettings } from "@app/pages/components/widget-settings/see-menu-widget-settings/see-menu-widget-settings";
import { SendGiftCardWidgetSettings } from "@app/pages/components/widget-settings/send-gift-card-widget-settings/send-gift-card-widget-settings";
import { SendPaymentLinkWidgetSettings } from "@app/pages/components/widget-settings/send-payment-link-widget-settings/send-payment-link-widget-settings";
import { SendSurveyWidgetSettings } from "@app/pages/components/widget-settings/send-survey-widget-settings/send-survey-widget-settings";
import { SetReminderWidgetSettings } from "@app/pages/components/widget-settings/set-reminder-widget-settings/set-reminder-widget-settings";
import { SmartPageWidgetSettings } from "@app/pages/components/widget-settings/smart-page-widget-settings/smart-page-widget-settings";
import { TranslocoService } from "@ngneat/transloco";
import { LocationWidgetSettingsMode, WidgetType } from "clearline-common";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class WidgetService {
  constructor(private http: DataService, private translateService: TranslocoService) {
    this.initWidgetTranslations();
    this.initWidgetIconsMap();
  }

  private serviceUrl = "widgetManagement";
  private translateWidgetMap = new Map<WidgetType, string>();
  private widgetIconsMap = new Map<WidgetType, string>();

  get defaultConfiguredWidgets() {
    return [
      WidgetType.IssueCoupon,
      WidgetType.RequestReview,
      WidgetType.DownloadMobileApp,
      WidgetType.ConnectSocialNetwork,
      WidgetType.OneTimePayment,
      WidgetType.SeeMenu
    ];
  }

  private widgetSettings:
    | RequestReviewWidgetSettings
    | SetReminderWidgetSettings
    | AddPromoWidgetSettings
    | DownloadMobileAppWidgetSettings
    | ConnectSocialNetworkWidgetSettings
    | SendPaymentLinkWidgetSettings
    | AnniversaryClubWidgetSettings
    | SendGiftCardWidgetSettings
    | SendSurveyWidgetSettings
    | AddToWaitlistWidgetSettings
    | ReferralProgramWidgetSettings
    | ScheduleAppointmentWidgetSettings
    | EnrollLoyaltyWidgetSettings
    | SmartPageWidgetSettings
    | SeeMenuWidgetSettings;

  initWidgetTranslations() {
    return this.translateService.selectTranslateObject("widgets").subscribe((t) => {
      this.translateWidgetMap.set(WidgetType.IssueCoupon, t.issueCoupon.title);
      this.translateWidgetMap.set(WidgetType.ValidateCoupon, t.validateCoupon.title);
      this.translateWidgetMap.set(WidgetType.EnrollToLoyalty, t.enrollLoyalty.title);
      this.translateWidgetMap.set(WidgetType.IssuePointsOrStamps, t.issuePoints.title);
      this.translateWidgetMap.set(WidgetType.RequestReview, t.requestReview.title);
      this.translateWidgetMap.set(WidgetType.SetReminder, t.setReminder.title);
      this.translateWidgetMap.set(WidgetType.AddPromo, t.addPromo.title);
      this.translateWidgetMap.set(WidgetType.DownloadMobileApp, t.downloadMobileApp.title);
      this.translateWidgetMap.set(WidgetType.ConnectSocialNetwork, t.connectSocialMedia.title);
      this.translateWidgetMap.set(WidgetType.OneTimePayment, t.sendPaymentLink.title);
      this.translateWidgetMap.set(WidgetType.ScheduleAppointment, t.scheduleAppointment.title);
      this.translateWidgetMap.set(WidgetType.AddToWaitlist, t.waitlist.title);
      this.translateWidgetMap.set(WidgetType.SendSurvey, t.sendSurvey.title);
      this.translateWidgetMap.set(WidgetType.SendGiftCard, t.sendGiftCard.title);
      this.translateWidgetMap.set(WidgetType.AnniversaryClub, t.anniversaryClub.title);
      this.translateWidgetMap.set(WidgetType.AddToReferralProgram, t.referralProgram.title);
      this.translateWidgetMap.set(WidgetType.SmartPage, t.smartPage.title);
      this.translateWidgetMap.set(WidgetType.SeeMenu, t.seeMenu.title);
    });
  }

  getWidgetNameById(id: number) {
    return this.translateWidgetMap.get(id);
  }

  getWidgetsInThePlan(widgets: LocationWithDefaultWidget[]): CustomizedCampaign[] {
    return widgets
      .filter((item: LocationWithDefaultWidget) => !item.isOutOfThePlan)
      .map((item: LocationWithDefaultWidget) => item.defaultWidgetSettings) as CustomizedCampaign[];
  }

  getDefaultWidget(widgetId: number): Observable<Campaign> {
    return this.http.get(`${this.serviceUrl}/${widgetId}`).pipe(
      map((w: Campaign) => {
        w.name = this.translateWidgetMap.get(w.id) || w.name;
        return w;
      })
    );
  }

  getAllCampaigns(): Observable<Campaign[]> {
    return this.http.get(`${this.serviceUrl}/all`).pipe(
      map((wgs: Campaign[]) => {
        wgs.forEach((w) => (w.name = this.translateWidgetMap.get(w.id) || w.name));
        return wgs;
      })
    );
  }

  updateDefaultCampaign(campaign: Campaign) {
    return this.http.put(`${this.serviceUrl}/updateDefaultCampaign`, campaign);
  }

  getLocationWidgets(locationId: number): Observable<LocationWidgetSettings[]> {
    return this.http.get(`${this.serviceUrl}/locationCampaigns/${locationId}`);
  }

  getDefaultWidgetCampaign(widgetId: number): Observable<DefaultWidgetCampaign> {
    return this.http.get(`${this.serviceUrl}/campaign/${widgetId}`);
  }

  getClickableTemplateWidgets(): Observable<Campaign[]> {
    return this.http.get(`${this.serviceUrl}/clickableTemplate`);
  }

  updateLocationCampaign(campaign: LocationWidgetSettings, mode = LocationWidgetSettingsMode.Cmc) {
    return this.http.put(`${this.serviceUrl}/updateLocationCampaign?mode=${mode}`, campaign);
  }

  getAllLocationDefaultWidgets(locationId: number, mode = LocationWidgetSettingsMode.Cmc): Observable<LocationWithDefaultWidget[]> {
    return this.http.get<LocationWithDefaultWidget[]>(`${this.serviceUrl}/locationDefaultWidgets/${locationId}?mode=${mode}`).pipe(
      map((widgetList: LocationWithDefaultWidget[]) => {
        const widgets = widgetList.filter((x) => !x.isOutOfThePlan);

        return widgets.map((widget: LocationWithDefaultWidget) => {
          if (widget.defaultWidgetSettings)
            widget.defaultWidgetSettings.name = this.translateWidgetMap.get(widget.defaultWidgetSettings.id);
          return widget;
        });
      })
    );
  }

  getQrSettings(featureId: WidgetType, ls: LocationWidgetSettings): QRCodeSettings {
    const widgetSettingsJson = ls.widgetSettingsJson;
    switch (featureId) {
      // case WidgetType.IssueCoupon:
      //   this.widgetSettings = JSON.parse(widgetSettingsJson) as IssueCouponWidgetSettings;
      //   return { url = widgetSettings., logo = widgetSettings } as QRCodeSettings;
      // case WidgetType.ValidateCoupon:
      //   this.widgetSettings = JSON.parse(widgetSettingsJson) as Object;
      //    return { url: widgetSettings.reviewLink1, logo: "" } as QRCodeSettings;
      // case WidgetType.IssuePoints:
      //   this.widgetSettings = JSON.parse(widgetSettingsJson) as Object;
      //    return { url: widgetSettings.reviewLink1, logo: "" } as QRCodeSettings;
      case WidgetType.EnrollToLoyalty:
        this.widgetSettings = JSON.parse(widgetSettingsJson) as EnrollLoyaltyWidgetSettings;
        return { url: ls.landingUrl || this.widgetSettings.enrollLoyaltyLink, logo: "" } as QRCodeSettings;
      case WidgetType.RequestReview:
        this.widgetSettings = JSON.parse(widgetSettingsJson) as RequestReviewWidgetSettings;
        return { url: ls.landingUrl, logo: "" } as QRCodeSettings;
      case WidgetType.SetReminder:
        this.widgetSettings = JSON.parse(widgetSettingsJson) as SetReminderWidgetSettings;
        return { url: this.widgetSettings.setReminderUrl || ls.landingUrl, logo: "" } as QRCodeSettings;
      case WidgetType.AddPromo:
        this.widgetSettings = JSON.parse(widgetSettingsJson) as AddPromoWidgetSettings;
        return { url: this.widgetSettings.promoListUrl || ls.landingUrl, logo: "" } as QRCodeSettings;
      case WidgetType.DownloadMobileApp:
        this.widgetSettings = JSON.parse(widgetSettingsJson) as DownloadMobileAppWidgetSettings;
        return { url: ls.landingUrl, logo: "" } as QRCodeSettings;
      case WidgetType.ConnectSocialNetwork:
        this.widgetSettings = JSON.parse(widgetSettingsJson) as ConnectSocialNetworkWidgetSettings;
        return { url: ls.landingUrl, logo: "" } as QRCodeSettings;
      case WidgetType.OneTimePayment:
        this.widgetSettings = JSON.parse(widgetSettingsJson) as SendPaymentLinkWidgetSettings;
        return { url: ls.landingUrl, logo: "" } as QRCodeSettings;
      case WidgetType.AnniversaryClub:
        this.widgetSettings = JSON.parse(widgetSettingsJson) as AnniversaryClubWidgetSettings;
        return { url: this.widgetSettings.anniversaryClubUrl || ls.landingUrl, logo: "" } as QRCodeSettings;
      case WidgetType.SendGiftCard:
        this.widgetSettings = JSON.parse(widgetSettingsJson) as SendGiftCardWidgetSettings;
        return { url: ls.landingUrl || this.widgetSettings.sendGiftCardUrl, logo: "" } as QRCodeSettings;
      case WidgetType.SendSurvey:
        this.widgetSettings = JSON.parse(widgetSettingsJson) as SendSurveyWidgetSettings;
        return { url: ls.landingUrl || this.widgetSettings.surveyUrl, logo: "" } as QRCodeSettings;
      case WidgetType.AddToWaitlist:
        this.widgetSettings = JSON.parse(widgetSettingsJson) as AddToWaitlistWidgetSettings;
        return { url: ls.landingUrl || this.widgetSettings.addToWaitListUrl, logo: "" } as QRCodeSettings;
      case WidgetType.AddToReferralProgram:
        this.widgetSettings = JSON.parse(widgetSettingsJson) as ReferralProgramWidgetSettings;
        return { url: this.widgetSettings.referralUrl || ls.landingUrl, logo: "" } as QRCodeSettings;
      case WidgetType.ScheduleAppointment:
        this.widgetSettings = JSON.parse(widgetSettingsJson) as ScheduleAppointmentWidgetSettings;
        return { url: ls.landingUrl || this.widgetSettings.appointmentUrl, logo: "" } as QRCodeSettings;
      case WidgetType.SmartPage:
        this.widgetSettings = JSON.parse(widgetSettingsJson) as SmartPageWidgetSettings;
        return { url: ls.landingUrl, logo: "" } as QRCodeSettings;
      case WidgetType.SeeMenu:
        this.widgetSettings = JSON.parse(widgetSettingsJson) as SeeMenuWidgetSettings;
        return { url: ls.landingUrl || this.widgetSettings.menuUrl, logo: "" } as QRCodeSettings;
      default:
        return null;
    }
  }

  getDeactiveSMSCampaignSettings(widgetId: WidgetType, ls: LocationWidgetSettings): boolean {
    const widgetSettingsJson = ls.widgetSettingsJson;
    if (!widgetSettingsJson) return null;
    switch (widgetId) {
      case WidgetType.SetReminder:
        this.widgetSettings = JSON.parse(widgetSettingsJson) as SetReminderWidgetSettings;
        return !this.widgetSettings.setReminderUrl || this.widgetSettings.setReminderUrl === null;
      case WidgetType.AddPromo:
        this.widgetSettings = JSON.parse(widgetSettingsJson) as AddPromoWidgetSettings;
        return !this.widgetSettings.promoListUrl || this.widgetSettings.promoListUrl === null;
      case WidgetType.AnniversaryClub:
        this.widgetSettings = JSON.parse(widgetSettingsJson) as AnniversaryClubWidgetSettings;
        return !this.widgetSettings.anniversaryClubUrl || this.widgetSettings.anniversaryClubUrl === null;
      default:
        return null;
    }
  }

  getWidgetIcon(id: number): string {
    return this.widgetIconsMap.get(id);
  }
  hasWidgetCampaign(id: WidgetType): boolean {
    return !(id == WidgetType.ValidateCoupon || id == WidgetType.IssuePointsOrStamps);
  }

  getSortedWidgets(widgets: LocationWithDefaultWidget[], configured: boolean = false): LocationWithDefaultWidget[] {
    if (configured) {
      const smartPageWidgetIndex: number = widgets.findIndex((item: LocationWithDefaultWidget) => {
        return item?.defaultWidgetSettings?.id === WidgetType.SmartPage;
      });

      if (smartPageWidgetIndex > 0) {
        const smartPageWidget: LocationWithDefaultWidget = widgets[smartPageWidgetIndex];

        if (!smartPageWidget.locationWidgetSettings?.disabled) {
          const slicedList: LocationWithDefaultWidget[] = this.getSlicedSortedWidgetList(widgets, smartPageWidgetIndex);

          widgets = [smartPageWidget].concat(slicedList);
        }
      }
    }

    return widgets;
  }

  private getSlicedSortedWidgetList(widgets: LocationWithDefaultWidget[], indexForReplace: number): LocationWithDefaultWidget[] {
    const slicedListUntilGoal: LocationWithDefaultWidget[] = widgets.slice(0, indexForReplace);
    const slicedListAfterGoal: LocationWithDefaultWidget[] = widgets[indexForReplace + 1] ? widgets.slice(indexForReplace + 1) : [];

    return slicedListUntilGoal.concat(slicedListAfterGoal);
  }

  private initWidgetIconsMap() {
    this.widgetIconsMap.set(WidgetType.IssueCoupon, "ri-coupon-line");
    this.widgetIconsMap.set(WidgetType.ValidateCoupon, "ri-qr-scan-line");
    this.widgetIconsMap.set(WidgetType.EnrollToLoyalty, "ri-service-line");
    this.widgetIconsMap.set(WidgetType.IssuePointsOrStamps, "ri-hand-coin-line");
    this.widgetIconsMap.set(WidgetType.RequestReview, "ri-chat-forward-line");
    this.widgetIconsMap.set(WidgetType.SetReminder, "ri-alarm-line");
    this.widgetIconsMap.set(WidgetType.AddPromo, "ri-file-list-3-line");
    this.widgetIconsMap.set(WidgetType.DownloadMobileApp, "ri-smartphone-line");
    this.widgetIconsMap.set(WidgetType.ConnectSocialNetwork, "ri-link");
    this.widgetIconsMap.set(WidgetType.OneTimePayment, "ri-refund-2-line");
    this.widgetIconsMap.set(WidgetType.ScheduleAppointment, "ri-calendar-todo-line");
    this.widgetIconsMap.set(WidgetType.AddToWaitlist, "ri-user-add-line");
    this.widgetIconsMap.set(WidgetType.SendSurvey, "ri-question-answer-line");
    this.widgetIconsMap.set(WidgetType.SendGiftCard, "ri-gift-2-line");
    this.widgetIconsMap.set(WidgetType.AnniversaryClub, "ri-cake-2-line");
    this.widgetIconsMap.set(WidgetType.AddToReferralProgram, "ri-medal-line");
    this.widgetIconsMap.set(WidgetType.SmartPage, "ri-settings-line");
    this.widgetIconsMap.set(WidgetType.SeeMenu, "ri-list-check-3");
  }
}
