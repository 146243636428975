<div class="table-responsive">
  <table class="table dt-responsive datatables custom no-footer dtr-inline mb-0" *ngIf="columnData">
    <thead class="table-light">
      <tr>
        <ng-container *ngFor="let c of columnData">
          <th
            *ngIf="!c.hidden && c.sortable"
            class="cursor-pointer"
            [class.sticky-column]="c.stickyColumn"
            [sortable]="c.key"
            [direction]="sorts?.column === c.key && sorts?.direction ? sorts?.direction : ''"
            (sort)="onSort($event)"
          >
            {{ c.tKey | transloco }}
            <span class="sort-icon"></span>
          </th>

          <th
            *ngIf="!c.hidden &&
            !c.sortable"
            class="not-sort"
          >{{ c.tKey | transloco }}</th>
        </ng-container>

        <th
          *ngIf="actionList?.length"
          class="not-sort"
          [class.td-fixed-right]="stickyActionListColumn"
        ></th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let row of tableData; let i = index">
        <ng-container *ngFor="let c of columnData">
          <td *ngIf="!c.hidden" class="column-wrap" [class.sticky-column]="c.stickyColumn">
            <div
              *ngIf="c.dataType === 'number' || c.dataType ==='string' || c.dataType === 'date'"
              class="{{ c.cellClasslist }}"
              [innerHTML]="(row[c.key] ?? '') | highlight: search"
            ></div>

            <div
              *ngIf="c.dataType === 'phone'"
              class="{{ c.cellClasslist }}"
              [innerHTML]="(row[c.key] ?? '') | phoneMask | phoneHighlight:search"
            ></div>

            <app-grid-cell-status
              *ngIf="c.dataType === 'status'"
              [status]="row[c.key]"
              [searchQuery]="search"
            ></app-grid-cell-status>

            <app-table-cell-custom-value
              *ngIf="c.dataType === 'custom' && c.customDataType"
              [dataType]="c.customDataType"
              [dataOptions]="c.customDataOptions ? c.customDataOptions(row) : null"
              [searchQuery]="search"
              [value]="row[c.key]">
            </app-table-cell-custom-value>
          </td>
        </ng-container>

        <td *ngIf="actionList?.length" class="column-nowrap column-action td-fixed-right">
          <app-table-cell-actions-menu
            [row]="row"
            [actionList]="actionList"
            (action)="onTableAction($event, row.id)">
          </app-table-cell-actions-menu>
        </td>
      </tr>
    </tbody>
  </table>

  <app-table-not-found-content *ngIf="!tableData.length && !loading"></app-table-not-found-content>

  <div class="row card-bottom card-bottom-mobile bottom-0 start-0" [class.position-absolute]="wrappedByCard">
    <div class="d-sm-flex justify-content-sm-between">
      <ngb-pagination
        [collectionSize]="total"
        [(page)]="pageIndex"
        [pageSize]="pageSize"
        [maxSize]="maxSize"
        (pageChange)="onPagination()"
      ></ngb-pagination>

      <app-pagination-size
        (clicked)="onPagination($event)"
        [pageSize]="pageSize"
      ></app-pagination-size>
    </div>
  </div>
</div>
