<ng-container *ngIf="marketingActions?.length">
  <div class="set-marketing-actions-widget" *transloco="let t; read: 'widgets.smartPage'">
    <div class="widget-explanation">
      <div class="widget-explanation__icon-wrapper">
        <i class="widget-explanation__icon ri-drag-move-line"></i>
      </div>

      <span class="widget-explanation__text bold-span" [innerHTML]="t('orderExplanation')"> </span>
    </div>

    <form [formGroup]="form" class="marketing-actions__form">
      <div
        cdkDropList
        [cdkDropListData]="marketingActions"
        formArrayName="marketingActions"
        class="marketing-actions"
        (cdkDropListDropped)="drop($event)"
      >
        <div
          *ngFor="let action of marketingActionsControl.controls; let i = index"
          cdkDrag
          class="marketing-action"
          [ngClass]="{ coupon: action.value?.widgetId === widgetType.IssueCoupon && action.value?.enabled }"
          [cdkDragData]="action"
          [cdkDragDisabled]="marketingActions[i].disabledSorting"
        >
          <div [formGroupName]="i" class="marketing-action__main-content">
            <div class="marketing-action__info">
              <i class="marketing-action__icon {{ marketingActions[i].icon }}" [ngClass]="{ disabled: marketingActions[i].disabledGlobal }">
              </i>

              <span class="marketing-action__name" [ngClass]="{ disabled: marketingActions[i].disabledGlobal }">
                {{ marketingActions[i].name | transloco }}
              </span>
            </div>

            <div [ngbTooltip]="marketingActions[i].disabledGlobal ? t('disabledActionTooltip') : ''" class="marketing-action__switcher">
              <div class="form-check form-switch form-switch-md switcher">
                <input
                  formControlName="enabled"
                  type="checkbox"
                  class="form-check-input"
                  [id]="'marketing-action-' + i + '-switcher'"
                  (change)="triggerSwitchAction(action, i)"
                />
                <label class="form-check-label" [for]="'marketing-action-' + i + '-switcher'"> </label>
              </div>
            </div>
          </div>

          <div *ngIf="action.value?.widgetId === widgetType.IssueCoupon && action.value?.enabled" class="coupon-carousel-wrapper">
            <div *ngIf="action.get('data')?.errors?.couponNotAvailable" class="mb-2">
              {{ t("selectedCouponError") }}
            </div>

            <app-coupon-carousel
              class="coupon-carousel"
              [loading]="loading"
              [selectedCoupon]="selectedCoupon"
              [coupons]="couponList"
              [withPreview]="false"
              [disabledNotActive]="true"
              [selectionType]="'single'"
              [viewType]="'small'"
              [couponSizeType]="'small'"
              [withNothingFoundText]="true"
              [couponConfig]="{ hasEdit: true, isEditDisabled: isImpersonated }"
              (selectCoupon)="onCouponListChanged($event)"
            >
            </app-coupon-carousel>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-container>
