<ng-container *ngIf="resources?.accounts as accounts">
  <form [formGroup]="form">
    <div class="form-group" *ngIf="accounts.length && !resources.isAccountAdminRole">
      <lib-form-label
        [label]="'common.controls.accounts' | transloco"
        [isRequired]="true"
      ></lib-form-label>

      <app-select-multiple
        bindValue="id"
        bindLabel="companyName"
        [formControl]="form.controls.accountIds"
        [items]="accounts"
        [isAllSelected]="useForAllAccounts"
        (isAllSelectedChange)="onAllSelectedChange($event)"
        [clearable]="true"
        [appendOptionsToBody]="true"
      >
      </app-select-multiple>
    </div>

    <ng-container *ngIf="assignedItems$ | async as assignedItems">
      <div class="mt-3" *ngIf="!useForAllAccounts && assignedItems.length > 0">
        <lib-form-label
          [label]="'templates.locations' | transloco"
          [isRequired]="true"
        ></lib-form-label>

        <div class="d-flex flex-column w-100">
          <div class="d-flex justify-content-between mb-2" *ngFor="let item of assignedItems">
            <div class="d-flex justify-content-between align-items-center bg-body w-100 h-40 ps-2 me-2">
              <span class="w-100">{{ item.companyName }}</span>
              <span class="d-flex justify-content-end fw-bold w-100 pe-2">
                {{ 'templates.locationsSelected' | transloco : { n: item.useForAllLocations ? ('templates.all_items_short' | transloco) : item.locationIds.length } }}
              </span>
            </div>

            <div
              class="d-flex justify-content-center align-items-center bg-body width-40 cursor-pointer"
              (click)="openLocationModal(item.accountId)">
              <i class="ri-edit-line font-size-20"></i>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </form>
</ng-container>
