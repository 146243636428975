import { Component, NgZone, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { TranslocoService } from "@ngneat/transloco";
import { OAuthService } from "angular-oauth2-oidc";
import { JwksValidationHandler } from "angular-oauth2-oidc-jwks";
import * as waves from "node-waves";
import { filter, map } from "rxjs/operators";
import { authConfig } from "./auth.config";
import { AppConfigService } from "./core";
import { ProfileModel } from "./core/models/profile-model";
import { AccountService } from "./core/services/account.service";
import { PlatformTypes } from "./models";

@Component({ selector: "app-clearline", templateUrl: "app.component.html", styleUrls: ["app.component.scss"] })
export class AppComponent implements OnInit {
  user: ProfileModel;
  loginOnly = false;
  platformTypes = PlatformTypes;
  get year() {
    return new Date().getFullYear();
  }

  constructor(
    private router: Router,
    private titleService: Title,
    private translateSvc: TranslocoService,
    private oauthService: OAuthService,
    private accountService: AccountService,
    private configSvc: AppConfigService,
    private zone: NgZone
  ) {}

  ngOnInit() {
    waves.init();
    this.configureOidc(); // fixme probably should be a part of AuthService
    if (!window.location.href.includes("admintest.")) setTimeout((t) => this.loadHelpHeroScript(), 1000);
  }

  private loadHelpHeroScript() {
    const script = document.getElementById("helpHeroId");
    if (script) return;

    const node = document.createElement("script");
    node.src = "//app.helphero.co/embed/Aa7eTXxk64";
    node.type = "text/javascript";
    node.id = "helpHeroId";

    const app = this;

    document.getElementsByTagName("body")[0].appendChild(node);

    this.zone.runOutsideAngular(() => {
      const interval = setInterval(function () {
        if (typeof (window as any).HelpHero !== "undefined" && app.user) {
          clearInterval(interval);
          (window as any).HelpHero.identify(app.user.sub);
        }
      }, 100);
    });
    this.setBrowserTitle();
  }

  private async configureOidc() {
    const authConf: IAuthConfig = this.configSvc.appData.authConfig;

    this.oauthService.configure(authConfig(authConf));

    const urlParams = new URLSearchParams(window.location.search);
    const actAsUserId = urlParams.get("actAsUserId") || sessionStorage.getItem("actAsUserId");
    if (actAsUserId) {
      this.oauthService.setStorage(sessionStorage);
      if (urlParams.get("actAsUserId")) sessionStorage.clear();
      sessionStorage.setItem("actAsUserId", actAsUserId);
    } else this.oauthService.setStorage(localStorage);

    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    const culture = "en-US";
    this.oauthService.customQueryParams = {
      ui_locales: culture,
      actAsUserId: actAsUserId
    };
    this.oauthService.setupAutomaticSilentRefresh();

    try {
      await this.oauthService.loadDiscoveryDocumentAndTryLogin();
      const user = this.accountService.user;

      this.accountService.refreshUser(user);

      const redirectPath = localStorage.getItem("redirectPath");
      if (redirectPath && this.accountService.isLoggedIn) {
        this.router.navigateByUrl(redirectPath);
        localStorage.removeItem("redirectPath");
      }
    } catch (error) {
      console.error(error);
    }
  }

  private setBrowserTitle() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.router.routerState.root;
          let routeTitle = "";
          while (route!.firstChild) {
            route = route.firstChild;
          }
          if (route.snapshot.data["title"]) {
            routeTitle = route!.snapshot.data["title"];
          }
          return routeTitle;
        })
      )
      .subscribe((title: string) => {
        if (title) {
          this.titleService.setTitle(`${this.configSvc.appData.title} - ${this.translateSvc.translateObject("common.menu." + title)}`);
        }
      });
  }
}
