export type TableCompareFn = (a: string | number, b: string | number) => number;

export type TableColumnDataSortType = TableColumnData["dataType"];

export interface TableColumnData {
  /** The `item` object key (property) */
  key: string;
  /** The translation key (i18n) */
  tKey: string;
  dataType: "string" | "number" | "date" | "phone" | "status" | "custom";
  sortable: boolean;
  hidden?: boolean;
  cellClasslist?: string;
  /** Stick the column for the horizontal scroll */
  stickyColumn?: boolean;
  customDataType?: TableCustomDataType;
  /** When dataType === "custom" */
  customDataOptions?: (row: unknown) => TableCustomDataOptions;
}

export interface TableCellAction {
  actionType: TableCellActionType;
  /** When dataType === "custom" */
  customTypeValue?: string;
}

export type TableCellActionType = "edit" | "delete" | "view" | "custom" | "enable" | "disable";

export type TableCustomDataType = "active" | "check" | "status" | "link";

export type TableCustomDataOptions = TableCustomDataLinkOptions;

export type TableCustomDataLinkOptions = {
  linkName: string;
  /** Full URL. Once `linkUrl` or `redirectTo` is required */
  linkUrl?: string;
  onClick?: () => void;
  /** Relative URL. Once `linkUrl` or `redirectTo` is required */
  redirectTo?: string;
  redirectTarget?: "_blank" | "_self";
};

export interface TableCellActionDataIn extends TableCellAction {
  label?: string;
  disabled?: (row: unknown) => boolean;
}

export interface TableCellActionDataOut {
  action: TableCellAction;
  itemId: number | string;
}
