<ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>

<dropzone
  *transloco="let t; read: 'common.file'"
  (sending)="onSending()"
  class="dropzone-container"
  [config]="config"
  fxLayout="row"
  fxLayoutAlign="start strech"
  fxFlex="auto"
  [message]="t('fileUpload')"
  (error)="onUploadError($event)"
  (success)="onUploadSuccess($event)"
>
  <a *ngIf="result" class="dz-remove" (click)="resetDropzoneUploads()" data-dz-remove="">{{ t("remove") }}</a>
</dropzone>

<div *ngIf="fileType === FileType.Video && result?.fileUrl">
  <video
    #videoPlayer
    autoplay
    muted
    crossOrigin="anonymous"
    [src]="result.fileUrl"
    (error)="videoOnError()"
    (loadeddata)="videoOnLoad()"
    width="400"
    class="d-none">
  </video>
</div>
