import { DisplayQrCodeLogoTypes } from "./enums";

export class QrCodeConfiguration {
  public primaryColor: string;
  public secondaryColor: string;
  public backgroundColor: string;
  public primaryTextColor: string;
  public secondaryTextColor: string;
  public buttonTextColor?: string;
  public buttonBackgroundColor?: string;
  public logo: string;
  public qrLogoType: DisplayQrCodeLogoTypes;
  public hasFrame: boolean;
  public logoBrandId?: number;
}
export class ShortLinkQrConfigurationDto extends QrCodeConfiguration {
  public primaryText: string;
  public secondaryText: string;
  public width: number;
  public height: number;
  public hasPrintId: boolean;
  public hasCouponCode: boolean;
  public hasSerialNumber: boolean;
  public printId?: number;
  public description?: string;
}

export class ShortLinkQrConfiguration extends ShortLinkQrConfigurationDto {
  public serialNumber?: string;
  public couponCode: string;
}
