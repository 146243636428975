export interface ProfileModel {
  sub: string | null;
  jti: string | null;
  useage: string | null;
  at_hash: string | null;
  nbf: number | null;
  exp: number | null;
  iat: number | null;
  iss: string | null;
  unique_name: string | null;
  logo: string | null;
  locationId: number | null;
  cashRegisterId: number | null;
  role: string;
  brandId: number;
  partnerId?: number;

  // from @admin app
  email_verified: boolean | null;
  email_verified_date: Date | null;
  email: string | null;
  name: string | null;
  family_name: string | null;
  avatar: string | null;
  doneQuickSetup: boolean;
  configured: boolean;
  accountId?: number;
  posSystemId?: string;
  subscriptionId: string | null;
  clientId: string | null;
  actsub?: boolean;

  // from @admin app
  qui: string | null;
  brandShortName: string;

  // from @cmc app:
  nickname: string | null;
  partner?: number;
}
