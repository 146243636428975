<form [formGroup]="form" *transloco="let t; read: 'widgets.seeMenu'">
  <h5>{{ t("title") }}</h5>
  <div class="col-md-12">
    <div class="row mb-3">
      <div class="col-md-12">
        <app-url-form-control [label]="t('link')" formControlName="menuUrl" [mark]="true" [validators]="validators"></app-url-form-control>
      </div>
    </div>

    <div class="row mb-3">
      <app-custom-image-uploader
        size="L"
        formControlName="imageUrl"
        labelCssClass="font-16 font-bold"
        [label]="t('image')"
        [hasCropper]="true"
        [isDisabled]="false"
        [isClearable]="true"
        [isRequired]="false"
      ></app-custom-image-uploader>
    </div>
  </div>
</form>
