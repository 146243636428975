import { ApplicationStyles } from "./brand-color-palette";
import { DisplayQrCodeLogoTypes } from "@app/models/enums";
import {
  ContentSettings,
  CouponContentSettings,
  ScreenSizesJsonAsset,
  Template,
  TemplateExtendedProperties,
  TemplatePreviewOptions,
  UrlContentSettings
} from "clearline-common";
import { DigitalAssetGetDto, MediaContentDto, TemplateValidationErrorDto } from "clearline-api";

export enum TemplateInfoEnum {
  customContentForCFSId = 17,
  cfsTemplateName = "Editable CFS Template",
  cfsQrCodeTemplateName = "QR Code Editable CFS Template",
  minUrlLength = 10,
  maxUrlLength = 800
}

export class LocationTemplate {
  locationId: number;
  canEnable: boolean;
  enabled: boolean;
}

export interface GeneratedTemplateGetDto extends CfsTemplate {
  templateContentTypeId: number;
}

export interface CfsTemplate extends Template {
  industryId?: number;
  industryName?: string;
  fullName?: string;
  templateTypeName?: string;
  marketingChannel?: string;
  useOnCfs?: boolean;
  useOnPos?: boolean;
  activeLocationCount?: number;
  locationTemplates?: LocationTemplate[];
  accountIds?: number[];
  useForAllAccounts?: boolean;
  activeAccountsCount?: number;
  activeAccountsCountGlobalTypeView?: string;
  errors?: TemplateValidationErrorDto[];
  applicationStyles?: ApplicationStyles;
  displayTypeIds?: number[];
  useAllDisplayTypes?: boolean;
}

export interface QrCodeContentSettings {
  backgroundColor?: string;
  backgroundImage?: string;
  logoUrl?: string;
  imageUrl?: string;
  qrCodePrimaryColor?: string;
  qrCodeSecondaryColor?: string;
  qrCodeBackground?: string;
  qrCodeHasFrame?: boolean;
  qrCodeLogo?: string;
  qrCodeLogoType?: DisplayQrCodeLogoTypes;
  qrCodeUrl?: string;
  buttonText?: string;
  buttonWidth?: number;
  buttonTextColor?: string;
  buttonBackgroundColor?: string;
  qrActionText?: string;
  qrActionColor?: string;
  subtitleText?: string;
  subtitleColor?: string;
  titleText?: string;
  titleColor?: string;
}

export type TemplateProperties = ContentSettings | UrlContentSettings | CouponContentSettings | TemplateExtendedProperties | null;

export class TemplateLocations {
  locationIds: number[];
}
export class GlobalTemplateAccounts {
  useForAllAccounts: boolean;
  accountIds: number[];
}

export interface TemplatesCarouselData {
  templateList?: CfsTemplate[];
  withPause?: boolean;
  updateOnNewCircle?: boolean;
}

export interface TemplateBasePreviewOptions {
  digitalAsset?: DigitalAssetGetDto;
  json?: string;
  screenSizesJsonAsset?: ScreenSizesJsonAsset;
  mediaContent?: MediaContentDto;
}

export interface TemplatePreviewSelectableOptions extends TemplatePreviewOptions {
  id?: number; // id, depending on context - DigitalAsset.Id|Template.Id|...
}
