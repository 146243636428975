import { ApexAxisChartSeries } from "ng-apexcharts";
import { ActivityByPeriodGetDto, ChartData, ChartOptions, SalesPersonActivityByPeriod } from "@app/models";

export class DashboardSalesPersonActivitiesUtilities {
  constructor() {}

  private static chartOptions: Partial<ChartOptions> = {
    chart: {
      type: "bar",
      toolbar: {
        show: true,
        export: {
          csv: {
            filename: "clearline_sales-persons_activities"
          },
          svg: {
            filename: "clearline_sales-persons_activities"
          },
          png: {
            filename: "clearline_sales-persons_activities"
          }
        }
      }
    },
    series: [
      {
        name: "basic",
        data: []
      }
    ],
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: "50%"
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: [],
      labels: {
        formatter: DashboardSalesPersonActivitiesUtilities.activityValueFormatter
      }
    },
    yaxis: {
      labels: {
        maxWidth: 200
      }
    },
    tooltip: {
      shared: false,
      intersect: true,
      enabled: true,
      enabledOnSeries: false,
      onDatasetHover: {
        highlightDataSeries: true
      },
      x: {
        show: true
      },
      y: {
        formatter: DashboardSalesPersonActivitiesUtilities.activityValueFormatter
      }
    }
  };

  static getChartOptions(info: ActivityByPeriodGetDto<SalesPersonActivityByPeriod>): Partial<ChartOptions> {
    const data: ChartData = this.getApexAxisChartData(info);
    const chartOptions: Partial<ChartOptions> = { ...DashboardSalesPersonActivitiesUtilities.chartOptions };

    chartOptions.chart.height = DashboardSalesPersonActivitiesUtilities.getChartHeight(data.categories.length);
    chartOptions.series = data.series;
    chartOptions.xaxis.categories = data.categories;
    chartOptions.xaxis.tickAmount = DashboardSalesPersonActivitiesUtilities.getMaxActivity(data);
    chartOptions.xaxis = { ...chartOptions.xaxis };

    return chartOptions;
  }

  private static activityValueFormatter(value: string): string {
    const numberValue = value ? +value : 0;

    return numberValue.toFixed(0);
  }

  private static getDefaultSeries(): ApexAxisChartSeries {
    return [
      {
        name: "Activities",
        color: "#34C38FD8",
        data: []
      }
    ];
  }

  private static getMaxActivity(data: ChartData): number {
    const series: ChartData = data.series;
    const maxValue: number = series[0].data.sort((a: number, b: number) => b - a)[0];

    if (maxValue === 0) {
      return 2;
    }

    return maxValue <= 20 ? maxValue : 20;
  }

  private static getApexAxisChartData(info: ActivityByPeriodGetDto<SalesPersonActivityByPeriod>): ChartData {
    const series: ApexAxisChartSeries = DashboardSalesPersonActivitiesUtilities.getDefaultSeries();
    const categories = [];

    if (info?.data?.length) {
      info.data.forEach((item: SalesPersonActivityByPeriod) => {
        const totalCount: number = item.totalCount || 0;
        const { displayName } = item;
        const handlesName: string = DashboardSalesPersonActivitiesUtilities.getSalesPersonName(displayName);

        series[0].data.push(totalCount as any);
        categories.push(handlesName);
      });
    } else {
      categories.push("");
    }

    return {
      series,
      categories
    };
  }

  private static getSalesPersonName(name: string): string {
    const username = name.split("(")[0]?.trim();

    return username || name;
  }

  private static getChartHeight(seriesNumber: number): number {
    return 68 + seriesNumber * 28;
  }
}
