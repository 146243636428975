import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, Output } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import {
  CfsTemplateMediaConfig,
  CFSTemplateType,
  CfsTemplateUtils,
  ContentSettings,
  Template,
  TemplatePreviewOptions,
  TemplatePreviewType,
  TemplateProperties,
  UrlContentSettings
} from "clearline-common";
import { BehaviorSubject, of } from "rxjs";
import { CommonModule } from "@angular/common";
import { CfsTemplatePreviewComponent } from "./cfs-template-preview";
import { TemplatePreviewService } from "./template-preview.service";
import { take } from "rxjs/operators";
import { DigitalAssetGetDto, DigitalAssetScreenSize } from "clearline-api";

@Component({
  selector: "lib-cfs-template",
  templateUrl: "./cfs-template.component.html",
  styleUrls: ["./cfs-template.component.scss"],
  standalone: true,
  imports: [CommonModule, CfsTemplatePreviewComponent]
})
export class CfsTemplateComponent implements AfterViewInit {
  @Input() isTestMode = false;
  @Input() index = 0;
  @Input() previewType: TemplatePreviewType = TemplatePreviewType.Active;
  @Input() forPrint = false;
  @Input() mediaConfig: CfsTemplateMediaConfig = { muted: true, autoplay: true };
  @Input() isLowResolutionScreen = false;

  @Input() set template(template: Template) {
    if (template) {
      const { name, generatedBody, configuration, templateProperties } = <Template>template;

      this.generatedBody = generatedBody ? this.sanitizer.bypassSecurityTrustHtml(generatedBody as string) : "";
      this.templateType = this.getTemplateType(generatedBody as string);
      this.title = name;
      this.displayTime = configuration?.options?.displayTime;
      this.templateProperties = templateProperties;

      if (this.templateType === CFSTemplateType.Lottie) {
        this.handleLottie(template as Template);
      }
    }
  }

  @Output() print = new EventEmitter();
  @Output() templateClick = new EventEmitter();
  @Output() ready = new EventEmitter();

  readonly CFSTemplateType = CFSTemplateType;
  private readonly smallScreen = 768;
  private readonly mediaReadyDelay = 1000;
  private isScreenSizeResponsive = false;
  private templateProperties?: TemplateProperties;
  private mediaCanPlayEventListener: (() => void) | null = null;

  title = "";
  displayTime?: number;
  contentDuration?: number;
  generatedBody: SafeHtml = "";
  screenSize?: DigitalAssetScreenSize;
  templateType?: CFSTemplateType | null;
  previewOptions$ = new BehaviorSubject<TemplatePreviewOptions | null>(null);
  contentSettings: ContentSettings | undefined;
  digitalAsset: DigitalAssetGetDto | undefined;

  @HostListener("window:resize") handleResizeEvent(): void {
    this.setScreenSize();
  }

  constructor(private sanitizer: DomSanitizer, private elementRef: ElementRef, private templatePreviewService: TemplatePreviewService) {}

  ngAfterViewInit(): void {
    const videoElement: HTMLVideoElement | null = this.getVideoPlayer();

    if (videoElement) {
      const successCallback = () => this.ready.emit();

      this.prepareMediaElementPlayback(successCallback, videoElement, this.mediaReadyDelay);
    } else {
      this.ready.emit();
    }

    this.initVideoTemplate();
  }

  onTemplateClick() {
    this.templateClick.emit();
  }

  onLaunchPrint(): void {
    if (this.forPrint) {
      this.print.emit();
    }
  }

  play(): void {
    const videoElement: HTMLVideoElement | null = this.getVideoPlayer();

    if (videoElement) {
      const successCallback = () => videoElement.play().then();

      this.prepareMediaElementPlayback(successCallback, videoElement, 0);
    }
  }

  stop(): void {
    const videoElement: HTMLVideoElement | null = this.getVideoPlayer();

    if (videoElement) {
      this.removeMediaPlayEventListener(videoElement);

      if (!videoElement.paused) {
        videoElement.pause();
      }

      videoElement.currentTime = 0;
    }
  }

  private handleLottie(template: Template): void {
    const previewOptions$ = template?.previewOptions ? of(template.previewOptions) : this.templatePreviewService.getLottieOptions(template);

    previewOptions$.pipe(take(1)).subscribe((previewOptions: TemplatePreviewOptions | null) => {
      const { mediaContent } = previewOptions || {};
      this.isScreenSizeResponsive = CfsTemplateUtils.getIsMediaScreenSizeResponsive(mediaContent);

      this.previewOptions$.next(previewOptions);
      this.setScreenSize();
    });
  }

  private setScreenSize(): void {
    const updatedScreenSize: DigitalAssetScreenSize = this.getScreenSizeType();

    if (this.screenSize !== updatedScreenSize) {
      this.screenSize = updatedScreenSize;
    }
  }

  private initVideoTemplate(): void {
    const videoElement: HTMLVideoElement | null = this.getVideoPlayer();

    if (videoElement && this.previewType === TemplatePreviewType.Active) {
      const contentDuration: number | undefined = (this.templateProperties as UrlContentSettings | undefined)?.ContentDuration;
      this.contentDuration = contentDuration ? Number(contentDuration) : 0;
      const { muted = true, autoplay } = this.mediaConfig;

      videoElement.muted = muted;
      videoElement.loop = autoplay || !this.contentDuration || !!(this.displayTime && this.displayTime > this.contentDuration);

      if (autoplay) {
        videoElement.autoplay = true;
      }
    }
  }

  private getScreenSizeType(): DigitalAssetScreenSize {
    let updatedScreenSize: DigitalAssetScreenSize = DigitalAssetScreenSize.Full;

    if (this.isScreenSizeResponsive && !this.isLowResolutionScreen) {
      if (window.innerWidth <= this.smallScreen) {
        updatedScreenSize = DigitalAssetScreenSize.Compact;
      } else if (window.innerHeight >= window.innerWidth) {
        updatedScreenSize = DigitalAssetScreenSize.Vertical;
      }
    }

    return updatedScreenSize;
  }

  private getTemplateType(generatedBody: string): CFSTemplateType | null {
    const temporaryDiv: HTMLElement = document.createElement("div");
    temporaryDiv.innerHTML = generatedBody;
    const templateElement: HTMLElement | null = temporaryDiv.querySelector(".template");

    if (templateElement) {
      const classList: DOMTokenList = templateElement.classList;

      for (const type in CFSTemplateType) {
        if (classList.contains(CFSTemplateType[type as keyof typeof CFSTemplateType])) {
          return CFSTemplateType[type as keyof typeof CFSTemplateType];
        }
      }
    }

    return null;
  }

  // Media Element utils:
  private getVideoPlayer(): HTMLVideoElement | null {
    if (this.templateType !== CFSTemplateType.Video) {
      return null;
    }

    return this.elementRef.nativeElement.querySelector("video.template-video");
  }

  private prepareMediaElementPlayback(callback: () => void, mediaElement: HTMLMediaElement, timeout: number): void {
    if (mediaElement) {
      this.removeMediaPlayEventListener(mediaElement);

      if (this.isMediaElementDataLoaded(mediaElement)) {
        callback();
      } else {
        this.mediaCanPlayEventListener = () => {
          setTimeout(() => callback(), timeout);
          this.removeMediaPlayEventListener(mediaElement);
        };

        mediaElement.addEventListener("canplaythrough", this.mediaCanPlayEventListener.bind(this), { once: true });
      }
    }
  }

  private removeMediaPlayEventListener(mediaElement: HTMLMediaElement): void {
    if (this.mediaCanPlayEventListener) {
      mediaElement.removeEventListener("canplaythrough", this.mediaCanPlayEventListener);
      this.mediaCanPlayEventListener = null;
    }
  }

  private isMediaElementDataLoaded(mediaElement: HTMLMediaElement): boolean {
    const successState = 4;

    return mediaElement.readyState >= successState;
  }
}
