<div class="row text-start mt-4 font-size-14" *transloco="let t; read: 'widgets.enrollLoyalty'">
  <div *ngIf="!rewardsList.length; else rewardsListTemplate" class="d-flex flex-row align-items-center">
    <div class="w-100 m-2">
      <app-lp-details-card *ngIf="selectedCard">
        <div class="col-12 font-size-13">
          <div class="row">
            <div class="col-xxl-3 col-xl-4 col-lg-3 col-md-12">
              <img [ngStyle]="{ 'background-image': 'url(' + selectedCard.frontImage + ')' }" class="card-image" />
            </div>
            <div class="col-xxl-9 col-xl-8 col-lg-9 col-md-12">
              <div class="col-12 d-flex justify-content-between mb-2">
                <div class="text-muted">{{ t("labelProgramTitle") }}</div>
                <div class="text-end">{{ selectedCard.title }}</div>
              </div>

              <div class="col-12 d-flex justify-content-between mb-2">
                <div class="text-muted">{{ t("labelProgramType") }}</div>
                <div class="text-end">{{ selectedCardTypeSetting.name }}</div>
              </div>
            </div>
          </div>

          <div class="col-12">
            <hr class="mb-3" />
          </div>

          <div class="row" *ngIf="loyaltyProgramDetailsResult as res">
            <!-- Goal balance -->
            <div class="col-12 d-flex justify-content-between mb-2 font-size-14">
              <div class="text-muted">{{ t("labelGoalBalance") }}</div>
              <div>{{ res.goalBalance }} {{ selectedCardTypeSetting.suffixList }}</div>
            </div>

            <!-- Starting position -->
            <div class="col-12 d-flex justify-content-between mb-2 font-size-14">
              <div class="text-muted">{{ t("labelStartingPosition") }}</div>
              <div>{{ res.startingStamps }} {{ selectedCardTypeSetting.suffixList }}</div>
            </div>

            <!-- Max per day -->
            <div class="col-12 d-flex justify-content-between mb-2 font-size-14">
              <div class="text-muted">{{ t("labelMaxPerDay") }}</div>
              <div>{{ res.maxPerDay }} {{ selectedCardTypeSetting.suffixList }}</div>
            </div>

            <!-- Calculation configuration -->
            <ng-container *ngIf="res.calculationConfiguration as cc">
              <div *ngIf="!isStampCard" class="col-12 d-flex justify-content-between mb-2 font-size-14">
                <div class="text-muted">{{ t("labelPointsToDollars") }}</div>
                <div>{{ t('labelAmountValue', { value: cc.amount }) }} = {{ t('labelPointValue', { value: cc.count }) }}</div>
              </div>
            </ng-container>

            <!-- Minimum requirements -->
            <div *ngIf="res.calculationConfiguration as cc" class="col-12 d-flex justify-content-between mb-2 font-size-14">
              <ng-container *ngIf="res.conditionConfiguration as condConf">
                <ng-container *ngIf="!!condConf.minimumAmount">
                  <div class="text-muted">{{ t("labelPurchaseMinAmount") }}</div>
                  <div>{{ t('labelAmountValue', { value: condConf.minimumAmount}) }}</div>
                </ng-container>
                <ng-container *ngIf="!!condConf.minimumCount">
                  <div class="text-muted">{{ t("minCount") }}</div>
                  <div>{{ t('labelCountValue', { value: condConf.minimumCount}) }}</div>
                </ng-container>
              </ng-container>
            </div>

            <!-- Expiration period -->
            <ng-container>
              <div class="col-12 d-flex justify-content-between mb-2 font-size-14" *ngIf="res.expiration as exp">
                <div class="text-muted">{{ expirationPeriodTitle }}</div>
                <div class="font-size-13">{{ expirationPeriod }}</div>
              </div>
            </ng-container>
          </div>
        </div>
      </app-lp-details-card>
    </div>

    <div class="w-100 m-2">
      <div *ngIf="rewardsList.length == 0">
        <span class="font-size-16 font-bold">{{ t("titleMessageRewards") }}</span>
        <br />
        <span class="font-size-14">
          {{ t("messageRewards") }}
        </span>
        <br /><br />
      </div>

      <ng-template *ngTemplateOutlet="btnAddRewardTierTemplate"></ng-template>
    </div>
  </div>
</div>


<ng-template #rewardsListTemplate>
  <div class="text-center" *ngIf="rewardsList.length > 0">
    <h5 class="mb-2">{{ 'widgets.enrollLoyalty.titleRewardsList' | transloco }}</h5>

    <div class="bg-light d-flex justify-content-between item-reward-list" *ngFor="let item of rewardsList; index as i">
      <div class="col-xxl-2 col-xl-3 col-lg-3 col-3 text-start text-muted">{{ item.name }}</div>
      <div class="col-xxl-8 col-xl-6 col-lg-6 col-3 text-start text-nowrap">{{ item.couponName }}</div>
      <div class="col-xxl-2 col-xl-3 col-lg-3 col-3 text-end align-self-center">
        <button class="btn btn-action-icon px-2" (click)="editItemRewardList(item)">
          <i class="ri-edit-line font-size-20"></i>
        </button>
        <button class="btn text-danger btn-action-icon-delete p-0" (click)="deleteItemRewardList(item)">
          <i class="ri-delete-bin-line font-size-20"></i>
        </button>
      </div>
    </div>
  </div>

  <ng-container *ngIf="isRewardsOptionsButtonVisible$ | async">
    <ng-template *ngTemplateOutlet="btnAddRewardTierTemplate"></ng-template>
  </ng-container>
</ng-template>

<ng-template #btnAddRewardTierTemplate>
  <div class="">
    <button type="button" class="btn btn-outline-primary font-size-15 w-100" (click)="addRewardTierModal()">
      {{ 'widgets.enrollLoyalty.btnAddRewardTier' | transloco }}
    </button>
  </div>
</ng-template>
