import { Injectable } from "@angular/core";
import { Brand } from "@app/models";
import { DeliveryProviderGetDto } from "clearline-api";

@Injectable({
  providedIn: "root"
})
export class SenderOptionsDataService {
  getBrandNames(brand: Brand): string[] {
    return brand
      ? [
          brand.name
            .replace(/[^a-zA-Z0-9 ]/g, "")
            .replace(/  +/g, " ")
            .trim()
            .split(" ")
            .join(".")
            .toLowerCase(),
          brand.shortName.toLowerCase()
        ]
      : [];
  }

  getDeliveryProviderLabel(deliveryProviderId: number, deliveryMethods: DeliveryProviderGetDto[]): string {
    const deliveryProvider: DeliveryProviderGetDto = deliveryMethods.find((provider) => provider.id === deliveryProviderId);

    return this.getDeliveryProviderLabelByCode(deliveryProvider?.code);
  }

  getDeliveryProviderLabelByCode(providerCode: string): string {
    return providerCode ? `senders.deliveryProviders.${providerCode}` : "";
  }
}
