import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output } from "@angular/core";
import {
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import { WidgetSettingsBaseComponent } from "@app/pages/components/widget-settings/widget-settings-base";
import {
  RequestReviewWidgetSettings,
  ReviewSourceData
} from "@app/pages/components/widget-settings/request-review-widget-settings/review-request-widget-settings";
import { Patterns } from "@app/shared/validators";
import { IndustryService, SubscriptionPlanService } from "@app/services";
import { Brand, Location, ReviewSource, SubscriptionPlan } from "@app/models";
import { AuthService } from "@app/core";

@Component({
  selector: "app-request-review-widget-settings",
  templateUrl: "./request-review-widget-settings.component.html",
  styleUrls: ["./request-review-widget-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: RequestReviewWidgetSettingsComponent
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RequestReviewWidgetSettingsComponent),
      multi: true
    }
  ]
})
export class RequestReviewWidgetSettingsComponent extends WidgetSettingsBaseComponent implements OnInit {
  public otherReviewSourceList: ReviewSource[] = [];
  public notOtherReviewSourceList: ReviewSource[] = [];
  public allReviewSourceList: ReviewSource[] = [];
  public reviewSources: UntypedFormArray;
  private _location: Location;
  public limitReviewRequest: number = 0;
  private _brand: Brand;
  private _plan: SubscriptionPlan;
  private _storage: Storage = sessionStorage;
  public search: string;
  isLoad = false;

  @Input() set brand(value: Brand) {
    if (value) this._brand = value;
  }

  get brand(): Brand {
    return this._brand;
  }

  @Input() set location(value: Location) {
    if (value) this._location = value;
  }

  get location(): Location {
    return this._location;
  }

  public get plan(): SubscriptionPlan {
    if (!this._plan) {
      this._plan = (JSON.parse(this._storage.getItem("plan")) as SubscriptionPlan) || null;
    }
    return this._plan;
  }
  public get sourcesCount() {
    let count = 0;
    if (this.form.controls.reviewGoogle.value) count++;
    if (this.form.controls.reviewYelp.value) count++;
    if (this.reviewSources.value) count += this.reviewSources.value.length;

    return count;
  }
  get isDisableAddBtn() {
    const hasEmpty = this.reviewSources.value.some((o) => o.code === "" || o.url === "");
    return hasEmpty || this.sourcesCount >= this.limitReviewRequest || !this.reviewSources.valid;
  }

  private _widget: RequestReviewWidgetSettings;
  public validators: Array<ValidatorFn> = [Validators.required, Validators.pattern(Patterns.url), Validators.maxLength(2000)];
  public linksVaidators: Array<ValidatorFn> = [Validators.pattern(Patterns.url), Validators.maxLength(2000)];
  @Output() widgetSettingsReady = new EventEmitter();
  @Input() set widgetSettingsJson(value: string) {
    if (value) this.requestReviewWidgetSetting = JSON.parse(value) as RequestReviewWidgetSettings;
  }

  public set requestReviewWidgetSetting(value: RequestReviewWidgetSettings) {
    if (value && !this._widget) {
      setTimeout(() => {
        if (value.reviewGoogle) this.form.controls.reviewGoogle.setValue(value.reviewGoogle);
        if (value.reviewYelp) this.form.controls.reviewYelp.setValue(value.reviewYelp);

        if (value.reviewFacebook) this.initReviewSourceDataForm("facebook", value.reviewFacebook);
        if (value.reviewLeafly) this.initReviewSourceDataForm("leafly", value.reviewLeafly);
        if (value.reviewNextdoor) this.initReviewSourceDataForm("nextdoor", value.reviewNextdoor);
        if (value.reviewWeedmaps) this.initReviewSourceDataForm("weedmaps", value.reviewWeedmaps);

        this.setValueReviewRequestData(value.reviewSources || []);
        this.form.updateValueAndValidity();
      });
    }

    this.onChange(value);
    this.cdf.markForCheck();
  }

  constructor(
    private cdf: ChangeDetectorRef,
    private industryService: IndustryService,
    private planService: SubscriptionPlanService,
    public authService: AuthService
  ) {
    super(authService);

    this.form = new UntypedFormGroup({
      reviewGoogle: new UntypedFormControl("", this.linksVaidators),
      reviewYelp: new UntypedFormControl("", this.linksVaidators),
      reviewSources: new UntypedFormArray([], [this.isDuplicatedLinkRequiredValidator()])
    });
  }

  ngOnInit(): void {
    console.log("PLAN Review Request =", this.plan);
    this.reviewSources = this.form.controls.reviewSources as UntypedFormArray;
    this.form.setValidators([this.atLeastOneLinkRequiredValidator(), this.limitLinksExceededValidator()]);
    this.form.updateValueAndValidity();
    this.getRequestLimitByPlan();
    this.loadReviewSourcesByCountry();
    this.widgetSettingsReady.emit();
    this.cdf.detectChanges();
  }

  ngAfterViewInit() {
    this.isLoad = true;
    this.cdf.markForCheck();
  }

  setValueReviewRequestData(array: ReviewSourceData[]) {
    array.forEach((item) => {
      this.initReviewSourceDataForm(item.code, item.url);
    });
  }
  loadReviewSourcesByCountry() {
    this.industryService.getReviewSourcesByCountry(this.brand.industryId, this.brand.country).subscribe((res) => {
      this.allReviewSourceList = res.sort((a, b) => a.name.localeCompare(b.name));
      this.notOtherReviewSourceList = res.filter((o) => o.industryId === this.brand.industryId);
      this.otherReviewSourceList = res.filter((o) => o.industryId !== this.brand.industryId);
      this.cdf.detectChanges();
    });
  }

  getRequestLimitByPlan() {
    if (this.brand && this.brand.id) {
      this.getRequestLimitByLocation();
    } else {
      if (this.plan && this.plan.limits.reviewRequestLimit) this.limitReviewRequest = this.plan.limits.reviewRequestLimit;
    }
  }

  getRequestLimitByLocation() {
    if (this.location) {
      this.planService.getLocationPlan(this.location.id).subscribe((res) => {
        if (res && res.limits.reviewRequestLimit) this.limitReviewRequest = res.limits.reviewRequestLimit;
        this.cdf.markForCheck();
      });
    }
  }

  addNewReviewSource() {
    if (this.sourcesCount < this.limitReviewRequest) {
      this.initReviewSourceDataForm("", "");
      this.cdf.detectChanges();
    }
  }

  initReviewSourceDataForm(code: string, url: string) {
    const reviewSourceDataForm = new UntypedFormGroup({
      code: new UntypedFormControl("", Validators.required),
      url: new UntypedFormControl("", this.validators)
    });

    reviewSourceDataForm.controls.code.setValue(code);
    reviewSourceDataForm.controls.url.setValue(url);
    this.reviewSources.push(reviewSourceDataForm);
  }

  deleteReviewSource(index: number) {
    this.reviewSources.removeAt(index);
    this.cdf.detectChanges();
  }

  selectedReviewSourceItem(code: string, index: number) {
    let dataControl = this.getFormByIndex(index).get("code");
    dataControl.setValue(code);
    this.reviewSources.value[index].code = code;
    this.cdf.detectChanges();
  }

  getFormByIndex(index: number): UntypedFormGroup {
    return this.reviewSources.at(index) as UntypedFormGroup;
  }

  getNameByCode(code: string) {
    if (!code) return "";
    const link = this.allReviewSourceList.find((o) => o.code === code);
    return link?.name || "";
  }

  checkDuplicatesByCode(array: ReviewSourceData[]) {
    return array.some((f, i) => array.some((s, j) => f.code === s.code && i !== j));
  }

  changeSearch(value) {
    const search = (this.search = value.toLowerCase().toString());
    if (search) {
      this.notOtherReviewSourceList = this.allReviewSourceList.filter(
        (o) => o.industryId === this.brand.industryId && ((o.name && o.name.toLowerCase().indexOf(search) !== -1) || !search)
      );
      this.otherReviewSourceList = this.allReviewSourceList.filter(
        (o) => o.industryId !== this.brand.industryId && ((o.name && o.name.toLowerCase().indexOf(search) !== -1) || !search)
      );
    } else {
      this.loadDefaultList();
    }
  }
  showList() {
    this.loadDefaultList();
  }

  loadDefaultList() {
    this.search = "";
    this.notOtherReviewSourceList = this.allReviewSourceList.filter((o) => o.industryId === this.brand.industryId);

    this.otherReviewSourceList = this.allReviewSourceList.filter((o) => o.industryId !== this.brand.industryId);
  }

  public atLeastOneLinkRequiredValidator(): ValidatorFn {
    return (group: UntypedFormGroup): ValidationErrors => {
      const isDefaultLinksValid =
        (this.form.controls.reviewGoogle.valid && this.form.controls.reviewGoogle.value) ||
        (this.form.controls.reviewYelp.valid && this.form.controls.reviewYelp.value);

      const sources: ReviewSourceData[] = this.reviewSources.value;

      const isCustomSourcesValid = sources.length && sources.some((r) => r.code && r.url);
      const noLinkConfigured = !isDefaultLinksValid && !isCustomSourcesValid;
      if (noLinkConfigured) {
        return { isEmpty: true };
      }

      return null;
    };
  }
  public limitLinksExceededValidator(): ValidatorFn {
    return (group: UntypedFormGroup): ValidationErrors => {
      const isValid = this.form.controls.reviewGoogle.valid && this.form.controls.reviewYelp.valid && this.reviewSources.valid;

      const sources: ReviewSourceData[] = this.reviewSources.value;

      const hasNoEmptyLink = sources.every((r) => r.code && r.url);
      if (isValid && hasNoEmptyLink && this.limitReviewRequest && this.sourcesCount > this.limitReviewRequest) {
        return { isExceeded: true };
      }

      return null;
    };
  }

  public isDuplicatedLinkRequiredValidator(): ValidatorFn {
    return (group: UntypedFormGroup): ValidationErrors => {
      const isDuplicate = this.reviewSources && this.checkDuplicatesByCode(this.reviewSources.value);
      if (isDuplicate) {
        return { isDublicate: true };
      }

      return null;
    };
  }
}
