import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TranslocoService } from "@ngneat/transloco";
import { QrCodeReaderModalComponent } from "../qr-code-reader-modal/qr-code-reader-modal.component";

@Component({
  selector: "app-qr-code-reader-button",
  templateUrl: "./qr-code-reader-button.component.html",
  styleUrls: ["./qr-code-reader-button.component.scss"]
})
export class QrCodeReaderButtonComponent implements OnInit {
  @Input() buttonText = "";
  @Input() buttonIcon = "fa fa-qrcode";
  @Input() useCustomScanner = false;
  @Output() scanResult = new EventEmitter<string>();
  @Output() scanModalOpened = new EventEmitter<NgbModalRef>();

  title = "";
  disabled = false;

  constructor(private modalSvc: NgbModal, private translocoService: TranslocoService) {}

  ngOnInit(): void {
    this.title = this.buttonText || this.translocoService.translateObject("qrcode.read");
  }

  openModal() {
    if (this.useCustomScanner)
    {
        // This warning needs to avoid confutions when the [Scan] button doesn't respond when clicked
        console.info("The system is configured to use a custom scanner");
        return;
    }

    this.disabled = true;
    const qrModal: NgbModalRef = this.modalSvc.open(QrCodeReaderModalComponent, {
      backdrop: "static",
      size: "l",
      centered: true,
      windowClass: "custom"
    });
    const comp: QrCodeReaderModalComponent = qrModal.componentInstance;

    this.scanModalOpened.emit(qrModal);
    comp.startScanning();
    qrModal.result.then(
      (result: string) => {
        this.scanResult.emit(result);
        this.disabled = false;
      },
      (error) => {
        this.scanResult.emit("");
        this.disabled = false;
      }
    );
  }
}
