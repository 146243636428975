import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MediaContentDto, MediaContentType } from "../dto";
import { ApiService } from "../core";

@Injectable({
  providedIn: "root"
})
export class MediaContentService {
  private serviceUrl = "mediaContent";

  constructor(private api: ApiService) {}

  public getMediaContent(id: number): Observable<MediaContentDto> {
    return this.api.get(`${this.serviceUrl}/${id}`);
  }

  public getByType(type: MediaContentType): Observable<MediaContentDto[]> {
    return this.api.get(`${this.serviceUrl}/type/${type}`);
  }
}
