import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output
} from "@angular/core";
import { AnniversaryClubWidgetSettings } from "@app/pages/components/widget-settings/anniversary-club-widget-settings/anniversary-club-widget-settings";
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from "@angular/forms";
import { Patterns } from "@app/shared/validators";
import { CouponService, WidgetService } from "../../../../services";
import { Brand, CouponInfo, Location, WidgetType } from "../../../../models";
import { WidgetSettingsPreviewMessageComponent } from "../WidgetSettingsPreviewMessageComponent";
import { AuthService } from "@app/core";

enum SettingsControl {
  SendingTime = "sendingTime",
  AnniversaryClubUrl = "anniversaryClubUrl",
  SelectedCouponId = "selectedCouponId",
  CampaignId = "campaignId"
}

@Component({
  selector: "app-anniversary-club-widget-settings",
  templateUrl: "./anniversary-club-widget-settings.component.html",
  styleUrls: ["./anniversary-club-widget-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: AnniversaryClubWidgetSettingsComponent
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AnniversaryClubWidgetSettingsComponent),
      multi: true
    }
  ]
})
export class AnniversaryClubWidgetSettingsComponent extends WidgetSettingsPreviewMessageComponent implements OnInit, OnDestroy {
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    public couponService: CouponService,
    public widgetService: WidgetService,
    public authService: AuthService
  ) {
    super(couponService, widgetService, authService);
  }

  @Output() widgetSettingsReady = new EventEmitter();

  @Input() brand: Brand;
  _location: Location;
  @Input() set location(value: Location) {
    if (value) this._location = value;
  }

  get location(): Location {
    return this._location;
  }

  @Input() set widgetSettingsJson(value: string) {
    if (value) {
      this.widgetSettings = JSON.parse(value) as AnniversaryClubWidgetSettings;
    } else {
      this.setCouponsAndPreviewMessage({
        widgetType: WidgetType.AnniversaryClub,
        brandName: this.brand?.name,
        locationId: this.location?.id
      });
    }
  }

  private _widget: AnniversaryClubWidgetSettings;
  public validators: Array<ValidatorFn> = [Validators.pattern(Patterns.url), Validators.maxLength(2000)];

  settingsControl = SettingsControl;

  public set widgetSettings(value: AnniversaryClubWidgetSettings) {
    if (value && !this._widget) {
      this._widget = value;

      setTimeout(() => {
        this.form.controls[SettingsControl.SendingTime].setValue(value.sendingTime);
        this.form.controls[SettingsControl.AnniversaryClubUrl].setValue(value.anniversaryClubUrl);
        this.form.controls[SettingsControl.CampaignId].setValue(value.campaignId);

        this.setCouponsAndPreviewMessage({
          widgetType: WidgetType.AnniversaryClub,
          brandName: this.brand?.name,
          selectedCouponId: value.selectedCouponId,
          locationId: this.location?.id
        });
      });
    }

    this.onChange(value);
    this.changeDetectorRef.markForCheck();
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup(
      {
        [SettingsControl.SendingTime]: new UntypedFormControl(""),
        [SettingsControl.AnniversaryClubUrl]: new UntypedFormControl("", this.validators),
        [SettingsControl.SelectedCouponId]: new UntypedFormControl(""),
        [SettingsControl.CampaignId]: new UntypedFormControl("")
      },
      [this.formValidator()]
    );

    this.widgetSettingsReady.emit();
  }

  onCouponListChanged(coupon: CouponInfo): void {
    this.couponListChanged({
      widgetType: WidgetType.AnniversaryClub,
      brandName: this.brand?.name,
      selectedCouponId: coupon?.id
    });
  }

  private formValidator(): ValidatorFn {
    const elseControlNameList: string[] = [SettingsControl.SendingTime, SettingsControl.SelectedCouponId];

    return this.getFormValidator(SettingsControl.AnniversaryClubUrl, elseControlNameList);
  }

  // destroy:
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
