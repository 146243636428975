import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from "@angular/core";
import { TemplatePreviewSelectableOptions } from "@app/models";
import { SimplebarAngularComponent } from "simplebar-angular";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { Unsubscriber } from "../base/unsubscriber.component";
import { DigitalAssetScreenSize } from "clearline-api";

@Component({
  selector: "app-multi-column-carousel",
  templateUrl: "./multi-column-carousel.component.html",
  styleUrls: ["./multi-column-carousel.component.scss"]
})
export class MultiColumnCarouselComponent extends Unsubscriber implements OnInit {
  constructor() {
    super();
  }

  readonly screenSizeEnum = DigitalAssetScreenSize;
  readonly itemsThresholdForRows = 5;
  readonly screenResizeWatcherDelay = 500;
  readonly scrollbarOptions: any = {
    forceVisible: false,
    autoHide: false
  };
  dataList: TemplatePreviewSelectableOptions[];
  isVisible = true;
  private resizeSubject = new Subject<Event>();
  private windowWidth: number;
  private lastCheckedWindowWidth: number;

  @ViewChild(SimplebarAngularComponent, { static: false }) simpleBar?: SimplebarAngularComponent;

  @Input() autoWidth = false;
  @Input() set items(items: TemplatePreviewSelectableOptions[]) {
    this.dataList = items;

    if (this.getIsCarouselCanBeReloaded()) {
      this.reloadCarousel();
    }
  }
  @Input() selectedItemId: number;
  @Input() loading = false;
  @Input() removeSelected = false;
  @Input() rows: number;
  @Output() selectItem = new EventEmitter<number>();

  @HostListener("window:resize", ["$event"]) onResize(event: Event): void {
    this.windowWidth = window.innerWidth;

    this.resizeSubject.next(event);
  }
  ngOnInit(): void {
    this.sub = this.resizeSubject.pipe(debounceTime(this.screenResizeWatcherDelay)).subscribe(() => {
      if (this.getIsCarouselCanBeReloaded()) {
        const isScreenSizeIncreasing: boolean = this.windowWidth > this.lastCheckedWindowWidth;

        if (isScreenSizeIncreasing) {
          // reload carousel only needed on increasing screen size
          this.reloadCarousel();
        }

        this.lastCheckedWindowWidth = this.windowWidth;
      }
    });
  }

  clickSelectItem(item: TemplatePreviewSelectableOptions): void {
    if (!this.selectedItemId || this.selectedItemId !== item.id) {
      this.selectedItemId = item.id;

      this.selectItem.emit(item.id);
    } else {
      this.removeSelectedItem(item);
    }
  }

  private reloadCarousel(): void {
    this.isVisible = !this.simpleBar?.SimpleBar; // reload SimpleBar if it already exists

    setTimeout(() => {
      this.isVisible = true;
    }, 0);
  }

  private getIsCarouselCanBeReloaded(): boolean {
    return this.autoWidth && (!this.rows || this.dataList?.length <= this.itemsThresholdForRows);
  }

  private removeSelectedItem(item: TemplatePreviewSelectableOptions) {
    if (this.removeSelected && this.selectedItemId) {
      if (this.selectedItemId === item.id) this.selectItem.emit(null);
      this.selectedItemId = 0;
    }
  }
}
