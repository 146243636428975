import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DigitalAssetGetDto, DigitalAssetType } from "../dto";
import { ApiService } from "../core";

@Injectable({
  providedIn: "root"
})
export class DigitalAssetService {
  private serviceUrl = "asset";

  constructor(private api: ApiService) {}

  getAll(): Observable<DigitalAssetGetDto[]> {
    return this.api.get(`${this.serviceUrl}`);
  }

  getAsset(id: number): Observable<DigitalAssetGetDto> {
    return this.api.get(`${this.serviceUrl}/${id}`);
  }

  getByType(assetType: DigitalAssetType): Observable<DigitalAssetGetDto[]> {
    return this.api.get(`${this.serviceUrl}/type/${assetType}`);
  }
}
