export interface ClientProfile {
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  birthDate?: string;
  createdBy: number;
  createdOn: string;
  accountId?: number;
  locationId?: number;
}

export interface ClientProfileGetDto extends ClientProfile {
  id: string;
  locationId: number;
}
export interface ClientProfileSearchDto {
  accountId?: number;
  locationId?: number;
  email: string;
  phone: string;
}

export interface ClientProfilePutDto extends ClientProfile {}

export interface ClientProfileStatisticsGetDto {
  totalAmount: number;
  lastTransactionDate: string;
  averageTransactionAmount: number;
  totalTransactions: number; // integer
}
