import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { AccountService, AppConfigService } from "@app/core";
import { FileUploaderService } from "@app/services";
import { TranslocoService } from "@ngneat/transloco";
import { DropzoneComponent, DropzoneConfigInterface } from "ngx-dropzone-wrapper";
import { FileResult } from "@app/shared/file-uploader";
import { FileMimeType, FileType, maxFileUploadSize } from "../file-uploader-modal/file-type";
import { catchError, mergeMap, take } from "rxjs/operators";
import { Observable, Observer, of } from "rxjs";

@Component({
  selector: "app-file-uploader",
  templateUrl: "./file-uploader.component.html",
  styleUrls: ["./file-uploader.component.scss"]
})
export class FileUploaderComponent implements OnInit {
  @ViewChild(DropzoneComponent, { static: false }) componentRef?: DropzoneComponent;
  @ViewChild("videoPlayer") videoPlayer: ElementRef<HTMLVideoElement>;

  @Input() fileType: FileType = FileType.Image;
  @Input() isMultiple = false;
  @Input() storageFolder = "files";
  @Input() extensions: Array<FileMimeType>;
  @Input() maxFileUploadSize: number = maxFileUploadSize;

  @Output() uploadChanged = new EventEmitter<FileResult>();
  @Output() uploadingInProgress = new EventEmitter<boolean>();

  readonly FileType = FileType;
  private readonly previewImageName = "preview";
  private readonly previewImageExtension = "png";
  private readonly previewImageMimeType = FileMimeType.png;

  error = "";
  result: FileResult;
  config: DropzoneConfigInterface = {
    maxFiles: 1,
    autoReset: null,
    cancelReset: null,
    errorReset: 1,
    maxFilesize: maxFileUploadSize,
    headers: {
      Authorization: `Bearer ${this.accountService.accessToken}`
    }
  };

  constructor(
    private confService: AppConfigService,
    public translateSvc: TranslocoService,
    private accountService: AccountService,
    private fileUploaderService: FileUploaderService
  ) {}

  ngOnInit(): void {
    this.config.acceptedFiles = this.fileUploaderService.getAcceptedFiles(this.extensions);
    this.config.url = `${this.confService.appData.apiUrl}/fileUploader/${this.storageFolder}`;
    this.config.maxFilesize = this.maxFileUploadSize;
  }

  resetDropzoneUploads(): void {
    if (this.componentRef && this.componentRef.directiveRef) {
      this.componentRef.directiveRef.reset();
      this.result = null;
      this.uploadChanged.next(this.result);
    }
  }

  onUploadError(error) {
    this.error = "";
    for (let i = 1; i < error.length - 1; i++) {
      if (error[i] instanceof Object) {
        error[i] = error[i].Message;
        this.error += error[i] + "\n";
      } else this.error += error[i] + "\n";
    }
    this.result = null;
    this.uploadChanged.next(this.result);
  }

  onUploadSuccess(result: any[]): void {
    if (result.length < 2) {
      console.log("result should have minimum 2 items in the array");
      return;
    }

    this.result = { fileDataUrl: result[0].dataURL, fileUrl: result[1].link } as FileResult;
    const image = result[0];
    const reader = new FileReader();

    reader.onloadend = (r) => {
      if (reader.result) {
        this.result = { fileDataUrl: reader.result, fileUrl: result[1].link } as FileResult;

        if (this.fileType === FileType.Video) {
          this.uploadingInProgress.emit(true);
        }
      } else {
        this.result = null;
      }

      this.uploadChanged.next(this.result);
    };

    reader.readAsDataURL(image);
  }

  onSending() {
    this.error = "";
  }

  videoOnLoad(): void {
    this.getVideoPreviewImageFile()
      .pipe(
        mergeMap((file: File) => this.fileUploaderService.saveFile(file, this.storageFolder)),
        catchError(() => of(null)),
        take(1)
      )
      .subscribe((url) => {
        if (url && this.componentRef && this.componentRef.directiveRef) {
          const dropzoneElement = this.componentRef.directiveRef.dropzone().element;
          const dropzoneImage = dropzoneElement.querySelector(".dz-image img");

          if (dropzoneImage) {
            dropzoneImage.setAttribute("src", url);
          }
        }

        if (url && this.result) {
          this.result.thumbnailUrl = url;
          this.result.duration = this.getVideoDuration();

          this.uploadChanged.next(this.result);
        }

        this.uploadingInProgress.emit(false);
      });
  }

  videoOnError(): void {
    this.uploadingInProgress.emit(false);
  }

  private getVideoPreviewImageFile(): Observable<File> {
    return new Observable((observer: Observer<File>) => {
      const video: HTMLVideoElement = this.videoPlayer?.nativeElement;

      if (video) {
        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const context = canvas.getContext("2d");
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        try {
          canvas.toBlob((blob) => {
            if (blob) {
              const file = new File([blob], `${this.previewImageName}.${this.previewImageExtension}`, { type: this.previewImageMimeType });
              observer.next(file);
              observer.complete();
            } else {
              observer.error(new Error("Canvas to Blob conversion failed"));
            }
          }, "image/png");
        } catch (error) {
          observer.error(error);
        }
      } else {
        observer.error(new Error("Video element not found"));
      }
    });
  }

  private getVideoDuration(): number {
    const video: HTMLVideoElement = this.videoPlayer?.nativeElement;

    return video?.duration || 0;
  }
}
