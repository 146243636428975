import { QrCodeFormat, QrCodeImageType } from "./enums";
import { ShortLinkQrConfiguration } from "./qr-code-configuration";

export class QRCodeSettings {
  public url: string;
  public logo: string;
}

export class QrCodeColors {
  public primaryColor: string;
  public secondaryColor: string;
}

export class QrCodePostDto extends QrCodeColors {
  public name?: string;
  public primaryText?: string;
  public secondaryText?: string;
  public primaryTextColor?: string;
  public secondaryTextColor?: string;
  public backgroundColor?: string;
  public imageType?: QrCodeImageType;
  public data: string;
  public width?: number;
  public height?: number;
  public printId?: number;
}

export class QrCodeResultDto {
  public link: string;
}

export class QrCodeWithTemplatePostDto extends ShortLinkQrConfiguration {
  public name: string;
  public templateName: QrCodeTemplateName;
  public data: string;
}

export enum QrCodeTemplateName {
  Normal = "NORMAL",
  Bulk = "BULK",
  AppStylesPhone = "APP_STYLES_PHONE",
  CfsTemplate = "CFS_TEMPLATE",
  PosReceipt = "POS_RECEIPT_TEMPLATE"
}

export class QrCodeImageTypeSetting {
  public id: QrCodeFormat;
  public name: string;
}

export const qrCodeImageTypeSettings: QrCodeImageTypeSetting[] = [
  { id: QrCodeFormat.Png, name: "png" },
  { id: QrCodeFormat.Html, name: "html" },
  { id: QrCodeFormat.Jpg, name: "jpeg" },
  { id: QrCodeFormat.Pdf, name: "pdf" }
];
