import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, Output } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

export type FileUploaderViewSize = "XL" | "L" | "M" | "S";

@Component({
  selector: "app-base-uploader",
  templateUrl: "./base-uploader.component.html",
  styleUrls: ["./base-uploader.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseUploaderComponent),
      multi: true
    }
  ]
})
export class BaseUploaderComponent<T> implements ControlValueAccessor {
  @Input() size: FileUploaderViewSize = "M";
  @Input() label = "";
  @Input() uploadLabel = "";
  @Input() tooltip = "";
  @Input() labelCssClass = "";
  @Input() isDisabled = false;
  @Input() isRequired = false;
  @Input() isClearable = false;
  @Input() value: T;

  @Input() isValueExist = false;

  @Output() clear = new EventEmitter<void>();
  @Output() upload = new EventEmitter<void>();

  private onChange: (value: T) => void = () => {};
  private onTouched: () => void = () => {};

  constructor(protected changeDetectorRef: ChangeDetectorRef) {}

  onClear(event: Event): void {
    event.preventDefault();
    this.clear.emit();
    this.updateValue(null);
  }

  onUpload(): void {
    if (!this.isDisabled) {
      this.upload.emit();
    }
  }

  writeValue(value: T): void {
    this.value = value;
    this.changeDetectorRef.markForCheck();
    this.onChange(value);
  }

  registerOnChange(fn: (value: T) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  updateValue(value: T): void {
    this.value = value;

    this.changeDetectorRef.markForCheck();
    this.onChange(value);
  }
}
