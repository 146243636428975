import { LoyaltyCardUserGetDto } from "clearline-api";

export interface BalanceInfo {
  id: string;
  fullName: string;
  phoneNumber: string;
  email: string;
  uniqueCode: string;
  balanceValue: number;
  balanceCurrency: string;
  cards: RewardCardView[];
}

export interface RewardCard {
  imageUrl: string;
  title: string;
  subTitle: string;
  session: string;
  campaign: string;
  claimed: boolean;
}

export type RewardCardView = RewardCard & { pending?: boolean };

export interface CheckInBalanceRouteState {
  balance: LoyaltyCardUserGetDto;
  cashRegisterId: number;
}

export type CheckInResult = {
  status: "success" | "error";
  cardUser: LoyaltyCardUserGetDto | null;
};
