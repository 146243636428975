import { WidgetSettingsBaseComponent } from "./widget-settings-base";
import { BehaviorSubject, forkJoin, Observable, of, Subject } from "rxjs";
import { CouponInfo, DefaultWidgetCampaign, WidgetType } from "../../../models";
import { catchError, map, mergeMap, takeUntil, tap } from "rxjs/operators";
import { CouponService, WidgetService } from "../../../services";
import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { AuthService } from "@app/core";

export interface WidgetSettingsPreviewMessageInfo {
  widgetType: WidgetType;
  brandName: string;
  selectedCouponId?: string;
  frequencyLabel?: string;
  locationId?: number;
}
export class WidgetSettingsPreviewMessageComponent extends WidgetSettingsBaseComponent {
  constructor(public couponService: CouponService, public widgetService: WidgetService, public authService: AuthService) {
    super(authService);
  }

  private readonly selectedCouponIdControlName = "selectedCouponId";
  private textMessageSubject = new BehaviorSubject<string>("");
  private _textMessageTemplate: string;

  unsubscribe$ = new Subject<void>();
  couponList$: Observable<CouponInfo[] | null>;
  textMessage$: Observable<string> = this.textMessageSubject.asObservable();

  loading = false;
  couponList: CouponInfo[];
  selectedCoupon: CouponInfo;

  // form validator:

  getFormValidator(landingUrlControlName: string, elseControlNameList: string[] = []): ValidatorFn {
    return (group: UntypedFormGroup): ValidationErrors => {
      const landingUrlControl: AbstractControl = group.controls[landingUrlControlName];
      const elseControlsList: AbstractControl[] = elseControlNameList.map((controlName: string) => group.controls[controlName]);

      if (landingUrlControl.valid && landingUrlControl.value) {
        elseControlsList.forEach((control: AbstractControl) => {
          control.setErrors(null, { emitEvent: false });
        });

        return null;
      }

      if (!elseControlsList.every((control: AbstractControl) => control.value)) {
        elseControlsList.forEach((control: AbstractControl) => {
          if (!control.value) {
            control.setErrors({ requiredControl: true }, { emitEvent: false });
          }
        });
      }

      return null;
    };
  }

  couponListChanged({ widgetType, brandName, selectedCouponId = null, frequencyLabel = "" }: WidgetSettingsPreviewMessageInfo): void {
    this.startLoading();
    this.handleOnCouponListChanges(selectedCouponId, true);

    this.getTextMessage$(widgetType, brandName, this.selectedCoupon?.url, frequencyLabel)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        () => this.finishLoading(),
        () => this.finishLoading()
      );
  }

  otherControlChanged({ widgetType, brandName, frequencyLabel = "" }: WidgetSettingsPreviewMessageInfo): void {
    this.startLoading();

    this.getTextMessage$(widgetType, brandName, this.selectedCoupon?.url, frequencyLabel)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        () => this.finishLoading(),
        () => this.finishLoading()
      );
  }

  setCouponsAndPreviewMessage({
    widgetType,
    brandName,
    selectedCouponId = null,
    locationId = 0,
    frequencyLabel = ""
  }: WidgetSettingsPreviewMessageInfo): void {
    this.startLoading();

    this.couponList$ = (
      locationId
        ? this.couponService.getLocationWidgetCoupons(locationId, widgetType)
        : this.couponService.getWidgetDefaultCoupons(widgetType)
    ).pipe(
      mergeMap((couponList: CouponInfo[]) => {
        this.couponList = couponList;

        this.handleOnCouponListChanges(selectedCouponId, true);

        return forkJoin([of(couponList), this.getTextMessage$(widgetType, brandName, this.selectedCoupon?.url, frequencyLabel)]);
      }),
      map(([couponList]: [CouponInfo[], string]) => couponList),
      tap(() => this.finishLoading()),
      catchError(() => {
        this.getTextMessage$(widgetType, brandName, this.selectedCoupon?.url, frequencyLabel)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            () => this.finishLoading(),
            () => this.finishLoading()
          );

        return of([]);
      })
    );
  }

  private handleOnCouponListChanges(selectedCouponId: string = null, patchToForm: boolean = false): void {
    this.selectedCoupon = selectedCouponId ? this.couponList.find((item: CouponInfo) => item.id === selectedCouponId) : null;

    this.handleCouponsOnSelection(selectedCouponId);

    if (patchToForm) {
      this.form.controls[this.selectedCouponIdControlName].setValue(selectedCouponId);
    }
  }

  private handleCouponsOnSelection(selectedCouponId: string): void {
    if (this.couponList?.length) {
      this.couponList.forEach((item: CouponInfo, index: number) => {
        this.couponList[index].isSelected = selectedCouponId && selectedCouponId === item.id;
      });
    }
  }

  // handle message preview:
  private getTextMessage$(widgetType: WidgetType, brandName: string, couponLink: string, frequencyLabel: string): Observable<string> {
    if (!this._textMessageTemplate) {
      return this.widgetService.getDefaultWidgetCampaign(widgetType).pipe(
        map((campaign: DefaultWidgetCampaign) => {
          this._textMessageTemplate = campaign?.textMessage;
          const textMessage = this.getHandledTextMessage(brandName, couponLink, frequencyLabel);

          this.textMessageSubject.next(textMessage);

          return textMessage;
        })
      );
    } else {
      const textMessage = this.getHandledTextMessage(brandName, couponLink, frequencyLabel);

      this.textMessageSubject.next(textMessage);

      return of(textMessage);
    }
  }

  private getHandledTextMessage(
    brandName: string = "{brandname}",
    couponLink: string = "{couponlink}",
    frequencyNameLabel: string = "{frequency-name}"
  ): string {
    let textMessage = "";

    if (this._textMessageTemplate) {
      textMessage = this._textMessageTemplate.replace("{brandname}", brandName).replace(/\{frequency\-name\}/g, frequencyNameLabel);
      textMessage = textMessage.substring(0, 125);
    }

    return textMessage;
  }

  // handle loading:
  private startLoading(): void {
    this.loading = true;
  }

  private finishLoading(): void {
    this.loading = false;
  }
}
