import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output } from "@angular/core";
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from "@angular/forms";
import { Patterns } from "@app/shared/validators";
import { WidgetSettingsBaseComponent } from "../widget-settings-base";
import { ReferralProgramWidgetSettings } from "@app/pages/components/widget-settings/referral-program-widget-settings/referral-program-widget-settings";
import { AuthService } from "@app/core";

@Component({
  selector: "app-referral-program-widget-settings",
  templateUrl: "./referral-program-widget-settings.component.html",
  styleUrls: ["./referral-program-widget-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ReferralProgramWidgetSettingsComponent
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReferralProgramWidgetSettingsComponent),
      multi: true
    }
  ]
})
export class ReferralProgramWidgetSettingsComponent extends WidgetSettingsBaseComponent implements OnInit {
  private _widget: ReferralProgramWidgetSettings;
  public validators: Array<ValidatorFn> = [Validators.pattern(Patterns.url), Validators.maxLength(2000)];
  @Output() widgetSettingsReady = new EventEmitter();
  @Input() set widgetSettingsJson(value: string) {
    if (value) this.widgetSetting = JSON.parse(value) as ReferralProgramWidgetSettings;
  }

  public set widgetSetting(value: ReferralProgramWidgetSettings) {
    if (value && !this._widget) {
      setTimeout(() => {
        this.form.controls.referralUrl.setValue(value.referralUrl);
      });
    }
    this.onChange(value);
    this.changeDetectorRef.markForCheck();
  }

  constructor(private changeDetectorRef: ChangeDetectorRef, public authService: AuthService) {
    super(authService);
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      referralUrl: new UntypedFormControl("", this.validators)
    });
    this.widgetSettingsReady.emit();
  }
}
