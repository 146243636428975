export interface PagesLoyaltyProgramCheckInPostDto {
  locationId: number;
  cashRegisterId: number;
  searchText: string;
}
export interface PagesLoyaltyProgramCheckInGetDto {
  cardUser: LoyaltyCardUserGetDto;
  loyaltyConfiguration: LoyaltyConfigurationDto;
  description: string;
}

export interface LoyaltyConfigurationDto {
  membership: LoyaltyMembershipConfigurationDto;
}
export interface LoyaltyMembershipConfigurationDto {
  cardType: LoyaltyMembershipCardType;
  tierType: LoyaltyMembershipTierType | null;
  discount: number | null;
  cardImageUrl: string;
}

export interface LoyaltyCardUserGetDtoApiResponse {
  data: LoyaltyCardUserGetDto;
}

export interface LoyaltyCardUserGetDto {
  id?: string;
  uniqueCode?: string;
  firstName?: string;
  profilePicture?: string;
  gender?: string;
  phone?: string;
  email?: string;
  stamps?: number;
  points?: number;
  lastName?: string;
  activeRewards?: LoyaltyRewardGetDto[];
}

export interface LoyaltyRewardGetDto {
  campaign?: string;
  session?: string;
  singleUseUrl?: string;
  expirationDate?: string;
  value?: string;
  opened: boolean;
  createdDateUtc?: string;
  claimed: boolean;
  claimDateUtc?: string;
  couponInfo?: CouponInfoGetDto;
}

export interface CouponInfoGetDto {
  id: string;
  code: string;
  url: string;
  couponImage: string;
  status: string; // "example" | "active" | "archived" | "template" | "deleted";
  name: string;
  title: string;
  subtitle: string;
  description: string;
  couponCategoryId: string;
  couponCategoryName: string;
  banner1: string;
  banner2: string;
  banner3: string;
  banner4: string;
  banner5: string;
  subaccount: string;
  logoUrl: string;
  couponTags: string;
  expiryDate: string;
  promotion: {
    value: number;
    promotionType: number;
  };
  isSelected: true;
}

export interface ShortLinkQrCodeDtoApiResponse {
  data: ShortLinkQrCodeDto;
}

export interface ShortLinkQrCodeDto {
  qrCodeLinkUrl: string;
  shortUrl: string;
}

export interface PagesLoyaltyProgramApplyRewardPostDto {
  cashRegisterId: number;
  rewardSessionId: string;
  rewardCampaignId: string;
  loyaltyCardNumber: string;
  loyaltyUserId: string;
}

export enum LoyaltyMembershipCardType {
  General = "General",
  Tiered = "Tiered",
  Discount = "Discount"
}
export enum LoyaltyMembershipTierType {
  Metal = "Metal",
  Diamond = "Diamond"
}
