export interface AccountUrlsConfigurationTagDto {
  title?: string;
  urls?: string;
  useIn?: string;
}

export interface UrlsConfigurationDto {
  secretGestureDestinationUrl?: string;
  tags?: AccountUrlsConfigurationTagDto;
}

export interface ActivationTransactions {
  reportName: string;
  isReportEnabled: boolean;
}

export interface AccountConfigurationReport {
  activationTransactions?: ActivationTransactions;
}
export interface AccountConfigurationSecurity {
  isPinCodeEnabled: boolean;
}

export interface AccountConfigurationDigitalScreensDto {
  isEnabled: boolean;
}

export interface AccountConfigurationSmsApiDto {
  isEnabled: boolean;
}

export interface AccountConfigurationLoyaltyDto {
  ambassadorFeatureAvailable: boolean;
  ambassadorEnabled: boolean;
}

export interface AccountConfigurationDto {
  // templatesConfiguration: AccountTemplateConfiguration; // todo: migrate to clearline-api
  templatesConfiguration: any; // fixme
  urlsConfiguration: UrlsConfigurationDto;
  reporting?: AccountConfigurationReport;
  security: AccountConfigurationSecurity;
  digitalScreens?: AccountConfigurationDigitalScreensDto;
  // externalConnection: AccountConfigurationExternalConnectionDto; // todo: migrate to clearline-api
  externalConnection: any; // fixme
  smsApi?: AccountConfigurationSmsApiDto;
  loyalty: AccountConfigurationLoyaltyDto;
}
