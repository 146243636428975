import { Injectable } from "@angular/core";
import { DataService } from "@app/core";
import { QrCodeFormat, QrCodeResultDto, QrCodeWithTemplatePostDto } from "@app/models";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class QrCodeGeneratorService {
  private serviceUrl = "qrcode";

  constructor(private http: DataService) {}

  generateQrCodeUrlWithHtmlContent(format: QrCodeFormat, qrCodeBulkDto: QrCodeWithTemplatePostDto): Observable<QrCodeResultDto> {
    return this.http.post(`${this.serviceUrl}/${format}`, qrCodeBulkDto);
  }

  generateBulkQr(format: QrCodeFormat, qrCodeBulkDto: QrCodeWithTemplatePostDto[]): Observable<QrCodeResultDto> {
    return this.http.post(`${this.serviceUrl}/bulk/${format}`, qrCodeBulkDto);
  }

  generatePosReceiptQrCode(): Observable<QrCodeResultDto> {
    return this.http.get(`${this.serviceUrl}/pos-receipt-preview`);
  }
}
