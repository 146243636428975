import { QuickSetupPredefinedSettingsGetDto } from "@app/models/quick-setup";
import { SubscriptionPlanGetDto } from "@app/models/subscription-plan";

export class QuickSetupConfiguration {
  subscriptionPlan: SubscriptionPlanGetDto;
  chargebeeSubscriptionId?: string;
  chargebeeAccount: string;
  predefinedSettings: QuickSetupPredefinedSettingsGetDto;
  userAddons?: any;
  userWidgets?: number[];

  isOnboardingUrlSent: boolean;
}
