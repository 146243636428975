<form [formGroup]="form" *transloco="let t; read: 'widgets.setReminder'">
  <h5>
    {{ t("title") }}
  </h5>

  <div class="widget-explanation mt-3">
    <span class="widget-explanation__text">
      {{ t("explanation") }}
    </span>
  </div>

  <div class="row mt-3 mb-3">
    <div class="row">
      <div class="col-sm-12 text-center">
        <label class="col-form-label">{{ t("frequency") }}</label>
      </div>

      <div class="col-sm-6" *ngFor="let item of frequencyNames">
        <div class="form-check mb-3">
          <input
            class="form-check-input"
            type="radio"
            [value]="item"
            id="formRadios+{{ reminderFrequencyNameRepresentation[item] }}"
            [formControlName]="settingsControl.FrequencyType"
            (blur)="onTouched()"
            (change)="onFrequencyNameChanged($event)"
          />

          <label class="form-check-label" for="formRadios+{{ reminderFrequencyNameRepresentation[item] }}">
            {{ reminderFrequencyNameRepresentation[item] }}
          </label>
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-md-4 col-6">
        <label for="example-time-input" class="col-form-label">
          {{ t("sendingTime") }}
        </label>

        <input
          [placeholder]="t('sendingTime')"
          class="form-control"
          [formControlName]="settingsControl.SendingTime"
          type="time"
          value="13:45:00"
          id="example-time-input"
        />
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-sm-12 text-center">
        <label class="col-form-label">{{ t("expiration") }}</label>
      </div>

      <div class="col-sm-6" *ngFor="let item of reminderExpirations">
        <div class="form-check mb-3">
          <input
            class="form-check-input"
            type="radio"
            [value]="item"
            id="formRadios+{{ reminderExpirationRepresentation[item] }}"
            [formControlName]="settingsControl.SetReminderExpiration"
            (blur)="onTouched()"
          />

          <label class="form-check-label" for="formRadios+{{ reminderExpirationRepresentation[item] }}">
            {{ reminderExpirationRepresentation[item] }}
          </label>
        </div>
      </div>
    </div>

    <div class="col-md-12 mb-3">
      <app-url-form-control
        label="Set Reminder Link"
        [formControlName]="settingsControl.SetReminderUrl"
        [mark]="false"
        [validators]="validators"
      >
      </app-url-form-control>
    </div>

    <div class="col-md-12 mt-2 mb-3">
      <div class="coupon-carousel-wrapper mt-2">
        <app-coupon-carousel
          class="coupon-carousel"
          [loading]="loading"
          [centerSpinner]="true"
          [selectedCoupon]="selectedCoupon"
          [coupons]="couponList$ | async"
          [withPreview]="false"
          [selectionType]="'single'"
          [viewType]="'small'"
          [couponSizeType]="'small'"
          [withNothingFoundText]="true"
          [removeSelected]="true"
          [couponConfig]="{ hasEdit: true, isEditDisabled: isImpersonated }"
          (selectCoupon)="onCouponListChanged($event)"
        >
        </app-coupon-carousel>
      </div>
    </div>

    <div *ngIf="textMessage$ | async as textMessage" class="col-12 mt-2">
      <span class="col-form-label">
        {{ "widgets.common.previewMessageTitle" | transloco }}
      </span>

      <div class="mt-2">
        <span class="preview-message">
          {{ textMessage }}
        </span>
      </div>
    </div>
  </div>
</form>
