import { PageModel } from "@app/core";
import { PartnerCustomDomainConfigurationDto, PartnerDomainConfigurationDto } from "@app/models/domain";
import { LocationShortGetDto } from "./location";

export enum PartnerGriduserActionEnum {
  update = "update",
  navigateToAccounts = "navigateToAccounts",
  navigateToBrands = "navigateToBrands",
  navigateToPartnerAdmins = "navigateToPartnerAdmins",
  delete = "delete"
}

export interface PartnerGriduserAction {
  partner: Partner;
  action: PartnerGriduserActionEnum;
}

export interface Partner {
  id: number;
  companyName: string;
  email: string;
  phone: string;
  industryId: number;
  website?: string;
  country: number;
  state: string;
  city: string;
  address: string;
  zip: string;
  customDomainId?: number;
  applicationStylesId: number;
  customDomain: PartnerCustomDomainDto;
  configuration: PartnerConfigurationDto;
  code?: string;
}

export interface PartnerCustomDomainDto {
  domainConfig: PartnerCustomDomainConfigurationDto;
  configured?: boolean;
}

export interface PartnerConfigurationDto {
  domain: PartnerDomainConfigurationDto;
  features: PartnerFeatureConfigurationDto;
  activationCodes: PartnerActivationCodesConfigurationDto;
}
export interface PartnerFeatureConfigurationDto {
  canCreatePublicBrand: boolean;
}

export interface PartnerActivationCodesConfigurationDto {
  limit: number;
}

export interface PartnerGetDto extends Partner {
  id: number;
}

export interface PartnerPostDto extends Partner {}

export interface PartnerGetDtoApiListResponse {
  data: PartnerGetDto[];
  page: PageModel;
}

export interface AccountLocationsShortGetDto {
  accountId: number;
  locations: LocationShortGetDto[];
  name: string; // Account company name
}
