import { CustomerDistributionStatus, DeliveryChannels } from "../enums";
import { DeliveryProviderGetDto } from "./sender-options";

export interface CustomerDistributionDetailsGetDto extends CustomerDistributionGetDto {
  createdByUser: string;
  sentByUser: string;
  modifiedByUser: string;
  deliveryProvider: DeliveryProviderGetDto;
  senderNumber: string;
}

export interface CustomerDistributionGetDto {
  id: number;
  name: string;
  message: string;
  deliveryChannel: DeliveryChannels;
  status: CustomerDistributionStatus;
  imageUrl: string;
  accountId: number;
  accounts: CustomerDistributionAccountGetDto[];
  deliveryProviderId: number;
  partnerId: number;
  createdBy: string;
  createdOn: string;
  sentBy: string;
  sentOn: string;
  modifiedBy: string;
  modifiedOn: string;
  configuration: CustomerDistributionConfigurationDto;
  useForAllAccounts: boolean;
}

export interface CustomerDistributionConfigurationDto {
  locationsCount: number;
  failedCount: number;
  accountsCount: number;
  totalRecipientsCount: number;
  sentCount: number;
}

export interface CustomerDistributionPutDto {
  name?: string;
  message?: string;
  deliveryChannel?: DeliveryChannels;
  imageUrl: string;
}

export interface CustomerDistributionPostDto {
  name: string;
  message: string;
  deliveryChannel: DeliveryChannels;
  status: CustomerDistributionStatus;
  imageUrl: string;
  useForAllAccounts: boolean;
  accounts: CustomerDistributionAccountsPostDto;
}

export interface DistributionAccountResultDto {
  account: any; // fixme should be AccountGetDto type
  useForAllLocations: boolean; // Sent to all locations recipients
  locationIds: number[]; // List of Account location ids
  clientProfilesCount: number; // Number of recipients
}

export interface CustomerDistributionAccountPostDto {
  accountId: number;
  locationIds: number[];
  useForAllLocations: boolean;
}

export interface CustomerDistributionAccountsPostDto {
  distributionAccounts: CustomerDistributionAccountPostDto[];
}

export interface CustomerDistributionAccountGetDto {
  id: number;
  accountId: number;
  useForAllLocations: boolean;
  customerDistributionId: number;
}

export interface CustomerDistributionAccountsPostDto {
  distributionAccounts: CustomerDistributionAccountPostDto[];
}

export interface CustomerDistributionSentMessageResponseDto {
  messagesId: string;
}

export interface CustomerDistributionSentResultDto {
  accountName: string;
  customerName: string;
  deliveryChannel: string;
  errorMessage: string;
  imageUrl: string;
  locationName: string;
  messageId: string;
  phone: string;
  senderNumber: string;
  sentDate: string; // date
  status: string;
  textMessage: string;
}
