import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../core";
import { PagesLoyaltyProgramApplyRewardPostDto, PagesLoyaltyProgramCheckInGetDto, PagesLoyaltyProgramCheckInPostDto } from "../dto";

@Injectable({
  providedIn: "root"
})
export class PagesLoyaltyProgramService {
  private serviceUrl = "pages/LoyaltyProgram";

  constructor(private api: ApiService) {}

  applyReward(payload: PagesLoyaltyProgramApplyRewardPostDto): Observable<void> {
    return this.api.post(`${this.serviceUrl}/apply-reward`, payload);
  }

  checkIn(data: PagesLoyaltyProgramCheckInPostDto): Observable<PagesLoyaltyProgramCheckInGetDto> {
    return this.api.post(`${this.serviceUrl}/check-in`, data);
  }
}
