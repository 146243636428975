import { Directive, HostListener, Input, OnDestroy, OnInit } from "@angular/core";

interface SizeInfo {
  value: number;
  unit: string;
}

interface ResizeData {
  heightInfo: SizeInfo;
  widthInfo: SizeInfo;
}

@Directive({
  selector: "[libIframeResize]",
  standalone: true
})
export class IframeResizeDirective implements OnInit, OnDestroy {
  @Input() originUrl = "";

  ngOnInit(): void {
    window.addEventListener("message", this.onMessage.bind(this), false);
  }

  @HostListener("window:unload", ["$event"])
  ngOnDestroy(): void {
    window.removeEventListener("message", this.onMessage.bind(this), false);
  }

  onMessage(event: MessageEvent): void {
    if (event.origin !== this.originUrl || !event.data || typeof event.data !== "object" || event.data.type !== "resize") {
      return;
    }

    const iframe = document.querySelector("iframe");
    const data: ResizeData = event.data;

    if (iframe) {
      const { heightInfo, widthInfo } = data;

      if (heightInfo) {
        const { value, unit } = heightInfo;
        iframe.style.height = `${value}${unit}`;
      }

      if (widthInfo) {
        const { value, unit } = widthInfo;
        iframe.style.width = `${value}${unit}`;
      }
    }
  }
}
