import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AccountService, AppConfigService, Permission, UserPermissionService } from "@app/core";
import { RoleTypes } from "core-kit";

@Component({
  selector: "app-access-denied",
  templateUrl: "./access-denied.component.html",
  styleUrls: ["./access-denied.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccessDeniedComponent implements OnInit {
  isManager = false;
  isLocationManager = false;
  hasDashboardAccess = false;
  hasCmcAccess = false;

  constructor(
    private router: Router,
    private accountSvc: AccountService,
    private configSvc: AppConfigService,
    private userPermissionService: UserPermissionService
  ) {}

  ngOnInit(): void {
    this.isManager = this.accountSvc.isUserInRole(RoleTypes.Manager);
    this.isLocationManager = this.accountSvc.isUserInRole(RoleTypes.LocationManager);
    this.hasDashboardAccess = this.userPermissionService.hasActionPermission(Permission.Dashboard) && this.isManager;
    this.hasCmcAccess = this.userPermissionService.hasActionPermission(Permission.MarketingCenter) && !this.isManager;
  }

  goToDashboard() {
    this.router.navigate(["/"]);
  }

  goToCmc() {
    const cmcUrl = this.configSvc.appData.marketingCenterUrl;
    location.href = cmcUrl;
  }
}
