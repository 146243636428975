export enum IframeMessageTypes {
  formSubmited = "formSubmited", // fixme typo
  userActivity = "userActivity",
  setWindowClass = "setWindowClass",
  scanCompleted = "scanCompleted",
  scanCancelled = "scanCancelled",
  init = "init"
}

export interface IframeMessageData {
  type: IframeMessageTypes;
  data: {};
}
