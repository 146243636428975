import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { FileMimeType, FileResult, FileType, FileUploaderModalComponent, ImageSize, maxImageUploadSize } from "@app/shared/file-uploader";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslocoService } from "@ngneat/transloco";
import { BaseUploaderComponent } from "../base-uploader";

@Component({
  selector: "app-custom-image-uploader",
  templateUrl: "./custom-image-uploader.component.html",
  styleUrls: ["./custom-image-uploader.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CustomImageUploaderComponent,
      multi: true
    }
  ]
})
export class CustomImageUploaderComponent extends BaseUploaderComponent<string> {
  @Input() storageFolder = "brands";
  @Input() extensions: Array<FileMimeType> = [FileMimeType.image];
  @Input() maxFileUploadSize: number = maxImageUploadSize;
  @Input() allowedFileSize: ImageSize;
  @Input() hasCropper = false;

  value = "";

  constructor(protected changeDetectorRef: ChangeDetectorRef, private modalService: NgbModal, private transloco: TranslocoService) {
    super(changeDetectorRef);
  }

  onUpload(): void {
    const imageModal = this.modalService.open(FileUploaderModalComponent, { backdrop: "static", size: "lg", centered: true });
    const comp: FileUploaderModalComponent = imageModal.componentInstance;
    comp.uploadType = FileType.Image;
    comp.storageFolder = this.storageFolder;
    comp.title = this.transloco.translate("brand.upload");
    comp.buttonText = this.transloco.translate("common.file.saveImage");
    comp.extensions = this.extensions;
    comp.maxFileUploadSize = this.maxFileUploadSize;
    comp.allowedImageSize = this.allowedFileSize;
    comp.isCropper = this.hasCropper;

    imageModal.result
      .then((file: FileResult) => {
        const url = file.fileUrl;

        this.updateValue(url);
      })
      .catch(() => {});
  }

  onClear(): void {
    this.updateValue(null);
  }
}
