import { Pipe, PipeTransform } from "@angular/core";
import { NgxMaskPipe } from "ngx-mask";
import { countNumbersInString, phoneMask } from "ui-kit";

@Pipe({
  name: "phoneMask"
})
export class PhoneMaskPipe implements PipeTransform {
  private phoneMask = phoneMask;
  private maskPipe = new NgxMaskPipe();

  transform(value: string): string {
    if (!value) return "";

    const numberCount = countNumbersInString(phoneMask);
    const slicedValue = value.slice(-numberCount);
    const maskedValue = this.maskPipe.transform(slicedValue, this.phoneMask);

    return maskedValue;
  }
}
