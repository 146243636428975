import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output } from "@angular/core";
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Brand, BrandSocialLink, SocialLinkTypes } from "@app/models";
import { ConnectSocialNetworkWidgetSettings } from "@app/pages/components/widget-settings/connect-social-network-widget-settings/connect-social-network-widget-settings";
import { BrandSocialLinkService } from "@app/services";
import { Patterns } from "@app/shared/validators";
import { WidgetSettingsBaseComponent } from "../widget-settings-base";
import { AuthService } from "@app/core";

@Component({
  selector: "app-connect-social-network-widget-settings",
  templateUrl: "./connect-social-network-widget-settings.component.html",
  styleUrls: ["./connect-social-network-widget-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ConnectSocialNetworkWidgetSettingsComponent
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ConnectSocialNetworkWidgetSettingsComponent),
      multi: true
    }
  ]
})
export class ConnectSocialNetworkWidgetSettingsComponent extends WidgetSettingsBaseComponent implements OnInit {
  private tempLinks = [];
  private _linksFromCreateBrand: BrandSocialLink[];
  _storage: Storage = sessionStorage;
  private types = {
    facebook: SocialLinkTypes.Facebook,
    instagram: SocialLinkTypes.Instagram,
    twitter: SocialLinkTypes.Twitter,
    tiktok: SocialLinkTypes.TikTok,
    linkedin: SocialLinkTypes.LinkedIn,
    google: SocialLinkTypes.Google,
    snapchat: SocialLinkTypes.Snapchat,
    youtube: SocialLinkTypes.Youtube
  };
  private _brand: Brand;
  private _widget: ConnectSocialNetworkWidgetSettings;

  public isFromBrand = false;
  public links: BrandSocialLink[];
  public widgetLinks: BrandSocialLink[] = [];
  @Output() widgetSettingsReady = new EventEmitter();
  @Input() set widgetSettingsJson(value: string) {
    if (value) {
      this.widgetSettings = JSON.parse(value) as ConnectSocialNetworkWidgetSettings;
    }
  }

  get linksFromCreateBrand(): BrandSocialLink[] {
    if (!this._linksFromCreateBrand) this._linksFromCreateBrand = (JSON.parse(this._storage.getItem("links")) as BrandSocialLink[]) || null;
    return this._linksFromCreateBrand;
  }

  @Input() set brand(value: Brand) {
    if (value) this._brand = value;
  }
  get brand(): Brand {
    return this._brand;
  }

  public set widgetSettings(value: ConnectSocialNetworkWidgetSettings) {
    if (value && !this._widget) {
      this.isFromBrand = value.isFromBrand;
      setTimeout(() => {
        this.form.controls.isFromBrand.setValue(value.isFromBrand);

        this.form.controls.facebook.setValue(value.facebook);
        this.form.controls.instagram.setValue(value.instagram);
        this.form.controls.linkedin.setValue(value.linkedin);
        this.form.controls.twitter.setValue(value.twitter);
        this.form.controls.google.setValue(value.google);
        this.form.controls.snapchat.setValue(value.snapchat);
        this.form.controls.youtube.setValue(value.youtube);
        this.form.controls.tiktok.setValue(value.tiktok);
        Object.keys(value).forEach((key) => {
          let linkType: number;
          for (const [typeKey, typeValue] of Object.entries(this.types)) {
            if (typeKey === key) {
              linkType = typeValue;
            }
          }
          if (linkType) {
            const link = {
              linkUrl: value[key],
              linkType: linkType
            } as BrandSocialLink;
            this.widgetLinks.push(link);
          }
        });
      });
    }
    this.onChange(value);
    this.changeDetectorRef.markForCheck();
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private brandSocialLinkSvc: BrandSocialLinkService,
    public authService: AuthService
  ) {
    super(authService);
  }

  ngOnInit(): void {
    if (this.brand && this.brand.id > 0) {
      this.brandSocialLinkSvc.getBrandSocialLinks(this.brand.id).subscribe((result) => {
        this.links = result;
        this.changeDetectorRef.detectChanges();
      });
    } else {
      this.links = this.linksFromCreateBrand;
    }

    this.form = new UntypedFormGroup({
      facebook: new UntypedFormControl("", [Validators.maxLength(2000), Validators.pattern(Patterns.url)]),
      instagram: new UntypedFormControl("", [Validators.maxLength(2000), Validators.pattern(Patterns.url)]),
      linkedin: new UntypedFormControl("", [Validators.maxLength(2000), Validators.pattern(Patterns.url)]),
      twitter: new UntypedFormControl("", [Validators.maxLength(2000), Validators.pattern(Patterns.url)]),
      google: new UntypedFormControl("", [Validators.maxLength(2000), Validators.pattern(Patterns.url)]),
      snapchat: new UntypedFormControl("", [Validators.maxLength(2000), Validators.pattern(Patterns.url)]),
      youtube: new UntypedFormControl("", [Validators.maxLength(2000), Validators.pattern(Patterns.url)]),
      tiktok: new UntypedFormControl("", [Validators.maxLength(2000), Validators.pattern(Patterns.url)]),
      isFromBrand: new UntypedFormControl(false)
    });
    this.widgetSettingsReady.emit();
  }

  setFromLocation() {
    this.form.controls.isFromBrand.setValue(false);
    this.widgetSettings = this.widgetSettings;
    this.widgetLinks = this.tempLinks;
    this.form.updateValueAndValidity();
    this.onLinksChnaged(this.tempLinks);
    this.changeDetectorRef.detectChanges();
  }

  setFromBrand() {
    this.form.reset();
    this.form.controls.isFromBrand.setValue(true);
    this.onLinksChnaged(this.links);
    this.tempLinks = this.widgetLinks;
    this.widgetLinks = [];
  }

  onLinksChnaged(brandLinks: BrandSocialLink[]) {
    if (brandLinks) {
      this.form.controls.facebook.setValue(brandLinks.find((l) => l.linkType === SocialLinkTypes.Facebook)?.linkUrl);
      this.form.controls.instagram.setValue(brandLinks.find((l) => l.linkType === SocialLinkTypes.Instagram)?.linkUrl);
      this.form.controls.linkedin.setValue(brandLinks.find((l) => l.linkType === SocialLinkTypes.LinkedIn)?.linkUrl);
      this.form.controls.twitter.setValue(brandLinks.find((l) => l.linkType === SocialLinkTypes.Twitter)?.linkUrl);
      this.form.controls.google.setValue(brandLinks.find((l) => l.linkType === SocialLinkTypes.Google)?.linkUrl);
      this.form.controls.snapchat.setValue(brandLinks.find((l) => l.linkType === SocialLinkTypes.Snapchat)?.linkUrl);
      this.form.controls.youtube.setValue(brandLinks.find((l) => l.linkType === SocialLinkTypes.Youtube)?.linkUrl);
      this.form.controls.tiktok.setValue(brandLinks.find((l) => l.linkType === SocialLinkTypes.TikTok)?.linkUrl);
    }
  }
}
