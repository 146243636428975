import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import {
  appIconAllowedImageSize,
  Brand,
  BrandCheckName,
  BrandFilterParameters,
  BrandLogoTypeEnum,
  BrandSocialLink,
  BrandType,
  countries,
  countriesSettings,
  Country,
  Industry,
  User
} from "@app/models";
import {
  BrandService,
  BrandSocialLinkService,
  EnumRepresentationService,
  IndustryService,
  SalesAgentService,
  UserService
} from "@app/services";
import { Unsubscriber } from "@app/shared/components";
import { FileMimeType, ImageSize } from "@app/shared/file-uploader/file-uploader-modal/file-type";
import { numberAndLetterValidator, Patterns } from "@app/shared/validators";
import { Observable } from "rxjs";
import { debounceTime, map, tap } from "rxjs/operators";
import { SocialLinksFormControlComponent } from "../social-links-form-control/social-links-form-control.component";

@Component({
  selector: "app-brand-form-control",
  templateUrl: "./brand-form-control.component.html",
  styleUrls: ["./brand-form-control.component.scss"]
})
export class BrandFormControlComponent extends Unsubscriber implements OnInit {
  @Input() isSummary = false;
  @Input() isAdmin: boolean;

  @Input() set brand(brand: Brand) {
    if (brand && !this._brand) {
      this.form.controls.name.setValue(brand.name);
      this.form.controls.shortName.setValue(brand.shortName);
      this.form.controls.website.setValue(brand.website);
      this.form.controls.privacyPolicyUrl.setValue(brand.privacyPolicyUrl);
      this.form.controls.termsAndConditionsUrl.setValue(brand.termsAndConditionsUrl);
      this.form.controls.disclaimer.setValue(brand.disclaimer);
      this.form.controls.country.setValue(brand.country);
      this.form.controls.slogan.setValue(brand.slogan);
      this.form.controls.brandType.setValue(brand.brandType);
      this.form.controls.ownerId.setValue(brand.ownerId);
      this.form.controls.logo.setValue(brand.logo);
      this.form.controls.brandHeroImg.setValue(brand.brandHeroImg);
      this.form.controls.qrCodeLogo.setValue(brand.qrCodeLogo);
      this.form.controls.appIcon.setValue(brand.appIcon);
      this.form.controls.referralId.setValue(this.referralId);
      this.form.controls.industryId.setValue(brand.industryId);

      this.brandValid.emit(this.form.valid);
    }
    if (brand?.id) {
      this.brandLinkService.getBrandSocialLinks(brand.id).subscribe((result: BrandSocialLink[]) => {
        this.links = result;
      });
    } else {
      if (this.isAdmin) {
        this.brandTypes = [BrandType.Public];
        this.form.controls.brandType.setValue(BrandType.Public, { emitEvent: false });
        this.form.controls.brandType.disable();
      }
    }

    this.formControl.setValue(brand);
    this._brand = brand;
  }

  @Input() set countryCode(value: string) {
    if (value) {
      const country: Country = countriesSettings.find((c) => c.code === value);
      this.form.controls.country.setValue(country.id);
    }
  }

  @Output() selectChanged = new EventEmitter<Brand>();
  @Output() brandValid = new EventEmitter<boolean>();
  @Output() sociaLinksValid = new EventEmitter<boolean>();
  @Output() industrySelected = new EventEmitter<Industry>();

  @ViewChild(SocialLinksFormControlComponent, { static: false }) socialLinksControlComponent?: SocialLinksFormControlComponent;

  appIconExtensions = [FileMimeType.png];
  appIconAllowedImageSize: ImageSize = appIconAllowedImageSize;
  BrandLogoTypeEnum = BrandLogoTypeEnum;
  brandsSubscription: Observable<Array<Brand>>;
  brandTypeRepresentation: { [status: number]: string };
  brandTypes = [BrandType.Public, BrandType.Private];
  countries = countries;
  hasOwnBrand = false;
  industries: Industry[];
  userSubscription: Observable<Array<User>>;
  validators: Array<ValidatorFn> = [Validators.pattern(Patterns.url), Validators.maxLength(100)];
  formControl = new UntypedFormControl();
  form = new UntypedFormGroup({
    name: new UntypedFormControl("", [Validators.required]),
    shortName: new UntypedFormControl("", [Validators.required, numberAndLetterValidator(), Validators.maxLength(11)]),
    website: new UntypedFormControl("", [Validators.pattern(Patterns.url), Validators.maxLength(100)]),
    privacyPolicyUrl: new UntypedFormControl(null, [Validators.pattern(Patterns.url), Validators.maxLength(100)]),
    termsAndConditionsUrl: new UntypedFormControl("", [Validators.pattern(Patterns.url), Validators.maxLength(100)]),
    disclaimer: new UntypedFormControl(""),
    brandHeroImg: new UntypedFormControl(""),
    logo: new UntypedFormControl("", [Validators.required]),
    qrCodeLogo: new UntypedFormControl(""),
    appIcon: new UntypedFormControl(""),
    country: new UntypedFormControl(0, [Validators.required]),
    slogan: new UntypedFormControl(""),
    brandType: new UntypedFormControl(1, [Validators.required]),
    ownerId: new UntypedFormControl(null),
    referralId: new UntypedFormControl(null),
    industryId: new UntypedFormControl(null, [Validators.required])
  });

  private _brand: Brand;
  private _links: BrandSocialLink[];
  private _storage: Storage = sessionStorage;
  private _referralId: string;

  get links(): BrandSocialLink[] {
    if (!this._links) this._links = (JSON.parse(this._storage.getItem("links")) as BrandSocialLink[]) || null;
    return this._links;
  }

  set links(value: BrandSocialLink[]) {
    if (value) {
      this._links = value;
      this._storage.setItem("links", JSON.stringify(value));
    }
  }

  get referralId(): string {
    if (!this._referralId) this._referralId = JSON.parse(this._storage.getItem("referralId")) || null;
    return this._referralId;
  }

  set referralId(value: string) {
    if (value) {
      this._referralId = value;
      this._storage.setItem("referralId", JSON.stringify(value));
    }
  }

  get brand() {
    return this._brand;
  }

  get isEditable() {
    return !this.isSummary;
    // this.router.url.startsWith('/create-brand') &&
  }

  get isPrivate() {
    return this.form && this.form.controls.brandType.value === BrandType.Private;
  }

  constructor(
    private brandService: BrandService,
    private router: Router,
    private salesAgentService: SalesAgentService,
    private enumRepresentationService: EnumRepresentationService,
    private brandSocialLinkService: BrandSocialLinkService,
    private brandLinkService: BrandSocialLinkService,
    private userServcie: UserService,
    private cdr: ChangeDetectorRef,
    private industrySvc: IndustryService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadIndustries();
    /// TODO: Refactor this when we will have multiple brands
    if (this.isEditable) {
      this.sub = this.brandSocialLinkService.onLinksChanged.subscribe((result: BrandSocialLink[]) => {
        this.links = result;
      });
      this.subscribeChanges();
      this.userSubscription = this.userServcie.getAll();
      this.validateName();
      this.validateShortName();
      this.validateReferralId();
    } else {
      this.form.controls.disclaimer.disable();
      this.form.controls.slogan.disable();
      this.form.controls.shortName.disable();
      this.form.controls.name.disable();
      this.form.controls.country.disable();
      this.form.controls.referralId.disable();
      this.form.controls.industryId.disable();
      this.brandsSubscription = this.brandService.getPublicBrands();
      this.getOwnBrands();
    }
    this.enumRepresentationService.init();
    this.brandTypeRepresentation = this.enumRepresentationService.eventBrandTypes;
    this.setValidation();
  }

  select() {
    this.brand = this.formControl.value;
    if (!this.brand) {
      this.links = [];
      this.form.reset();
    }
    this.selectChanged.emit(this.brand);
  }

  onTypeChange() {
    this.setValidation();
    if (this.form.controls.brandType.value === BrandType.Public) this.form.controls.ownerId.setValue(null);
  }

  onLinksValid(value: boolean) {
    this.sociaLinksValid.emit(value);
  }

  onLinksChanged(links: BrandSocialLink[]) {
    this.links = links;
  }

  private loadIndustries() {
    this.industrySvc.getPublic().subscribe((industries) => {
      this.industries = industries;
      if (this.industries.length === 1) {
        this.form.controls.industryId.setValue(this.industries[0].id);
        this.form.controls.industryId.disable();
      }
    });
  }

  private subscribeChanges() {
    this.sub = this.form.controls.name.valueChanges
      .pipe(
        debounceTime(300),
        tap(() => {
          this.validateName();
        })
      )
      .subscribe();

    this.sub = this.form.controls.shortName.valueChanges
      .pipe(
        map((val) => {
          if (val) {
            const upperVal = (val as string).toUpperCase();
            if (val !== upperVal) {
              val = upperVal;
              this.form.controls.shortName.setValue(val);
            }
          }
          return val;
        }),
        debounceTime(300),
        tap(() => {
          this.validateShortName();
        })
      )
      .subscribe();

    this.sub = this.form.controls.referralId.valueChanges
      .pipe(
        debounceTime(300),
        tap(() => {
          this.validateReferralId();
        })
      )
      .subscribe();
    this.sub = this.form.controls.industryId.valueChanges
      .pipe(
        debounceTime(300),
        tap(() => {
          this.industrySelected.emit(this.industries.find((i) => i.id == this.form.controls.industryId.value));
        })
      )
      .subscribe();

    this.sub = this.form.valueChanges.subscribe(() => {
      const brand = {
        brandHeroImg: this.form.controls.brandHeroImg.value,
        logo: this.form.controls.logo.value,
        qrCodeLogo: this.form.controls.qrCodeLogo.value,
        appIcon: this.form.controls.appIcon.value,
        name: this.form.controls.name.value,
        shortName: this.form.controls.shortName.value,
        country: this.form.controls.country.value,
        website: this.form.controls.website.value,
        termsAndConditionsUrl: this.form.controls.termsAndConditionsUrl.value,
        privacyPolicyUrl: this.form.controls.privacyPolicyUrl.value,
        disclaimer: this.form.controls.disclaimer.value,
        slogan: this.form.controls.slogan.value,
        brandType: this.form.controls.brandType.value,
        ownerId: this.form.controls.ownerId.value,
        industryId: this.form.controls.industryId.value
      } as Brand;
      this.selectChanged.emit(brand);
      this.brandValid.emit(this.form.valid);
    });
  }

  private validateName() {
    if (this.form.controls.name.value) {
      this.brandValid.emit(false);
      this.brandService.checkName({ name: this.form.controls.name.value } as BrandFilterParameters).subscribe((res: BrandCheckName) => {
        if (res && res.nameErrors) {
          const isUniq = res.nameErrors.some((e) => e === "uniq");
          if (isUniq) {
            this.form.controls.name.setErrors({ notUnique: true });
          }
        }
        this.brandValid.emit(this.form.valid);
      });
    }
  }

  private validateShortName() {
    if (this.form.controls.shortName.value) {
      this.brandValid.emit(false);
      this.brandService
        .checkName({ shortName: this.form.controls.shortName.value } as BrandFilterParameters)
        .subscribe((res: BrandCheckName) => {
          if (res && res.shortNameErrors) {
            const isUniq = res.shortNameErrors.some((e) => e === "uniq");
            if (isUniq) {
              this.form.controls.shortName.setErrors({ notUnique: true });
            }
          }
          this.brandValid.emit(this.form.valid);
        });
    }
  }

  private validateReferralId() {
    this.referralId = this.form.controls.referralId.value;
    if (this.form.controls.referralId.value) {
      this.brandValid.emit(false);
      this.salesAgentService.getReferralByCode(this.form.controls.referralId.value).subscribe((agent) => {
        if (!agent) {
          this.form.controls.referralId.setErrors({ notUnique: true });
        }
        this.brandValid.emit(this.form.valid);
      });
    }
  }

  private getOwnBrands() {
    if (!this.isSummary)
      this.brandService.getOwnBrands().subscribe((brands: Brand[]) => {
        if (brands.length) {
          this.hasOwnBrand = true;
          this.brand = brands[0];
          this.formControl.disable();
          this.selectChanged.emit(this.brand);
        }
      });
  }

  private setValidation() {
    if (this.isAdmin && this.isPrivate) {
      this.form.controls.ownerId.setValidators([Validators.required]);
    } else this.form.controls.ownerId.setValidators([]);
    this.cdr.detectChanges();
  }
}
