import { DateStringRangeDto } from "@app/services";

export interface ActivationTransactionsPostRequestDto extends DateStringRangeDto {
  locationIds: number[];
}

export interface ActivationTransactionsPosResponsetDto {
  startDate: string;
  endDate: string;
  data: ActivationTransactionsPostDto[];
  total: number;
}

export interface ActivationTransactionsPostDto {
  companyPosId: string;
  locationId: number;
  locationName: string;
  phoneNumber: string;
  productId: string;
  productName: string;
  terminalId: string;
  timestamp: string;
}

export type ActivationTransactionsView = {
  localLocationName: string;
} & ActivationTransactionsPostDto;
