import { CommonModule } from "@angular/common";
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { LocationInfoPipe } from "@app/shared/pipes/location-info.pipe";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { TranslocoModule } from "@ngneat/transloco";
import { SimplebarAngularModule } from "simplebar-angular";
import { Location, LocationAvailability, LocationStatus } from "../../../models";

@Component({
  selector: "app-location-autocomplete-select",
  templateUrl: "./location-autocomplete-select.component.html",
  styleUrls: ["./location-autocomplete-select.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslocoModule, NgbTooltip, FormsModule, SimplebarAngularModule, LocationInfoPipe]
})
export class LocationAutocompleteSelectComponent implements OnInit, AfterViewInit {
  @Input() hideReset: boolean;
  @Input() selectionType: "single" | "multiple" = "multiple";
  @Input() selectedLocationId: number;
  @Input() loading = false;
  @Input() disabled = false;
  @Input() isRequired = false;

  @Input() set selectedLocationIds(selectedLocationIds: number[]) {
    if (selectedLocationIds) {
      this._selectedLocationIds = selectedLocationIds;
      this.handleSelectedLocations();
    }
  }

  @Input() set locations(locations: Location[] | LocationAvailability[]) {
    if (locations) {
      this._locations = locations;
      this.originalLocationLength = locations.length;
      this.filter();
      this.handleSelectedLocations();
      this.handleSelectedLocation(this.selectedLocationId);
    }
  }

  @Output() selectedLocationsChanged = new EventEmitter<Location[]>(); // selectionType === "multiple"
  @Output() selectedLocationChanged = new EventEmitter<Location>(); // selectionType === "single"

  @ViewChild("searchInput") searchInput: ElementRef;

  private _locations: Location[] = [];
  private _selectedLocationIds: number[];

  LocationStatus: LocationStatus;
  searchString = "";
  filteredLocations: Location[] = [];
  originalLocationLength = 0;

  selectedLocation: Location;
  selectedLocations: Location[] = [];
  selectedAll = false;

  scrollbarOptions: any = {
    forceVisible: false,
    autoHide: false
  };

  ngOnInit(): void {
    this.searchString = "";
    this.filter();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.searchInput) {
        this.searchInput.nativeElement.focus({ preventScroll: true });
      }
    }, 0);
  }

  filter(): void {
    this.filteredLocations = this.getFilteredLocations();
  }

  triggerSelectLocation(location: Location | LocationAvailability): void {
    if (this.selectionType === "multiple") {
      this.multipleSelectLocation(location);
    } else {
      this.selectLocation(location?.id);
    }
  }

  private multipleSelectLocation(location: Location): void {
    const targetItem = this.filteredLocations.find((item) => item.id == location.id);
    if (targetItem) {
      targetItem.selected = !targetItem.selected;
    }

    this.selectedLocations = this.getSelectedLocationIds();
    this.selectedLocationsChanged.emit(this.selectedLocations);
  }

  private multipleSelectByLocationIds(locationIds: number[]): void {
    this.filteredLocations.forEach((item: Location, index: number) => {
      this.filteredLocations[index].selected = locationIds.some((id: number) => id === item.id);
    });

    this.selectedLocations = this.getSelectedLocationIds();
    this.selectedAll = this.getSelectAll();
  }

  private selectLocation(locationId?: number): void {
    this.selectedLocation = null;

    this.filteredLocations.forEach((item: Location, index: number) => {
      this.filteredLocations[index].selected = item.id === locationId;

      if (this.filteredLocations[index].selected) {
        this.selectedLocation = this.filteredLocations[index];
      }
    });
  }

  private resetSelection(): void {
    // single selection only
    this.selectLocation();
  }

  private resetSelections(): void {
    // multiple selection only
    this.selectedAll = true;

    this.triggerSelectAll();
  }

  triggerSelectAll(): void {
    // multiple selection only
    if (this.selectionType === "multiple") {
      this.searchString = "";
      this.selectedAll = !this.selectedAll;
      this.selectedLocations = [];

      this.filteredLocations = this._locations.map((item) => {
        item.selected = this.selectedAll;

        if (item.selected) {
          this.selectedLocations.push(item);
        }

        return item;
      });

      this.selectedLocationsChanged.emit(this.selectedLocations);
    }
  }

  onReset(): void {
    if (this.selectionType === "single") {
      this.resetSelection();
      this.selectedLocationChanged.emit(null);
    }
  }

  onSave(): void {
    // single selection only
    if (this.selectionType === "single" && this.selectedLocation) {
      this.selectedLocationChanged.emit(this.selectedLocation);
    }
  }

  private getFilteredLocations(): Location[] {
    return this._locations.filter((location: Location) => {
      return location.companyName?.toLowerCase().includes(this.searchString.toLowerCase());
    });
  }

  private getSelectedLocationIds(): Location[] {
    return this._locations.filter((location: Location) => location.selected);
  }

  private getSelectAll(): boolean {
    return this.selectedLocations.length === this.originalLocationLength;
  }

  private handleSelectedLocations(): void {
    // only for "multiple" select
    if (this.selectionType === "multiple" && !!this._selectedLocationIds) {
      this.multipleSelectByLocationIds(this._selectedLocationIds);
    }
  }

  private handleSelectedLocation(selectedLocationId: number): void {
    // only for "single" select
    if (this.selectionType === "single" && this.selectedLocationId) {
      this.selectLocation(selectedLocationId);
    }
  }
}
