import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AccountService, AppConfigService, AuthService, UserPermissionService } from "@app/core";
import { PlatformAccountService, ViewAsPartnerService } from "@app/services";
import { SidebarComponent } from "../sidebar/sidebar.component";

@Component({
  selector: "app-sidebar-horizontal",
  templateUrl: "./sidebar-horizontal.component.html",
  styleUrls: ["./sidebar-horizontal.component.scss"]
})
export class SidebarHorizontalComponent extends SidebarComponent {
  constructor(
    router: Router,
    configSvc: AppConfigService,
    platformAccountSvc: PlatformAccountService,
    accountSvc: AccountService,
    authService: AuthService,
    userPermissionService: UserPermissionService,
    viewAsPartnerService: ViewAsPartnerService
  ) {
    super(router, configSvc, platformAccountSvc, accountSvc, authService, userPermissionService, viewAsPartnerService);
    router.events.subscribe((val) => {
      SidebarComponent.removeAllClasses();
    });
  }
}
