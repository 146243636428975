import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CfsTemplate } from "@app/models";
import { TemplatePreviewType } from "clearline-common";

@Component({
  selector: "app-printable-template",
  templateUrl: "./printable-template.component.html",
  styleUrls: ["./printable-template.component.scss"]
})
export class PrintableTemplateComponent {
  @Input() template: CfsTemplate;

  @Output() print = new EventEmitter();

  readonly TemplatePreviewType = TemplatePreviewType;

  private readyToPrintTemplateCount = 0;

  onPrint(): void {
    this.readyToPrintTemplateCount += 1;

    if (this.readyToPrintTemplateCount === 2) {
      setTimeout(() => {
        this.print.emit();
      }, 0);
    }
  }
}
