import { LoyaltyRewardGetDto } from "clearline-api";
import { RewardCardView } from "../../models";

export function rewardsDtoToRewardCardList(rewards: LoyaltyRewardGetDto[] = []): RewardCardView[] {
  return rewards.map((reward: LoyaltyRewardGetDto) => ({
    imageUrl: reward.couponInfo?.couponImage || "",
    title: reward.couponInfo?.title || "",
    subTitle: reward.couponInfo?.name || "",
    campaign: reward.campaign || "",
    session: reward.session || "",
    claimed: reward.claimed
  }));
}
