import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-checkbox",
  templateUrl: "./checkbox.component.html",
  styleUrls: ["./checkbox.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxComponent {
  @Input() inlineLabel = "";
  @Input() label = "";
  @Input() isRequired = false;
  @Input() isSlider = false;
  @Input() size: "" | "md" | "lg" = "";
  @Input() control: FormControl | null = null;
  @Input() tooltip = "";
  @Input() tooltipSize: "wide" | "normal" = "normal";
  @Input() tooltipTriggers: "click:blur" | "hover" = "click:blur";

  @Output() selected = new EventEmitter(this.control?.value);

  onChange(): void {
    if (this.control) {
      this.selected.emit(this.control.value);
    }
  }
}
