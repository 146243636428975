import { AccountConfigurationDto } from "./account-configuration";

export interface Account {
  id?: number;
  companyName: string;
  billingCountry?: number;
  billingCity: string;
  billingWebsite: string;
  billingState: string;
  billingAddress: string;
  billingContactEmail: string;
  billingContactLastName: string;
  billingContactFirstName: string;
  billingEmail: string;
  billingPhoneNumber: string;
  billingZip: string;
  billingLongState: string;
  isBillingAddress: boolean;
  salesAgentId?: number;
  companyFullName?: string;
  numberOfLocations?: number;
  isPosIntegrationAvailable?: boolean;
  partnerId?: number;
  partnerName?: string;
  posSystemId?: number;
  companyPosId?: string;
  smsApiKey?: string;

  // todo: move from 'clearline-admin`, verify with backend DTO:
  // accountManager?: User;
  configuration: AccountConfigurationDto;
}

export interface AccountSettingsGetDto {
  ambassadorFeatureAvailable: boolean;
  ambassadorEnabled: boolean;
}

export interface AccountSettingsPostDto {
  ambassadorEnabled: boolean;
}
