import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output
} from "@angular/core";
import {
  CouponPerUsers,
  IssueCouponWidgetSettings
} from "@app/pages/components/widget-settings/issue-coupon-widget-settings/issue-coupon-widget-settings";
import { WidgetSettingsBaseComponent } from "@app/pages/components/widget-settings/widget-settings-base";
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { CategoryService, CouponService, EnumRepresentationService, SubscriptionPlanService } from "@app/services";
import { Brand, Category, CouponInfo, CouponInfoQr, Location, SubscriptionPlan } from "@app/models";
import { switchMap, takeUntil, tap } from "rxjs/operators";
import { Subject } from "rxjs";
import { TranslocoService } from "@ngneat/transloco";
import { AuthService } from "@app/core";

@Component({
  selector: "app-issue-coupon-widget-settings",
  templateUrl: "./issue-coupon-widget-settings.component.html",
  styleUrls: ["./issue-coupon-widget-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: IssueCouponWidgetSettingsComponent
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IssueCouponWidgetSettingsComponent),
      multi: true
    }
  ]
})
export class IssueCouponWidgetSettingsComponent extends WidgetSettingsBaseComponent implements OnInit, OnDestroy {
  private _storage: Storage = sessionStorage;
  private _brand: Brand;
  loadModal = false;
  private _plan: SubscriptionPlan;
  private _location: Location;
  public limitCoupons: number;
  public selectedCoupon: CouponInfoQr;
  public activeCoupons: CouponInfoQr[] = [];
  public coupons: Array<CouponInfoQr> = [];
  public categories: Array<any> = [];
  public selectedBadge = new Category();

  selectedNotActiveCoupons: Array<CouponInfoQr> = [];
  messageSelectedNotActiveCoupons: string = null;

  loading = true;
  private search$ = new Subject();
  private unsubscribe$ = new Subject();

  public couponPerUserRepresentation: { [status: number]: string };
  public perUserSettings = [CouponPerUsers.Forever, CouponPerUsers.PerMonth, CouponPerUsers.PerYear];
  private _widget: IssueCouponWidgetSettings;

  @Output() widgetSettingsReady = new EventEmitter();
  @Input() set widgetSettingsJson(value: string) {
    if (value) this.widgetSetting = JSON.parse(value) as IssueCouponWidgetSettings;
  }

  @Input() set brand(value: Brand) {
    if (value) this._brand = value;
  }

  get brand(): Brand {
    return this._brand;
  }

  @Input() set location(value: Location) {
    if (value) this._location = value;
  }

  get location(): Location {
    return this._location;
  }

  public get plan(): SubscriptionPlan {
    if (!this._plan) {
      this._plan = (JSON.parse(this._storage.getItem("plan")) as SubscriptionPlan) || null;
    }
    return this._plan;
  }

  public set widgetSetting(value: IssueCouponWidgetSettings) {
    if (value && !this._widget) {
      setTimeout(() => {
        if (value.activeCoupons) this.form.controls.activeCoupons.patchValue(value.activeCoupons);

        if (value.allowMultipleCoupons) {
          this.form.controls.allowMultipleCoupons.setValue(value.allowMultipleCoupons);
        }

        if (value.couponPerUser) this.form.controls.couponPerUser.setValue(value.couponPerUser);
      });
    }

    this.onChange(value);
    this.changeDetectorRef.markForCheck();
  }

  constructor(
    private translateSvc: TranslocoService,
    private planService: SubscriptionPlanService,
    private changeDetectorRef: ChangeDetectorRef,
    private couponService: CouponService,
    private categoryService: CategoryService,
    private enumRepresentationService: EnumRepresentationService,
    public authService: AuthService
  ) {
    super(authService);
  }

  ngOnInit(): void {
    this.enumRepresentationService.init();
    this.couponPerUserRepresentation = this.enumRepresentationService.eventCouponPerUserTypes;

    this.form = new UntypedFormGroup({
      allowMultipleCoupons: new UntypedFormControl(false),
      couponPerUser: new UntypedFormControl(CouponPerUsers.Forever),
      activeCoupons: new UntypedFormControl([], [Validators.required])
    });

    this.loadCoupons();
    this.search$.next();
    this.widgetSettingsReady.emit();
  }

  setSessionValueCoupons(ids) {
    if (ids && this.coupons) {
      this.coupons.forEach((e, i) => {
        ids.forEach((o) => {
          if (o === e.id) {
            e.isSelected = true;
            this.activeCoupons.push(e);
          }
        });
      });
    }
  }

  clearSelectedCouponsAfterChangePlan() {
    if (this.activeCoupons.length > this.limitCoupons) {
      this.coupons.forEach((e) => (e.isSelected = false));
      this.form.controls.activeCoupons.patchValue([]);
      this.activeCoupons = [];
    }
  }

  select(coupon: CouponInfoQr) {
    this.selectedCoupon = coupon;
    if (coupon.isSelected) {
      coupon.isSelected = false;
      this.activeCoupons = this.activeCoupons.filter((item) => item.id !== coupon.id);
    } else {
      coupon.isSelected = true;
      this.activeCoupons.push(coupon);
    }
    const ids = [];
    this.activeCoupons.forEach((e) => {
      ids.push(e.id);
    });

    this.form.controls.activeCoupons.patchValue(ids);
    if (this.activeCoupons.length > this.limitCoupons || this.activeCoupons.length < 1) {
      this.form.controls.activeCoupons.patchValue([]);
    }
    this.getCheckIfSelectedNotActiveCoupons();
  }

  private getCategories() {
    this.categoryService.getCategories().subscribe((categories: Array<any>) => {
      this.categories = categories;
    });
  }

  onBadgetChanged(category: any) {
    this.selectedBadge = category;
    this.search$.next();
  }

  getLimitCouponsByPlan() {
    if (this.brand.id > 0) {
      this.getLimitCouponsByLocation();
    } else {
      this.limitCoupons = this.plan.limits.couponLimit;
      this.clearSelectedCouponsAfterChangePlan();
    }
  }

  getLimitCouponsByLocation() {
    if (this.location) {
      this.planService.getLocationPlan(this.location.id).subscribe((res) => {
        this.limitCoupons = res.limits.couponLimit;
        this.changeDetectorRef.markForCheck();
      });
    }
  }

  loadCoupons(): void {
    this.search$
      .pipe(
        tap(() => {
          this.loading = true;
          this.coupons = null;
        }),
        switchMap(() =>
          this.location && this.location.id
            ? this.couponService.getLocationCoupons(this.location.id)
            : this.couponService.getIndustryCoupons(this.brand.industryId)
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        (coupons: Array<CouponInfo>) => {
          this.coupons = coupons ? coupons.map((item) => CouponInfoQr.toCouponSettings(item)) : null;
          this.setSessionValueCoupons(this.form.controls.activeCoupons.value);
          this.getLimitCouponsByPlan();
          this.selectedCoupon = null;
          this.loading = false;
          this.getCheckIfSelectedNotActiveCoupons();
          this.changeDetectorRef.markForCheck();
        },
        () => {
          this.loading = false;
        }
      );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getCheckIfSelectedNotActiveCoupons() {
    this.selectedNotActiveCoupons = this.coupons.filter((o) => o.status !== "active" && o.isSelected == true);
    if (this.selectedNotActiveCoupons.length == 1) {
      this.messageSelectedNotActiveCoupons = this.translateSvc.translateObject("coupons.messageSelectedNotActiveCoupon");
    } else if (this.selectedNotActiveCoupons.length > 1) {
      this.messageSelectedNotActiveCoupons = this.translateSvc.translateObject("coupons.messageSelectedNotActiveCoupons");
    } else {
      this.messageSelectedNotActiveCoupons = null;
    }
  }
}
