import { CouponInfo } from "@app/models/coupons";
import { WidgetType } from "@app/models/widget-type";
import { AllowedDeliveryChannel } from "./campaign";
import { TemplateInfoEnum } from "./cfsTemplate";
import { TemplateValidationErrorDto } from "clearline-api";

export interface ConditionPostDto {
  name?: string;
  conditionType: ConditionType;
  conditionParametersJson?: ConditionTriggerInfo;
  marketingAction: ConditionMarketingAction;
  widgetId?: WidgetType;
  accountTemplateId?: number;
  widgetDataJson?: CouponPostDto;
  deliveryChannel: AllowedDeliveryChannel;
}

export interface ConditionGetDto {
  id: number;
  name?: string;
  active: boolean;
  conditionType: ConditionType;
  conditionParametersJson?: string;
  marketingAction: ConditionMarketingAction;
  widgetId?: WidgetType;
  accountTemplateId?: number;
  widgetDataJson?: string;
  locationsAmount?: number;
  deliveryChannel: AllowedDeliveryChannel;
}

export interface Condition extends ConditionGetDto {
  errors?: ConditionValidationErrorDto[];
}

export interface CouponPostDto {
  CouponId: string;
  CouponImageUrl?: string;
  CouponUrl: string;
  CouponName?: string;
  CouponOffer?: string;
}

export interface TemplateContentTypeDto {
  id: number;
  name: TemplateInfoEnum;
}

export interface ConditionLocationsDto {
  conditionId: number;
  locationIds: number[];
}

export interface ConditionWithLocationIdsDto {
  condition: ConditionGetDto;
  locationIds?: number[];
}

export interface ConditionsGetDto {
  locationIds?: number[];
  name?: string;
  accountId: number;
}

export interface ConditionWidgetsInfo {
  widgets: WidgetType[];
}

export enum ConditionType {
  TransactionAmount = "TransactionAmount",
  ProductName = "ProductName",
  Manufacturer = "Manufacturer",
  ProductCategory = "ProductCategory",
  ProductQuantity = "ProductQuantity"
}

export interface ConditionTypeGetDto {
  id: number;
  name: string;
  conditionType: ConditionType;
}

export enum ConditionMarketingAction {
  Widget = "Widget",
  CfsDisplayPromotionalSlider = "CfsDisplayPromotionalSlider"
}
export interface ConditionMarketingActionInfo {
  widgetType: WidgetType;
  label: string;
}

export interface PosConditionOptionsInfo {
  label: string;
  value: string;
}

export interface PosConditionTypeInfo {
  label: string;
  value: ConditionType;
  type?: string;
}

export interface ConditionOptionsForm {
  name?: string;
  type?: PosConditionTypeInfo;
  trigger?: ConditionTriggerInfo;
  marketingAction?: ConditionMarketingAction;
  widget?: ConditionMarketingActionInfo;
  accountTemplateId?: number;
  details?: CouponInfo;
  deliveryChannel?: AllowedDeliveryChannel;
}

export interface ConditionOptionsBaseForm extends ConditionOptionsForm {
  id?: number;
  couponUrl?: string;
  locationsAmount?: number;
}

export interface ConditionInfo {
  active: boolean;
  id: number;
  name: string;
  type: string;
  trigger: string;
  marketingAction: string;
  locationsAmount?: number;
  triggerFrom?: number;
  errors?: ConditionValidationErrorDto[];
}

export enum ConditionTable {
  active = "active",
  name = "name",
  type = "type",
  trigger = "trigger",
  marketingAction = "marketingAction",
  locationsAmount = "locationsAmount"
}

export interface ConditionTriggerInfo {
  min: number;
  max: number;
  names: string[];
}

export enum ConditionControlNames {
  name = "name",
  type = "type",
  trigger = "trigger",
  triggerFrom = "min",
  triggerTo = "max",
  names = "names",
  marketingAction = "marketingAction",
  widget = "widget",
  templateContentType = "templateContentType",
  accountTemplateId = "accountTemplateId",
  details = "details",
  deliveryChannel = "deliveryChannel"
}

export enum PosConditionsOptionsLabels {
  name = "POSConditions.table.name",
  type = "POSConditions.table.type",
  trigger = "POSConditions.wizard.form.trigger",
  marketingAction = "POSConditions.table.marketingAction",
  widget = "POSConditions.table.widget",
  details = "POSConditions.wizard.complete.details"
}

export type ConditionTriggerRangeType = "float" | "integer";

export interface ConditionWidgetMessageDeliveryGetDto {
  id: number;
  conditionId: number;
  widgetId: WidgetType;
  deliveryChannels: AllowedDeliveryChannel;
  deliveryType: PosDeliveryDelayType;
  delayPeriod?: PosDeliveryDelayPeriod;
  delayBy?: number;
  sendingTime?: string;
  widgetDataJson?: CouponPostDto;
}
export interface ConditionWidgetMessageDeliveriesPutDto {
  widgetMessageDeliveries: ConditionWidgetMessageDeliveryPutDto[];
}
export interface ConditionWidgetMessageDeliveryPutDto {
  id: number;
  widgetId: WidgetType;
  deliveryChannels: AllowedDeliveryChannel;
  deliveryType: PosDeliveryDelayType;
  delayPeriod?: PosDeliveryDelayPeriod;
  delayBy?: number;
  sendingTime?: string;
  widgetDataJson?: CouponPostDto;
}
export enum PosDeliveryDelayType {
  Instant = "Instant",
  FromTransactionTime = "FromTransactionTime",
  FutureTime = "FutureTime"
}
export enum PosDeliveryDelayPeriod {
  Instant = "Instant",
  Minutes = "Minutes",
  Hours = "Hours",
  Days = "Days",
  Weeks = "Weeks",
  Months = "Months"
}

export interface ConditionValidationErrorDto extends TemplateValidationErrorDto {
  templateId: number;
}

export interface ConditionValidationErrorsDto {
  conditionId: number;
  errors: ConditionValidationErrorDto[];
}
