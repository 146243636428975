<!-- ========== Left Sidebar Start ========== -->

<ng-template #contentTemplate>
  <!-- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul class="navbar-nav" id="side-menu" #sideMenu>
      <!-- {{menuItems}} -->
      <ng-container *ngFor="let item of menuItems">
        <li class="nav-item" [class.d-none]="item.hidden">
          <a
            *ngIf="item.subItems"
            [tooltipClass]="'tooltip-menu-horizontal'"
            [ngbTooltip]="labelTranslationKey + item.label | transloco"
            placement="bottom"
            class="is-parent has-arrow d-flex nav-main-item"
          >
            <i class="{{ item.icon }}" *ngIf="item.icon"></i>

            <span class="mx-2"><span class="d-none d-xl-block d-xxl-block">
              <ng-container *ngTemplateOutlet="itemLabel; context: { label: item.label }"></ng-container>
            </span></span>
          </a>
          <a
            #sideNavLink
            *ngIf="item.routePath"
            class="d-flex"
            [ngbTooltip]="labelTranslationKey + item.label | transloco"
            [tooltipClass]="'tooltip-menu-horizontal'"
            placement="bottom"
            [routerLink]="item.routePath"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <i class="{{ item.icon }}" *ngIf="item.icon"></i>
            <span class="d-none d-xl-block d-xxl-block">
              <ng-container *ngTemplateOutlet="itemLabel; context: { label: item.label }"></ng-container>
            </span>
          </a>
          <a
            #sideNavLink
            *ngIf="item.function"
            class="d-flex"
            [ngbTooltip]="labelTranslationKey + item.label | transloco"
            [tooltipClass]="'tooltip-menu-horizontal'"
            placement="bottom"
            (click)="this[item.function](item.param)"
            routerLink=""
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <i class="{{ item.icon }}" *ngIf="item.icon"></i>
            <span class="d-none d-xl-block d-xxl-block">
              <ng-container *ngTemplateOutlet="itemLabel; context: { label: item.label }"></ng-container>
            </span>
          </a>

          <ul *ngIf="item.subItems" [class.d-none]="item.hidden" class="sub-menu" aria-expanded="false">
            <ng-container *ngFor="let subitem of item.subItems">
              <li [class.d-none]="subitem.hidden">
                <a
                  #sideNavLink
                  *ngIf="subitem.routePath"
                  [routerLink]="subitem.routePath"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  <ng-container *ngTemplateOutlet="itemLabel; context: { label: subitem.label }"></ng-container>
                </a>
                <a
                  #sideNavLink
                  *ngIf="subitem.function"
                  (click)="this[subitem.function](subitem.param)"
                  [routerLink]=""
                  [class.no-wrap]="(labelTranslationKey + subitem.label | transloco).length < 25"
                >
                  <ng-container *ngTemplateOutlet="itemLabel; context: { label: subitem.label }"></ng-container>
                  <sup><i class="{{ subitem.icon }}"></i></sup>
                </a>
                <a
                  #sideNavLink
                  *ngIf="subitem.hyperLink"
                  [href]="subitem.hyperLink"
                  target="_blank"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  <ng-container *ngTemplateOutlet="itemLabel; context: { label: subitem.label }"></ng-container>
                  <sup><i class="{{ subitem.icon }}"></i></sup>
                </a>

                <a *ngIf="subitem.subItems" class="side-nav-link-a-ref has-arrow">
                  <ng-container *ngTemplateOutlet="itemLabel; context: { label: subitem.label }"></ng-container>
                </a>

                <ul *ngIf="subitem.subItems" class="sub-menu mm-collapse" aria-expanded="false">
                  <ng-container *ngFor="let subSubitem of subitem.subItems">
                    <li [class.d-none]="subSubitem.hidden">
                      <a
                        #sideNavLink
                        *ngIf="subSubitem.routePath"
                        [routerLink]="subSubitem.routePath"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }"
                      >
                        <ng-container *ngTemplateOutlet="itemLabel; context: { label: subSubitem.label }"></ng-container>
                      </a>
                      <a #sideNavLink *ngIf="subSubitem.function" (click)="this[subSubitem.function](subSubitem.param)" [routerLink]="">
                        <ng-container *ngTemplateOutlet="itemLabel; context: { label: subSubitem.label }"></ng-container>
                        <sup><i class="{{ subSubitem.icon }}"></i></sup>
                      </a>
                      <a
                        #sideNavLink
                        *ngIf="subSubitem.hyperLink"
                        [href]="subSubitem.hyperLink"
                        target="_blank"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }"
                      >
                        <ng-container *ngTemplateOutlet="itemLabel; context: { label: subSubitem.label }"></ng-container>
                        <sup><i class="{{ subSubitem.icon }}"></i></sup>
                      </a>
                    </li>
                  </ng-container>
                </ul>
              </li>
            </ng-container>
          </ul>
        </li>
      </ng-container>
      <ng-container *transloco="let t; read: 'common.topBar'">
        <li class="nav-item" style="right: 20px; position: absolute">
          <a
            [tooltipClass]="'tooltip-menu'"
            [ngbTooltip]="logoutTooltip"
            placement="bottom"
            class="is-parent"
            (click)="logout()"
          >
            <i class="ri-logout-circle-line"></i>
            <span>{{ t("logout") }}</span>
          </a>
        </li>
      </ng-container>
    </ul>
  </div>
</ng-template>

<div class="horizontal-menu">
  <nav class="navbar navbar-expand-sm bg-dark navbar-dark">
    <div class="container-fluid min-width-100">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </nav>
</div>

<ng-template #itemLabel let-label="label">
  <ng-container *ngIf="label">{{ labelTranslationKey + label | transloco }}</ng-container>
</ng-template>
