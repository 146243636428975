export interface PagesWidgetCompletePostDto {
  cashRegisterId: number;
  campaignId: number;
  profile: RecipientProfilePostDto;
}

export interface RecipientProfilePostDto {
  firstName?: string;
  lastName?: string;
  customerEmail?: string;
  customerPhone?: string;
  salesPerson?: string;
  purchaseName?: string;
  purchaseId?: string;
  purchaseCategory?: string;
  purchasePrice?: string;
  gender?: string;
}

export interface PagesWidgetQrCodeDataDto {
  widgetName: string;
  qrCodeLinkUrl: string;
  shortUrl: string;
  joinButtonText: string;
}
