import { Platform } from "./platform";

export class ExternalFeature {
  id: ExternalFeatureTypes;
  code: string;
  name: string;
  platformId: number;
  categoryId: number;
  platform: Platform;
  url: string;
}
export enum ExternalFeatureTypes {
  Vouchers = 1,
  LoyaltyProgram = 2,
  Coupons = 3,
  Surveys = 4,
  TwoReward = 5,
  Waitlist = 6,
  SmsGlobal = 7,
  LoginGlobal = 8,
  Landing = 9,
  CodeGeneration = 10,
  EmailMarketing = 11,
  TabletKiosk = 12,
  ReplyReview = 13,
  SocialMediaMarketing = 14,
  OnlineListingsManagement = 15,
  UnifiedReportingDashboard = 16,
  ExecutiveReport = 17,
  AppsMarketplace = 18,
  Connectors = 19,
  CouponsCatalog = 20,
  MobileKiosk = 21,
  CampaignAutomation = 22,
  SingleUrl = 23,
  GoogleTagManager = 24,
  ReferralProgram = 25,
  BirthdayClub = 26,
  RedeemReferralsReport = 27,
  ReferralRewardsRedemptions = 28,
  smsContacts = 29,
  loyaltyContacts = 30,
  SmsReminder = 31,
  VipClub = 32,
  AppoinmentBooking = 33
}

export enum ExternalFeatureCategories {
  Undefined = 0,
  MarketingAppsSettings = 1,
  AdvancedMarketingTools = 2,
  OutboundMarketing = 3,
  ReportingAnalytics = 4,
  Contacts = 5,
  CustomTransactionReport = 6,
  LoyaltyReporting = 7
}

export class ExternalFeatureCategoriesTitle {
  id: ExternalFeatureCategories;
  title: string;
}
export const externalFeatureCategoriesTitleSettings: ExternalFeatureCategoriesTitle[] = [
  {
    id: ExternalFeatureCategories.MarketingAppsSettings,
    title: "Marketing Apps Settings"
  },
  {
    id: ExternalFeatureCategories.AdvancedMarketingTools,
    title: "Advanced Tools"
  },
  {
    id: ExternalFeatureCategories.OutboundMarketing,
    title: "Outbound Marketing"
  },
  {
    id: ExternalFeatureCategories.ReportingAnalytics,
    title: "ReportingAnalytics"
  },
  {
    id: ExternalFeatureCategories.Contacts,
    title: "Contacts"
  },
  {
    id: ExternalFeatureCategories.CustomTransactionReport,
    title: "CustomTransactionReport"
  },
  {
    id: ExternalFeatureCategories.LoyaltyReporting,
    title: "LoyaltyReporting"
  }
];
