import { IApplicationConfig, IAuthConfig } from "./application-config";

export interface CoreKitConfig {
  isProduction: boolean;
  baseName: string;
  apiName: string;
  selfServicePortalUrl?: string;
  authConfig?: IAuthConfig;
  redirectUrlKey?: keyof IApplicationConfig;
}
