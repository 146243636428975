import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { AccountsService } from "@app/services";
import { map } from "rxjs/operators";
import { Account, RoleTypes } from "@app/models";
import { AccountService } from "@app/core";

@Injectable()
export class PosIntegrationGuard  {
  constructor(private accountsService: AccountsService, private router: Router, private accountService: AccountService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const isManager: boolean = this.accountService.isUserInRole(RoleTypes.Manager);
    const isLocationManager: boolean = this.accountService.isUserInRole(RoleTypes.LocationManager);

    if (isManager || isLocationManager) {
      return this.accountsService.getCurrentAccount().pipe(
        map((account: Account) => {
          const isPosIntegrationAvailable: boolean = account?.isPosIntegrationAvailable;

          if (!isPosIntegrationAvailable) {
            this.router.navigate(["/access-denied"]).then(() => {});
          }

          return isPosIntegrationAvailable;
        })
      );
    }

    return of(false);
  }
}
