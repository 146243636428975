import { Injectable } from "@angular/core";
import { Account } from "@app/models";
import { AccountService as AccountServiceCore } from "core-kit";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class AccountService extends AccountServiceCore {
  private currentAccountSrc$ = new BehaviorSubject<Account>(null);

  readonly currentAccount$ = this.currentAccountSrc$.asObservable();

  setCurrentAccount(account: Account) {
    this.currentAccountSrc$.next(account);
  }
}
