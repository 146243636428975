import { KeyValueAsset } from "./media-content";
import { ApplicationStylesGetDto } from "./application-styles";

export interface TemplatePostDto {
  name?: string;
  defaultTemplateId: number;
  order: number;
  templateType: DefaultTemplateType;
  enabled: boolean;
  templateProperties?: any; // add TemplateProperties model
  useOnPos: boolean;
  useOnCfs: boolean;
  useForAllLocations: boolean;
  useAllDisplayTypes: boolean;
  displayTypeIds?: number[];
}

export interface TemplateGetDto {
  id: number;
  name?: string;
  templateName?: string;
  widgetId?: number;
  defaultTemplateId: number;
  order: number;
  templateType: DefaultTemplateType;
  isDefault: boolean;
  globalTemplateType: GlobalTemplateType;
  enabled: boolean;
  canEnable: boolean;
  templateProperties?: any; // add TemplateProperties model
  industryId?: number;
  industryName?: string;
  useOnPos: boolean;
  useOnCfs: boolean;
  useForAllLocations: boolean;
  applicationStyles: ApplicationStylesGetDto;
  useAllDisplayTypes: boolean;
  displayTypeIds?: number[];
}

export interface GlobalTemplateGetDto {
  id: number;
  name?: string;
  widgetId?: number;
  defaultTemplateId: number;
  templateType: DefaultTemplateType;
  isDefault: boolean;
  globalTemplateType: GlobalTemplateType;
  canEnable: boolean;
  templateProperties?: any; // add TemplateProperties model
  industryId?: number;
  industryName?: string;
  activeAccountsCount: number;
  useForAllAccounts: boolean;
  accountIds?: number[];
  useOnPos: boolean;
  useOnCfs: boolean;
  useAllDisplayTypes: boolean;
  displayTypeIds?: number[];
  configuration?: TemplateConfigurationDto;
  applicationStyles?: ApplicationStylesGetDto;
}

export interface AccountTemplateGetDto {
  id: number;
  name?: string;
  templateName?: string;
  widgetId?: number;
  defaultTemplateId: number;
  order: number;
  templateType: DefaultTemplateType;
  isDefault: boolean;
  globalTemplateType: GlobalTemplateType;
  templateProperties?: any; // add TemplateProperties model
  industryId?: number;
  industryName?: string;
  activeAccountsCount: number;
  locationTemplates?: LocationTemplateGetDto[];
  useOnPos: boolean;
  useOnCfs: boolean;
  useForAllLocations: boolean;
  useAllDisplayTypes: boolean;
  displayTypeIds?: number[];
  configuration?: TemplateConfigurationDto;
  applicationStyles?: ApplicationStylesGetDto;
}

export interface GeneratedTemplateGetDto {
  id: number;
  templateId: number;
  templateContentTypeId: number;
  name?: string;
  templateName?: string;
  widgetId?: number;
  defaultTemplateId: number;
  order: number;
  templateType: DefaultTemplateType;
  isDefault: boolean;
  globalTemplateType: GlobalTemplateType;
  enabled: boolean;
  canEnable: boolean;
  templateProperties?: any; // add TemplateProperties model
  generatedBody?: string;
  configuration?: TemplateConfigurationDto;
  locationTemplates?: LocationTemplateGetDto[];
  redirectSettings?: TemplateInteractionRedirectConfigModel;
}

export interface TemplatePutDto {
  id?: number;
  name?: string;
  order: number;
  templateType: DefaultTemplateType;
  enabled: boolean;
  templateProperties?: any; // add TemplateProperties model
  useOnPos: boolean;
  useOnCfs: boolean;
  configuration?: TemplateConfigurationDto;
  applicationStyles?: ApplicationStylesGetDto;
  useForAllLocations: boolean;
  useAllDisplayTypes: boolean;
  displayTypeIds?: number[];
}

export interface SystemTemplatePutDto {
  id: number;
  useOnCfs: boolean;
  useOnPos: boolean;
  displayTypeIds?: number[];
  useAllDisplayTypes: boolean;
  options: TemplateConfigurationOptionsDto;
}

export interface LocationTemplateGetDto {
  locationId: number;
  enabled: boolean;
  canEnable: boolean;
}

export interface TemplateInteractionRedirectConfigModel {
  redirectPageUrl?: string;
  timeoutInSeconds: number;
}

export interface TemplateConfigurationDto {
  mediaContentId?: number;
  defaultParameters: KeyValueAsset;
  options?: TemplateConfigurationOptionsDto;
  triggers?: TemplateConfigurationTriggerDto[];
}

export interface TemplateConfigurationOptionsDto {
  defaultDestination?: TemplateDestinationDto;
  displayTime?: number;
  image: TemplateImageConfigurationDto;
  logo: TemplateLogoConfigurationDto;
}

export interface TemplateConfigurationTriggerDto {
  triggerType: TemplateConfigurationTriggerType;
  area: TemplateConfigurationTriggerAreaDto;
  action: TemplateConfigurationTriggerActionDto;
}

export interface TemplateConfigurationTriggerAreaDto {
  areaType: TemplateConfigurationTriggerAreaType;
}

export interface TemplateConfigurationTriggerActionDto {
  actionType: TemplateConfigurationTriggerActionType;
  destination: TemplateCustomDestinationDto;
}

export interface TemplateDestinationDto {
  destinationType: TemplateDestinationType;
  separateUrl?: boolean;
  url?: string;
  widgetType?: number;
  externalCouponId?: string;
}

export interface TemplateCustomDestinationDto {
  useDefaultDestination: boolean;
  customDestination?: TemplateDestinationDto;
}

export interface TemplateImageConfigurationDto {
  sourceType: TemplateImageSourceType;
}

export interface TemplateLogoConfigurationDto {
  sourceType: TemplateLogoSourceType;
  brandId?: number;
}

export interface TemplateAssignedAccountsDto {
  useForAllAccounts: boolean; // Is all Accounts assigned. If true - 'Accounts' property is ignored.
  accounts: TemplateAccountLocationsDto[];
}

export interface TemplateAccountLocationsDto {
  accountId: number;
  useForAllLocations: boolean; // All Account Locations. If 'true' - 'LocationIds' property is ignored
  locationIds?: number[];
}

export interface TemplateValidationResultDto {
  templateId: number;
  isGlobal: boolean;
  errors: TemplateValidationErrorDto[];
}

export interface TemplateValidationErrorDto {
  locationId?: number;
  location: any; // todo: add Location model
  widgetId?: number;
  externalCouponId?: string;
  messageCode?: string;
}

export interface DisplayTypeDto {
  id: number;
  name?: string;
}

export enum DefaultTemplateType {
  None = 0,
  Cfs = 1,
  Print = 2,
  CfsAndPrint = 3
}

export enum TemplateDestinationType {
  Coupon = "Coupon",
  Url = "Url",
  Widget = "Widget"
}

export enum GlobalTemplateType {
  System = "System",
  Custom = "Custom",
  Global = "Global",
  CmcQrCode = "CmcQrCode"
}

export enum TemplateConfigurationTriggerAreaType {
  Any = "Any"
}

export enum TemplateConfigurationTriggerType {
  None = "None",
  Click = "Click"
}

export enum TemplateConfigurationTriggerActionType {
  IframeNavigation = "IframeNavigation"
}

export enum TemplateImageSourceType {
  None = "None",
  Default = "Default",
  Custom = "Custom"
}

export enum TemplateLogoSourceType {
  None = "None",
  Default = "Default",
  CustomBrand = "CustomBrand",
  Custom = "Custom"
}
