import { Directive, HostListener, Input } from "@angular/core";
import { isIframe } from "../utils";

@Directive({
  selector: "[libIframeButtonPostMessage]",
  standalone: true
})
export class IframeButtonPostMessageDirective {
  @Input() buttonId = "";

  @HostListener("click") onClick(): void {
    if (isIframe()) {
      this.sendPostMessage("buttonClick", this.buttonId);
    }
  }

  private sendPostMessage(eventName: string, buttonId: string): void {
    const targetWindow = window.top;

    if (targetWindow) {
      const data = { type: eventName, buttonId };

      targetWindow.postMessage(data, "*");
    }
  }
}
