<div class="form-group" [class.form-switch-wrapper]="isSlider" *ngIf="control">
  <label *ngIf="label" class="col-form-label"> {{ label }}<span *ngIf="isRequired" class="text-danger"> *</span></label>
  <div
    class="form-check mt-2 {{ size ? 'form-switch-' + size : '' }}"
    [class.form-switch]="isSlider"
  >
    <input
      class="form-check-input cursor-pointer"
      type="checkbox"
      id="label-checkbox-{{inlineLabel}}"
      [formControl]="control"
      (change)="onChange()"
    />
    <label for="label-checkbox-{{inlineLabel}}" class="form-check-label" *ngIf="inlineLabel">
      {{ inlineLabel }}
      <span
        *ngIf="tooltip"
        #tt="ngbTooltip"
        container="body"
        class="form-switch-tooltip font-size-18 d-inline-flex cursor-pointer"
        [tooltipClass]="tooltipSize === 'wide' ? 'ngb-tooltip-wide-m' : ''"
        [ngbTooltip]="tooltip"
        [triggers]="tooltipTriggers"
        (mouseout)="tt.close()"
        (click)="$event.preventDefault()"
      >
        <i class="bx bx-question-mark mb-0 text-info"></i>
      </span>
    </label>

    <ng-content></ng-content>
  </div>
</div>
