import { NewRegistrationStatus } from "./new-registration-status";
import { NewRegistrationType } from "./new-registration-type";

export class CreateNewRegistrationModel {
  status: NewRegistrationStatus;
  registrationType: NewRegistrationType;
  subscriptionPlanId: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  state: string;
  address: string;
  city: string;
  zip: string;
  country: number;
  companyName: string;
  website?: string;
  sendToEmail?: string;
}
