import { OverlayModule } from "@angular/cdk/overlay";
import { CommonModule, DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { TranslocoRootModule } from "@app/core/services/transloco/transloco-root.module";
import {
  AccountLocationAssignerComponent,
  ApplicationStylesComponent,
  AutocompleteMultiSelectComponent,
  BaseGridComponent,
  BaseUploaderComponent,
  CouponCarouselComponent,
  CouponComponent,
  CustomFilterModalComponent,
  CustomImageUploaderComponent,
  CustomRangeDatepickerComponent,
  CustomVideoUploaderComponent,
  DateNavFiltersComponent,
  FromToDatePickerComponent,
  GridCellStatusComponent,
  GridValidationErrorsTooltipComponent,
  ImageUploadFormControlComponent,
  LocationAssignmentModalComponent,
  LocationAutocompleteComponent,
  LocationAutocompleteSelectComponent,
  LocationFilterComponent,
  MultiAutocompleteComponent,
  MultiColumnCarouselComponent,
  MultiFilterComponent,
  MultilocationSelectionComponent,
  PaginationSizeComponent,
  PincodeManagementComponent,
  QrCodeDesignPreviewComponent,
  QrCodeDesignSettingsComponent,
  TableCellActionsMenuComponent,
  TableCellCustomValueComponent,
  TableNotFoundContentComponent,
  TileComponent,
  UpdateDevicePageComponent
} from "@app/shared/components";
import { CouponPreviewModalComponent } from "@app/shared/components/coupon-preview-modal";
import { WarningComponent } from "@app/shared/components/warning";
import { ColorPickerComponent, CustomColorPickerDirective } from "@app/shared/form-controls/colors-form-control";
import { MaskedDigitalHighlightPipe } from "@app/shared/pipes/masked-digital-highlight.pipe";
import { TemplateModule } from "@app/template/template.module";
import {
  NgbAlertModule,
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbNavModule,
  NgbPaginationModule,
  NgbPopoverModule,
  NgbProgressbarModule,
  NgbTooltipModule,
  NgbTypeaheadModule
} from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { ColorPickerModule } from "ngx-color-picker";
import { NgxMaskDirective, provideNgxMask } from "ngx-mask";
import { SimplebarAngularModule } from "simplebar-angular";
import { FilterSelectMultipleComponent, ToggleGroupWithLabelComponent, WizardComponent } from "ui-kit";
import { LocationFormComponent } from "../pages/components/widget-settings/location-form/location-form.component";
import { AccessDeniedComponent } from "./access-denied/access-denied.component";
import { CouponInputComponent } from "./components/coupon-input/coupon-input.component";
import { CouponSelectModalComponent } from "./components/coupon-select-modal/coupon-select-modal.component";
import { SelectMultipleComponent } from "./components/select/select-multiple/select-multiple.component";
import { DirectivesModule } from "./directives/directives.module";
import { FileUploaderModule } from "./file-uploader/file-uploader.module";
import { BrandFormControlComponent } from "./form-controls/brand-form-control/brand-form-control.component";
import { ColorsFormControlComponent } from "./form-controls/colors-form-control/colors-form-control.component";
import { DeliveryPlatformAccountComponent } from "./form-controls/delivery-platform-account/delivery-platform-account.component";
import { EmailSenderFormControlComponent } from "./form-controls/email-sender-form-control/email-sender-form-control.component";
import { EmbeddedVideoFormControlComponent } from "./form-controls/embedded-video-form-control/embedded-video-form-control.component";
import { SmsSenderFormControlComponent } from "./form-controls/sms-sender-form-control/sms-sender-form-control.component";
import { SocialLinksFormControlComponent } from "./form-controls/social-links-form-control/social-links-form-control.component";
import { SafeUrlFormControlComponent, UrlControlComponent, UrlFormControlComponent } from "./form-controls/url-controls";
import { WidgetsFormControlComponent } from "./form-controls/widgets-form-control/widgets-form-control.component";
import { ZipFormControlComponent } from "./form-controls/zip-form-control/zip-form-control.component";
import { ConfirmationModalComponent } from "./modals";
import { IframeBrowserModalComponent } from "./modals/iframe-browser-modal/iframe-browser-modal.component";
import { GridNotFoundComponent } from "./not-found/grid-not-found/grid-not-found.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { EllipsisPipe } from "./pipes/ellipsis.pipe";
import { EnumToArrayPipe } from "./pipes/enum-to-array.pipe";
import { HighlightPipe } from "./pipes/highlight.pipe";
import { HtmlEntitiesHandlerPipe } from "./pipes/html-entities-handler.pipe";
import { LocationInfoPipe } from "./pipes/location-info.pipe";
import { MatchBitMaskPipe } from "./pipes/match-bit-mask.pipe";
import { PhoneMaskPipe } from "./pipes/phone-mask.pipe";
import { PhoneHighlightPipe } from "./pipes/phoneHighlight.pipe";
import { SplitCamelCasePipe } from "./pipes/split-camel-case.pipe";
import { UIModule } from "./ui/ui.module";
import { VerificationComponent } from "./verification/verification.component";
import { WidgetModule } from "./widget/widget.module";
import { CfsTemplatePreviewComponent } from "clearline-common";

@NgModule({
  declarations: [
    VerificationComponent,
    LocationFormComponent,
    AccessDeniedComponent,
    EnumToArrayPipe,
    UrlFormControlComponent,
    SafeUrlFormControlComponent,
    BrandFormControlComponent,
    WidgetsFormControlComponent,
    SocialLinksFormControlComponent,
    MatchBitMaskPipe,
    HighlightPipe,
    PhoneHighlightPipe,
    PhoneMaskPipe,
    SplitCamelCasePipe,
    MaskedDigitalHighlightPipe,
    EllipsisPipe,
    SmsSenderFormControlComponent,
    EmailSenderFormControlComponent,
    ApplicationStylesComponent,
    ColorsFormControlComponent,
    ConfirmationModalComponent,
    TileComponent,
    NotFoundComponent,
    EmbeddedVideoFormControlComponent,
    IframeBrowserModalComponent,
    MultilocationSelectionComponent,
    LocationFilterComponent,
    LocationAutocompleteComponent,
    UrlControlComponent,
    CustomImageUploaderComponent,
    CustomVideoUploaderComponent,
    ZipFormControlComponent,
    PaginationSizeComponent,
    PincodeManagementComponent,
    QrCodeDesignPreviewComponent,
    QrCodeDesignSettingsComponent,
    CustomRangeDatepickerComponent,
    CouponCarouselComponent,
    CouponComponent,
    CouponPreviewModalComponent,
    HtmlEntitiesHandlerPipe,
    UpdateDevicePageComponent,
    CustomFilterModalComponent,
    DateNavFiltersComponent,
    FromToDatePickerComponent,
    MultiColumnCarouselComponent,
    MultiAutocompleteComponent,
    AutocompleteMultiSelectComponent,
    MultiFilterComponent,
    CouponSelectModalComponent,
    CouponInputComponent,
    ImageUploadFormControlComponent,
    BaseGridComponent,
    TableCellCustomValueComponent,
    TableCellActionsMenuComponent,
    TableNotFoundContentComponent,
    GridCellStatusComponent,
    GridNotFoundComponent,
    GridValidationErrorsTooltipComponent,
    WarningComponent,
    CustomColorPickerDirective,
    ColorPickerComponent,
    DeliveryPlatformAccountComponent,
    BaseUploaderComponent
  ],
  imports: [
    CommonModule,
    UIModule,
    WidgetModule,
    NgbPopoverModule,
    NgbTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbNavModule,
    RouterModule,
    DirectivesModule,
    ColorPickerModule,
    NgbAlertModule,
    TranslocoRootModule,
    NgbPaginationModule,
    NgbTypeaheadModule,
    NgbCollapseModule,
    NgbDropdownModule,
    NgxMaskDirective,
    SimplebarAngularModule,
    NgbDatepickerModule,
    TemplateModule,
    LocationInfoPipe,
    AccountLocationAssignerComponent,
    FileUploaderModule,
    FilterSelectMultipleComponent,
    LocationAssignmentModalComponent,
    LocationAutocompleteSelectComponent,
    ToggleGroupWithLabelComponent,
    SelectMultipleComponent,
    WizardComponent,
    OverlayModule,
    CfsTemplatePreviewComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UIModule,
    NgbPopoverModule,
    NgbTooltipModule,
    NgSelectModule,
    NgbNavModule,
    NgbPaginationModule,
    NgbProgressbarModule,
    TemplateModule,
    // Modules
    DirectivesModule,
    FileUploaderModule,
    SimplebarAngularModule,
    VerificationComponent,
    // Pipes
    EnumToArrayPipe,
    MatchBitMaskPipe,
    HighlightPipe,
    PhoneHighlightPipe,
    PhoneMaskPipe,
    SplitCamelCasePipe,
    MaskedDigitalHighlightPipe,
    EllipsisPipe,
    LocationInfoPipe,
    HtmlEntitiesHandlerPipe,
    // Components
    CustomImageUploaderComponent,
    CustomVideoUploaderComponent,
    ZipFormControlComponent,
    UrlFormControlComponent,
    SafeUrlFormControlComponent,
    BrandFormControlComponent,
    WidgetsFormControlComponent,
    SocialLinksFormControlComponent,
    LocationFormComponent,
    ColorsFormControlComponent,
    ApplicationStylesComponent,
    EmailSenderFormControlComponent,
    SmsSenderFormControlComponent,
    ConfirmationModalComponent,
    TranslocoRootModule,
    EmbeddedVideoFormControlComponent,
    IframeBrowserModalComponent,
    MultilocationSelectionComponent,
    LocationAutocompleteComponent,
    LocationFilterComponent,
    LocationAutocompleteSelectComponent,
    LocationAssignmentModalComponent,
    UrlControlComponent,
    NotFoundComponent,
    PaginationSizeComponent,
    PincodeManagementComponent,
    QrCodeDesignPreviewComponent,
    QrCodeDesignSettingsComponent,
    CustomRangeDatepickerComponent,
    CouponCarouselComponent,
    CouponSelectModalComponent,
    CouponInputComponent,
    HtmlEntitiesHandlerPipe,
    UpdateDevicePageComponent,
    DateNavFiltersComponent,
    FromToDatePickerComponent,
    MultiColumnCarouselComponent,
    MultiAutocompleteComponent,
    AutocompleteMultiSelectComponent,
    MultiFilterComponent,
    FilterSelectMultipleComponent,
    ToggleGroupWithLabelComponent,
    SelectMultipleComponent,
    ImageUploadFormControlComponent,
    BaseGridComponent,
    GridCellStatusComponent,
    GridNotFoundComponent,
    GridValidationErrorsTooltipComponent,
    TableCellActionsMenuComponent,
    TableNotFoundContentComponent,
    WizardComponent,
    WarningComponent,
    ColorPickerComponent,
    CustomColorPickerDirective,
    DeliveryPlatformAccountComponent,
    BaseUploaderComponent
  ],
  providers: [DatePipe, provideNgxMask()]
})
export class SharedModule {}
