import { Injectable } from "@angular/core";
import { DataService } from "@app/core";
import {
  CreateManagerAccountResponseDto,
  QuickSetupChecklist,
  QuickSetupConfiguration,
  SendEmailWithRegistrationUrlRequestDto
} from "@app/models";
import { Observable } from "rxjs";
import { CompletequickSetupModel } from "../pages/quick-setup/models";

@Injectable({ providedIn: "root" })
export class QuickSetupService {
  private serviceUrl = "QuickSetup";

  constructor(private http: DataService) {}

  createAccount(quickSetupModel: CompletequickSetupModel): Observable<CreateManagerAccountResponseDto> {
    return this.http.post(`${this.serviceUrl}/createAccount`, quickSetupModel);
  }

  getChecklist(locationId: number): Observable<QuickSetupChecklist> {
    return this.http.get(`${this.serviceUrl}/getchecklist/${locationId}`);
  }

  updateChecklist(locationId: number, checklist: QuickSetupChecklist): Observable<QuickSetupChecklist> {
    return this.http.post(`${this.serviceUrl}/updatechecklist/${locationId}`, checklist);
  }

  doneQuickSetup(): Observable<boolean> {
    return this.http.post(`${this.serviceUrl}/doneQuickSetup`);
  }

  getConfiguration(): Observable<QuickSetupConfiguration> {
    return this.http.get(`${this.serviceUrl}/configuration`);
  }

  resetAccount(): Observable<boolean> {
    return this.http.post(`${this.serviceUrl}/resetAccount`);
  }

  sendRegistrationUrl(userId: number, registrationUrl: string): Observable<null> {
    const data: SendEmailWithRegistrationUrlRequestDto = { userId, registrationUrl };
    return this.http.post(`${this.serviceUrl}/send/email/registration-url`, data);
  }
}
