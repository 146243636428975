import { PlatformAccountBase } from "./platform-account-base";

export class TwoRewardAccount extends PlatformAccountBase {
  public id: number;
  public username: string;
  public password: string;
  public expiryDate: Date;
  public email: string;
  public firstName: string;
  public lastName: string;
  public companyName: string;
  public accessSettings = true;
  public directoryId: string;
}
