import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CouponInfoQr } from "@app/models";
import { CouponConfig } from "@app/shared/components";

@Component({
  selector: "app-coupon-carousel",
  templateUrl: "./coupon-carousel.component.html",
  styleUrls: ["./coupon-carousel.component.scss"]
})
export class CouponCarouselComponent {
  @Input() loading = true;
  @Input() withPreview = true;
  @Input() withNothingFoundText = false;
  @Input() viewType: "large" | "medium" | "small" = "large";
  @Input() selectionType: "single" | "multiple" = "multiple";
  @Input() couponSizeType: "standard" | "small" = "standard";
  @Input() centerSpinner = false;
  @Input() couponConfig?: CouponConfig;

  @Input() selectedCoupon: CouponInfoQr;
  @Input() coupons: Array<CouponInfoQr> = [];
  @Input() previewMode = false;
  @Input() rows: number;
  @Input() disabledNotActive: boolean;
  @Input() displayLoading = true;
  @Input() removeSelected = false;
  @Input() responsive = false;

  @Output() selectCoupon = new EventEmitter<CouponInfoQr>();
  @Output() qrCodeModalClosed = new EventEmitter<null>();

  scrollbarOptions: any = {
    forceVisible: false,
    autoHide: false
  };

  clickSelectCoupon(coupon: CouponInfoQr): void {
    this.selectCoupon.emit(coupon);
  }

  onCloseQrCodeModal(): void {
    this.qrCodeModalClosed.emit(null);
  }
}
