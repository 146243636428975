<div class="reward-card-container">
  <div
    *ngFor="let reward of rewards; let i = index"
    class="reward-card"
    [class.enabled]="!isReadOnly"
    [libClickPrevent]="!!reward.pending"
    (allowedClick)="rewardClick.emit(reward)"
  >
    <img [src]="reward.imageUrl">
    <span class="reward-card-sub-title">{{ reward.subTitle}}</span>
    <span class="reward-card-title">{{ reward.title }}</span>
  </div>
</div>
