<div>
  <h5 *ngIf="title" class="mb-3">{{ title }}</h5>
  <label *ngIf="label" class="col-form-label">
    {{ label }}<span *ngIf="isRequired" class="text-danger"> *</span>
  </label>
  <div
    class="d-flex"
    [class.vertical-orientation]="orientation === 'vertical'"
    [class.vertical-orientation-sm]="orientationSmallScreens === 'vertical'"
    *ngIf="items.length">
    <div class="{{ itemClassList }}" *ngFor="let item of items; let i = index">
      <input
        type="radio"
        class="form-check-input cursor-pointer"
        id="{{ groupName + i }}"
        [ngClass]="item.disabled || isDisabled ? 'disabled' : ''"
        [name]="groupName"
        [checked]="item.value === value"
        [value]="item.value"
        [disabled]="isDisabled"
        (change)="onModelChange(item.value)"
      />
      <label class="form-check-label" for="{{ groupName + i }}">
        {{ isTranslatedLabels ? item.label : (item.label | transloco) }}
      </label>
    </div>
  </div>
  <ng-content></ng-content>
</div>
