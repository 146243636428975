import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validators
} from "@angular/forms";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { Location } from "@app/models";
import { TranslocoModule } from "@ngneat/transloco";
import { CustomerDistributionAccountPostDto } from "clearline-api";
import { BehaviorSubject } from "rxjs";
import { auditTime, filter, startWith } from "rxjs/operators";
import { cloneFlatArray, distinctUntilPlainObjectChanged, UiKitModule } from "ui-kit";
import { LocationAssignmentModalComponent, LocationAssignmentModalOptions } from "../";
import { Unsubscriber } from "../base/unsubscriber.component";
import { SelectMultipleComponent } from "../select/select-multiple/select-multiple.component";

export type AccountAssignerResource = {} & { id: number; companyName: string };

export interface AccountLocationAssignerResource {
  accounts: AccountAssignerResource[];
  locations: Location[];
  assignedItems: AccountLocationAssignedItem[];
  isAccountAdminRole?: boolean;
}

export interface AccountLocationAssignedData {
  useForAllAccounts: boolean;
  assignedItems: AccountLocationAssignedItem[];
}
export interface AccountLocationAssignedItem extends CustomerDistributionAccountPostDto {
  companyName: string;
}

export interface AccountItem {
  companyName: string;
  accountId: number;
}

@Component({
  selector: "app-account-location-assigner",
  templateUrl: "./account-location-assigner.component.html",
  styleUrls: ["./account-location-assigner.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslocoModule,
    MatDialogModule,
    SelectMultipleComponent,
    UiKitModule,
    LocationAssignmentModalComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AccountLocationAssignerComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AccountLocationAssignerComponent),
      multi: true
    }
  ]
})
export class AccountLocationAssignerComponent extends Unsubscriber implements OnInit {
  @Input() hasUseForAllLocations = false;
  @Input() set resource(value: AccountLocationAssignerResource) {
    if (value) {
      this.resources = value;
    }
  }

  protected resources: AccountLocationAssignerResource;

  protected form = new FormGroup({
    accountIds: new FormControl<number[]>(null, [Validators.required]),
    locationIds: new FormControl<number[]>(null, [Validators.required]),
    useForAllAccounts: new FormControl<boolean>(false, [Validators.required])
  });

  protected accounts: AccountItem[] = [];
  /** The list of items to reflect Accounts with assigned Locations to it */
  protected assignedItems$ = new BehaviorSubject<AccountLocationAssignedItem[]>([]);

  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};

  get useForAllAccounts(): boolean {
    return this.form.controls.useForAllAccounts.value;
  }

  constructor(private dialog: MatDialog) {
    super();
  }

  ngOnInit(): void {
    this.sub = this.form.controls.accountIds.valueChanges
      .pipe(
        startWith(this.form.controls.accountIds.value),
        distinctUntilPlainObjectChanged,
        filter(() => !!this.resources?.accounts?.length)
      )
      .subscribe((accountIds) => {
        const selectedAccounts = this.resources.accounts.filter((account) => (accountIds || []).includes(account.id));
        const assignedItems: AccountLocationAssignedItem[] = selectedAccounts.map((account: AccountAssignerResource) => {
          const { id, companyName } = account;
          const existedAccount = this.assignedItems$.value.find((x) => x.accountId === id);

          if (existedAccount) {
            return existedAccount;
          }

          const existedAssignment = this.resources.assignedItems.find((x) => x.accountId === id);
          const locationIds =
            existedAssignment?.locationIds ||
            (this.hasUseForAllLocations ? [] : this.resources.locations.filter((l) => l.accountId === id).map((l) => l.id));

          return {
            accountId: id,
            companyName,
            locationIds,
            useForAllLocations: existedAssignment?.useForAllLocations ?? this.hasUseForAllLocations
          };
        });

        if (!this.resources.isAccountAdminRole && assignedItems.length === this.resources.accounts.length) {
          this.updateUseForAllAccounts(true);
        }

        if (accountIds?.length) {
          const locationsIds = assignedItems.reduce((acc, item) => [...acc, ...item.locationIds], []);

          this.form.controls.locationIds.setValue(locationsIds, { emitEvent: false });
        }

        this.updateAssignedItems(assignedItems);
      });

    this.sub = this.form.valueChanges.pipe(distinctUntilPlainObjectChanged, auditTime(1)).subscribe(() => {
      this.onTouched();
      this.onModelChange();
    });
  }

  writeValue(value: AccountLocationAssignedData): void {
    if (value) {
      const assignedItems = value.assignedItems || [];
      const accountIds = value.useForAllAccounts ? [] : assignedItems.map((item) => item.accountId);
      const locationIds = assignedItems.reduce((acc, item) => [...acc, ...item.locationIds], []);

      this.form.patchValue(
        {
          accountIds,
          locationIds,
          useForAllAccounts: value.useForAllAccounts
        },
        { emitEvent: false }
      );
    } else {
      this.form.reset();
    }

    this.updateAssignedItems(value?.assignedItems || []);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable({ emitEvent: false });
    } else {
      this.form.enable({ emitEvent: false });
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const assignedItems = control.value?.assignedItems || [];

    if (assignedItems.length) {
      const locationsCount = assignedItems.reduce((acc, item) => acc + item.locationIds.length, 0);

      return locationsCount > 0 ? null : { locationIdsEmpty: true };
    }

    return null;
  }

  onAllSelectedChange(value: boolean): void {
    this.updateUseForAllAccounts(value);
  }

  openLocationModal(selectedAccountId: number): void {
    const assignedItems = cloneFlatArray(this.assignedItems$.value);
    const data: LocationAssignmentModalOptions = {
      assignedItems,
      hasUseForAllLocations: this.hasUseForAllLocations,
      locations: cloneFlatArray(this.resources?.locations),
      selectedAccountId: selectedAccountId || this.assignedItems$.value[0].accountId
    };

    this.dialog
      .open(LocationAssignmentModalComponent, {
        data,
        panelClass: "modal-standard-s",
        autoFocus: false,
        disableClose: true
      })
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe((items: AccountLocationAssignedItem[]) => {
        this.updateAssignedItems(items);
        this.updateUseForAllAccounts(items.length === 0);
        this.form.patchValue(
          {
            accountIds: items.map((item) => item.accountId),
            locationIds: items.reduce((acc, item) => [...acc, ...item.locationIds], [])
          },
          { emitEvent: true }
        );
      });
  }

  private onModelChange(): void {
    this.onChange(<AccountLocationAssignedData>{
      useForAllAccounts: this.useForAllAccounts,
      assignedItems: this.useForAllAccounts ? null : this.assignedItems$.value
    });
  }

  private updateAssignedItems(value: AccountLocationAssignedItem[]): void {
    const items = cloneFlatArray(value);

    this.assignedItems$.next(items);
  }

  private updateUseForAllAccounts(value: boolean): void {
    this.form.controls.useForAllAccounts.setValue(!!value, { emitEvent: true });
  }
}
