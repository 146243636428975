import { LoyaltyProgramSettingsGetDto } from "..";

export enum PosSystems {
  Clover = 1,
  Cova = 2,
  Surge = 3,
  Pax = 4
}
export enum PosSystemNames {
  Clover = "clover",
  Cova = "cova",
  Surge = "surge",
  Pax = "pax"
}
export class AccountPosSystemSettingsDto {
  public companyPosId: string;
  public posSystemId: number;
  public configurationJson: AccountPosSystemSettingsConfiguration | string;
}
export class POSSystemInfo {
  public id: number;
  public name: string;
  public code: string;
  public configuration: PosSystemConfigurationDto;
}
export class AccountPosSystemSettings {
  public companyPosId: string;
  public posSystemId: PosSystems;
  public configuration: AccountPosSystemSettingsConfiguration;

  public static fromDto(dto: AccountPosSystemSettingsDto) {
    const configurationJson: string = dto.configurationJson === "null" ? "{}" : (dto.configurationJson as string);
    const model: AccountPosSystemSettings = { ...dto, configuration: JSON.parse(configurationJson) };
    model.configuration.posSystemId = dto.posSystemId;
    return model;
  }
}
export interface PosSystemSettingsBase {
  cfs?: PosCfsSettingsDto;
  posSystemId: PosSystems;
  isTransactionsEnabled: boolean;
  isPosRulesEnabled: boolean;
  isProductsImportEnabled: boolean;
  isReceiptPromotionEnabled: boolean;
  isCouponsEnabled: boolean;
  isUserDataSendingToPosEnabled: boolean;
  loyaltyAutomaticCalculationSettings: LoyaltyProgramSettingsGetDto;
}
export interface CovaPosSystemSettings extends PosSystemSettingsBase {
  clientId: string;
  clientSecret: string;
  userName: string;
  password: string;
}
export interface CloverPosSystemSettings extends PosSystemSettingsBase {}
export interface SurgePosSystemSettings extends PosSystemSettingsBase {}
export interface PaxPosSystemSettings extends PosSystemSettingsBase {}
export type AccountPosSystemSettingsConfiguration =
  | CloverPosSystemSettings
  | CovaPosSystemSettings
  | SurgePosSystemSettings
  | PaxPosSystemSettings;

export enum ReceiptPromotionDestinationType {
  Widget = "Widget",
  Coupon = "Coupon",
  CustomUrl = "CustomUrl"
}

export interface PosSystemReceiptPromotionConfigurationDto {
  destinationType: ReceiptPromotionDestinationType;
  widgetId?: number;
  couponId?: string;
}

export class PosSystemConfigurationDto {
  public feature: PosSystemFeatureConfigurationDto;
}

export class PosSystemFeatureConfigurationDto {
  public isProductsImportEnabled: boolean;
  public isDeliveryChannelsVisible: boolean;
}

export interface PosCfsSettingsDto {
  isCheckInMenuAvailable: boolean;
}
