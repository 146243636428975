import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "lib-loyalty-program-balance",
  templateUrl: "./loyalty-program-balance.component.html",
  styleUrls: ["./loyalty-program-balance.component.scss"],
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoyaltyProgramBalanceComponent {
  @Input() value = 0;
  @Input() label = "";
  @Input() size: "S" | "L" = "L";
}
