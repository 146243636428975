import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output } from "@angular/core";
import {
  AbstractControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators
} from "@angular/forms";
import { Patterns } from "@app/shared/validators";
import { debounceTime, tap } from "rxjs/operators";
import { SendPaymentLinkWidgetSettings } from "../send-payment-link-widget-settings/send-payment-link-widget-settings";
import { WidgetSettingsBaseComponent } from "../widget-settings-base";
import { AuthService } from "@app/core";

@Component({
  selector: "app-send-payment-link-widget-settings",
  templateUrl: "./send-payment-link-widget-settings.component.html",
  styleUrls: ["./send-payment-link-widget-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: SendPaymentLinkWidgetSettingsComponent
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SendPaymentLinkWidgetSettingsComponent),
      multi: true
    }
  ]
})
export class SendPaymentLinkWidgetSettingsComponent extends WidgetSettingsBaseComponent implements OnInit {
  private _widget: SendPaymentLinkWidgetSettings;
  public validators: Array<ValidatorFn> = [Validators.required, Validators.pattern(Patterns.url), Validators.maxLength(2000)];
  @Output() widgetSettingsReady = new EventEmitter();
  @Input() set widgetSettingsJson(value: string) {
    if (value) this.widgetSetting = JSON.parse(value) as SendPaymentLinkWidgetSettings;
  }

  public set widgetSetting(value: SendPaymentLinkWidgetSettings) {
    if (value && !this._widget) {
      setTimeout(() => {
        this.form.controls.paymentLink1.setValue(value.paymentLink1);
        this.form.controls.paymentLink2.setValue(value.paymentLink2);
        switchOneOfRequiredUrlControls(this.form.controls.paymentLink1, this.form.controls.paymentLink2);
        this.changeDetectorRef.detectChanges();
      });
    }
    this.onChange(value);
    this.changeDetectorRef.markForCheck();
  }

  constructor(private changeDetectorRef: ChangeDetectorRef, public authService: AuthService) {
    super(authService);
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      paymentLink1: new UntypedFormControl("", [Validators.required, Validators.pattern(Patterns.url), Validators.maxLength(2000)]),
      paymentLink2: new UntypedFormControl("", [Validators.required, Validators.pattern(Patterns.url), Validators.maxLength(2000)])
    });

    this.initForm();
    this.widgetSettingsReady.emit();
  }
  private initForm() {
    this.form.valueChanges
      .pipe(
        debounceTime(300),
        tap(() => {
          switchOneOfRequiredUrlControls(this.form.controls.paymentLink1, this.form.controls.paymentLink2);
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe();
  }
}
export function switchOneOfRequiredUrlControls(control1: AbstractControl, control2: AbstractControl) {
  if (control1.valid && control1.value && !control2.valid) {
    control2.setValidators(null);
    control2.setValidators([Validators.pattern(Patterns.url), Validators.maxLength(2000)]);
    control2.updateValueAndValidity();
  }

  if (control2.valid && control2.value && !control1.valid) {
    control1.setValidators(null);
    control1.setValidators([Validators.pattern(Patterns.url), Validators.maxLength(2000)]);
    control1.updateValueAndValidity();
  }

  if (!control1.value && !control2.value && (control1.valid || control2.valid)) {
    control1.setValidators([Validators.required, Validators.pattern(Patterns.url), Validators.maxLength(2000)]);
    control2.setValidators([Validators.required, Validators.pattern(Patterns.url), Validators.maxLength(2000)]);
    control1.markAsTouched();
    control2.markAsTouched();
    control1.updateValueAndValidity();
    control2.updateValueAndValidity();
  }
}
