import { DeliveryProviderGetDto, DeliveryProviderType } from "clearline-api";
import { DeliveryPlatformAccount, EmailSenderOption, SenderOptionBase, SMSSenderOption } from ".";
import { AllowedDeliveryChannel } from "./campaign";

export class SenderOption {
  id: number;
  deliveryProviderId: DeliveryProviderType;
  channelType: AllowedDeliveryChannel;
  value: SenderOptionBase | EmailSenderOption | SMSSenderOption | string;
  isActive: boolean;
  isApproved: boolean;
}
export class SenderOptionModel<T extends SMSSenderOption | EmailSenderOption | string> extends SenderOption {
  value: T;
}

export interface DeliveryProvider extends DeliveryProviderGetDto {
  platformAccount?: DeliveryPlatformAccount;
  senderOptions?: SenderOption[];
}
