import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { AuthService } from "@app/core/services/auth.service";
import { AccountService } from "@app/core/services/account.service";
import { CompanyDataService } from "@app/services/company-data.service";
import { ProfileModel } from "@app/core/models";
import { Subscription, SubscriptionPlan } from "@app/models";
import { AppConfigService } from "@app/core";
import { CurrentSubscriptionPlanService, SubscriptionPlanService } from "@app/services";
import { RoleTypes } from "core-kit";
import { take } from "rxjs/operators";

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"]
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {
  user: ProfileModel;
  subscription: Subscription = new Subscription();
  element;
  configData;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  name: string;
  smallLogoUrl: string;
  fullLogoUrl: string;

  profileUrl: string;
  plan: SubscriptionPlan;
  constructor(
    @Inject(DOCUMENT) private document: any,
    private authService: AuthService,
    private currentSubscriptionPlanService: CurrentSubscriptionPlanService,
    private subscriptionPlanService: SubscriptionPlanService,
    private accountService: AccountService,
    private confService: AppConfigService,
    private companyDataService: CompanyDataService
  ) {}

  listLang = [
    { text: "English", flag: "assets/images/flags/us.jpg", lang: "en" },
    { text: "Spanish", flag: "assets/images/flags/spain.jpg", lang: "es" },
    { text: "German", flag: "assets/images/flags/germany.jpg", lang: "de" },
    { text: "Italian", flag: "assets/images/flags/italy.jpg", lang: "it" },
    { text: "Russian", flag: "assets/images/flags/russia.jpg", lang: "ru" }
  ];

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    this.openMobileMenu = false;
    this.element = document.documentElement;

    this.configData = {
      suppressScrollX: true,
      wheelSpeed: 0.3
    };

    this.user = this.accountService.user;
    const config = this.confService.appData;
    this.openProfileMenu();
    if (config && config.name) {
      this.name = config.name;
    } else {
      this.name = "clearline";
    }
    if (config && config.smallLogoUrl) {
      this.smallLogoUrl = config.smallLogoUrl;
    } else {
      this.smallLogoUrl = `assets/images/logo/small-${this.name}-menu-logo.svg`;
    }
    if (config && config.fullLogoUrl) {
      this.fullLogoUrl = config.fullLogoUrl;
    } else {
      this.fullLogoUrl = `assets/images/logo/full-${this.name}-menu-logo.svg`;
    }

    console.log("project-name", config.name);

    const identityUrl = config.identityUrl;
    const returnUrl = config.authConfig.redirectUrl;
    this.profileUrl = `${identityUrl}?returnUrl=${returnUrl}`;
  }

  get isAdmin() {
    return this.accountService && this.authService.requredMinimumRole(RoleTypes.PartnerAdmin);
  }

  get isManager() {
    return this.accountService && this.accountService.user?.role === RoleTypes.Manager;
  }

  get isLocationManager() {
    return this.accountService && this.accountService.user?.role === RoleTypes.LocationManager;
  }

  get isSales() {
    return this.accountService && this.accountService.user?.role === RoleTypes.Sales;
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    this.authService.logout();
  }

  get selfServicePortalUrl() {
    return this.confService.appData.selfServicePortalUrl;
  }

  get marketingCenterUrl() {
    return this.confService.appData.marketingCenterUrl;
  }

  get periodEndDate() {
    const endDate = new Date(this.subscription.endDate);
    const dd = String(endDate.getDate()).padStart(2, "0");
    const mm = String(endDate.getMonth() + 1).padStart(2, "0");
    const yyyy = endDate.getFullYear();
    return mm + "/" + dd + "/" + yyyy;
  }

  get periodEndDays() {
    const endDate = new Date(this.subscription.endDate);
    const today = new Date();
    const difference = endDate.getTime() - today.getTime();
    const days = Math.ceil(difference / (1000 * 3600 * 24));
    return days;
  }
  get hasCmc() {
    const hasCmc = this.plan?.options?.hasCmc;
    return hasCmc;
  }
  openProfileMenu() {
    if (!this.accountService.isUserInRole(RoleTypes.Admin) && this.accountService.user?.doneQuickSetup) {
      this.subscriptionPlanService
        .getLocationPlan(this.accountService.user?.locationId)
        .pipe(take(1))
        .subscribe((plan) => (this.plan = plan));
      this.companyDataService
        .getSubscription()
        .pipe(take(1))
        .subscribe((subscription: Subscription) => {
          this.subscription = subscription;
          this.currentSubscriptionPlanService.setSubscription(subscription);
        });
    }
  }

  goToProfile() {
    window.location.href = this.profileUrl;
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle("fullscreen-enable");
    if (!document.fullscreenElement && !this.element.mozFullScreenElement && !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }
}
