import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslocoRootModule } from "@app/core/services/transloco/transloco-root.module";
import { DirectivesModule } from "@app/shared/directives/directives.module";
import { NgbDropdownModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { NgClickOutsideDirective } from "ng-click-outside2";
import { SimplebarAngularModule } from "simplebar-angular";
import { UIModule } from "../shared/ui/ui.module";
import { FooterComponent } from "./footer/footer.component";
import { HorizontalComponent } from "./horizontal/horizontal.component";
import { LayoutComponent } from "./layout.component";
import { RightsidebarComponent } from "./rightsidebar/rightsidebar.component";
import { SidebarHorizontalComponent } from "./sidebar-horizontal/sidebar-horizontal.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { TopbarComponent } from "./topbar/topbar.component";
import { VerticalComponent } from "./vertical/vertical.component";

@NgModule({
  declarations: [
    LayoutComponent,
    SidebarComponent,
    SidebarHorizontalComponent,
    TopbarComponent,
    FooterComponent,
    RightsidebarComponent,
    HorizontalComponent,
    VerticalComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbDropdownModule,
    NgClickOutsideDirective,
    UIModule,
    SimplebarAngularModule,
    TranslocoRootModule,
    NgbTooltipModule,
    DirectivesModule
  ],
  providers: []
})
export class LayoutsModule {}
