import { AllowedDeliveryChannel } from "./campaign";

/** @deprecated Use import from `clearline-common` */
export enum WidgetType {
  IssueCoupon = 1,
  ValidateCoupon = 2,
  EnrollLoyalty = 3,
  IssuePoints = 4,
  RequestReview = 5,
  SetReminder = 6,
  AddPromo = 7,
  DownloadMobileApp = 8,
  ConnectSocialMedia = 9,
  SendPaymentLink = 10,
  AnniversaryClub = 11,
  SendGiftCard = 12,
  SendSurvey = 13,
  Waitlist = 14,
  ReferralProgram = 15,
  ScheduleAppointment = 16,
  SmartPage = 34,
  SeeMenu = 37
}

/** @deprecated Use import from `clearline-common` */
export enum CampaignType {
  EmailConfirmation = 17,
  PendingAdminConfiguration = 18,
  ManagerAccountConfigured = 19,
  UserPendingConfiguration = 20,
  SendUserPassword = 21,
  ForgotPassword = 22,
  MonthlyLimitReached = 23,
  Request10DigitLocalNumber = 24,
  RequestDedicatedTollFreeNumber = 25,
  RequestExistingLandlineNumber = 26,
  NotifyAdminForWidgetConfig = 27,
  NotifyManagerForWidgetConfig = 28,
  NotifyAdminForLocationConfig = 29,
  SendNegativeFeedback = 31,
  SendSalesAgentCreated = 32,
  SendJobFailNotification = 33,
  NewAccountRegistration = 35,
  NewGenericAccountRegistration = 36
}

/** @deprecated Use import from `clearline-common` */
export enum WidgetCodes {
  EnrollLoyalty = "enrollLoyalty",
  RequestReview = "requestReview",
  IssueCoupon = "issueCoupon",
  IssuePoints = "issuePoints",
  ValidateCoupon = "validateCoupon",
  ScheduleAppointment = "scheduleAppointment",
  Waitlist = "waitlist",
  SendSurvey = "sendSurvey",
  DownloadMobileApp = "downloadMobileApp",
  SendGiftCard = "sendGiftCard",
  SetReminder = "setReminder",
  ConnectSocialMedia = "connectSocialMedia",
  AddPromo = "addPromo",
  SendPaymentLink = "sendPaymentLink",
  AnniversaryClub = "anniversaryClub",
  ReferralProgram = "referralProgram",
  FAQ = "faq",
  SmartPage = "smartPage",
  SeeMenu = "seeMenu"
}

export class FeatureModel {
  get code() {
    return this.id.toString();
  }
  public deliveryMethod?: AllowedDeliveryChannel;
  constructor(public id: WidgetType, public icon: string, public name: string = "") {}
}

export class WidgetModel {
  get code() {
    return this.id.toString();
  }

  constructor(public id: WidgetType, public name: string, public icon: string, public disabled = false) {}
}
