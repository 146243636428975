import { Injectable } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";
import { RoleTypes } from "core-kit";
import { from, Observable, of } from "rxjs";
import { AccountService } from "./account.service";

@Injectable()
export class AuthService {
  constructor(private oAuthService: OAuthService, private accountSvc: AccountService) {}
  public get isLoggedIn(): boolean {
    return this.accountSvc.isLoggedIn;
  }

  public logout(noRedirectDuringLogout = false) {
    this.oAuthService.logOut(noRedirectDuringLogout);
    this.accountSvc.refreshUser(null);
    localStorage.setItem("actAsUserId", "");
  }

  public login() {
    this.oAuthService.initImplicitFlow("");
  }

  public refreshToken(params = null): Observable<null> {
    if (!this.isLoggedIn) return of(null);
    return from(
      this.oAuthService.silentRefresh(params).then((r) => {
        this.accountSvc.refreshUser(this.accountSvc.user); // todo: check if it's needed
        return null;
      })
    );
  }

  public isUserInRole(role: string): boolean {
    return this.accountSvc.isLoggedIn && this.accountSvc.user.role === role;
  }

  public isUserInRoles(roles: string[]): boolean {
    return this.accountSvc.isLoggedIn && roles.includes(this.accountSvc.user.role) && this.accountSvc.user.role !== RoleTypes.Sales;
  }

  public requredMinimumRole(role: RoleTypes): boolean {
    switch (role) {
      case RoleTypes.PartnerAdmin:
        return this.isUserInRoles([RoleTypes.Admin, RoleTypes.PartnerAdmin]);
      case RoleTypes.Manager:
        return this.isUserInRoles([RoleTypes.Admin, RoleTypes.PartnerAdmin, RoleTypes.Manager]);
      case RoleTypes.LocationManager:
        return this.isUserInRoles([RoleTypes.Admin, RoleTypes.PartnerAdmin, RoleTypes.Manager, RoleTypes.LocationManager]);
      default:
        return this.isUserInRole(role);
    }
  }

  public isImpersonated(): boolean {
    return this.accountSvc.isLoggedIn && this.accountSvc.user.actsub;
  }
}
