import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject, combineLatest } from "rxjs";
import { map, take, tap } from "rxjs/operators";
import { FileResult } from "./file-result";
import { FileMimeType, FileType, ImageSize, maxFileUploadSize } from "./file-type";
import { FileUploaderModalService } from "./file-uploader-modal.service";
import { LoadingService } from "@app/core";

@Component({
  selector: "app-file-uploader-modal",
  templateUrl: "./file-uploader-modal.component.html",
  styleUrls: ["./file-uploader-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FileUploaderModalService]
})
export class FileUploaderModalComponent {
  extensions: Array<FileMimeType> = [];
  maxFileUploadSize: number = maxFileUploadSize;
  allowedImageSize: ImageSize;
  uploadType: FileType = FileType.Image;
  title: string;
  buttonText: string;
  isCropper = false;
  aspectRatio = 1;
  fileChangeEvent: Event;
  fileResult$ = new BehaviorSubject<FileResult | null>(null);
  fileBlob$ = new BehaviorSubject<Blob | null>(null);
  disabled$ = combineLatest([this.fileResult$, this.service.isUploading$]).pipe(
    tap(([result, isUploading]) => {
      if (result) {
        this.loadingService.setSpinner(isUploading);
      }
    }),
    map(([result, isUploading]) => !result || isUploading)
  );
  storageFolder = "files";
  readonly FileType = FileType;

  constructor(
    public activeModal: NgbActiveModal,
    private service: FileUploaderModalService,
    private changeDetectionRef: ChangeDetectorRef,
    private loadingService: LoadingService
  ) {}

  dismiss() {
    this.activeModal.dismiss("cancel");
  }

  confirm() {
    if (this.isCropper && this.fileBlob$.value) {
      this.service
        .uploadImage(this.fileBlob$.value, this.storageFolder)
        .pipe(take(1))
        .subscribe((result: FileResult) => {
          this.closeModal(result);
        });
    } else {
      this.closeModal(this.fileResult$.value);
    }
  }

  onUploadChanged(result: FileResult) {
    this.fileResult$.next(result);
  }

  onCroppedChanged(blob: Blob) {
    this.fileBlob$.next(blob);
  }

  setIsUploading(isUploading: boolean) {
    this.service.setIsUploading(isUploading);
    this.changeDetectionRef.detectChanges();
  }

  private closeModal(result: FileResult): void {
    this.activeModal.close(result);
  }
}
