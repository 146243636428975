import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "clearline-api";
import { Observable } from "rxjs";

@Injectable()
export class DataService extends ApiService {
  uploadFormData(url: string, formData: FormData): Observable<any> {
    url = this.getFullUrl(url);

    return this.http.post(url, formData);
  }

  getFull<T>(url: string): Observable<HttpResponse<T>> {
    url = this.getFullUrl(url);
    return this.http.get<T>(url, { observe: "response", responseType: "blob" as "json" });
  }
}
