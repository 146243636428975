export interface ApplicationStylesGetDto {
  id: number;
  name: string;
  qrConfiguration: QrCodeConfiguration;
  applicationAccessLevel: ApplicationAccessLevel;
}

export interface QrCodeConfiguration {
  primaryColor: string;
  secondaryColor: string;
  backgroundColor: string;
  primaryTextColor: string;
  secondaryTextColor: string;
  buttonTextColor: string;
  buttonBackgroundColor: string;
  logo: string;
  qrLogoType: DisplayQrCodeLogoTypes;
  hasFrame: boolean;
  logoBrandId?: number;
}

export enum DisplayQrCodeLogoTypes {
  None = 0,
  BrandLogo = 1,
  CustomLogo = 2,
  DisplayQrCodeLogo = 3
}

export enum ApplicationAccessLevel {
  Private = "private",
  Public = "public"
}
