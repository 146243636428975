import { Injectable, OnDestroy } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { BehaviorSubject, Observable, OperatorFunction, Subject, Subscription } from "rxjs";
import { finalize } from "rxjs/operators";

export interface LoaderState {
  loading: boolean;
}

@Injectable()
export class LoadingService implements OnDestroy {
  constructor(router: Router) {
    this.subscriptions.add(
      router.events.subscribe((x) => {
        if (x instanceof NavigationStart) {
          this.resetLoaders(); // Reset loaders on each navigation to avoid collisions.
        }
      })
    );
  }

  private emitter: Subject<boolean> = new Subject<boolean>();
  private subscriptions = new Subscription();
  private currentLoadersCount = 0;
  private loadingSubject = new BehaviorSubject<LoaderState>({ loading: false });

  loading$: Observable<LoaderState> = this.loadingSubject.asObservable();

  getChangeEmitter() {
    return this.emitter;
  }

  setSpinner(loading: boolean = false) {
    this.loadingSubject.next({ loading });

    this.emitter.next(loading);
  }

  showSpinner() {
    this.loadingSubject.next({ loading: true });

    this.emitter.next(true);
  }

  hideSpinner() {
    this.loadingSubject.next({ loading: false });

    this.emitter.next(false);
  }

  add() {
    if (this.currentLoadersCount++ <= 0) {
      this.showSpinner(); // Show only if wasn't started before.
    }
  }

  remove() {
    if (--this.currentLoadersCount <= 0) {
      this.hideSpinner(); // Hide only if there's no loaders anymore.
    }
  }

  pipe<T>(): OperatorFunction<T, T> {
    this.add();

    return (source: Observable<T>) => {
      return source.pipe(finalize(() => this.remove()));
    };
  }

  private resetLoaders() {
    this.currentLoadersCount = 0;
    this.hideSpinner();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
