import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl, Validators } from "@angular/forms";
import { AccountService, AppConfigService } from "@app/core";
import { AllowedDeliveryChannel, Location, PosSystemNames, PosSystems } from "@app/models";
import { AccountsService, EnumRepresentationService } from "@app/services";
import { RoleTypes } from "core-kit";
import { take } from "rxjs/operators";

@Component({
  selector: "app-delivery-method-form-control",
  templateUrl: "./delivery-method-form-control.component.html",
  styleUrls: ["./delivery-method-form-control.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DeliveryMethodFormControlComponent),
      multi: true
    }
  ]
})
export class DeliveryMethodFormControlComponent implements OnInit, ControlValueAccessor {
  @Input() isHorizontal = false;
  @Input() enabled = true;
  @Input() location: Location;
  @Input() position: string = "center";
  public deliveryMethodRepresentation: { [status: number]: string };
  public deliveryMethods = [
    { name: AllowedDeliveryChannel.qr, selected: true, disabled: true },
    { name: AllowedDeliveryChannel.email, selected: false, disabled: false }
  ];

  public formControl = new UntypedFormControl("", [Validators.required]);
  public selected: number[] = [];
  appConfig: IApplicationConfig;

  @Input() set value(value: AllowedDeliveryChannel) {
    if (value) {
      this.formControl.setValue(value);
      this.onChange(value);
      this.onTouched();
      this.changeDetectorRef.markForCheck();
    }
  }
  get value() {
    return this.formControl.value;
  }

  constructor(
    public accountService: AccountService,
    public accountsService: AccountsService,
    public changeDetectorRef: ChangeDetectorRef,
    public configSvc: AppConfigService,
    public enumRepresentationService: EnumRepresentationService
  ) {}

  ngOnInit(): void {
    this.appConfig = this.configSvc.appData;

    if (this.appConfig.enableSms) this.deliveryMethods.push({ name: AllowedDeliveryChannel.sms, selected: false, disabled: false });

    this.enumRepresentationService.init();
    this.deliveryMethodRepresentation = this.enumRepresentationService.eventDeliveryMethods;
    this.init();
  }

  private init() {
    const user = this.accountService.user;
    // todo CAP-5871 fix add pos system settings features configuration
    const isAdmin = this.accountService.isUserRequireMinRole(RoleTypes.PartnerAdmin);
    if (user.accountId || isAdmin) {
      const account$ = isAdmin ? this.accountsService.getAccount(this.location.accountId) : this.accountsService.getCurrentAccount();
      account$.pipe(take(1)).subscribe((acc) => {
        this.enabled = acc.posSystemId != PosSystems.Clover;
        this.changeDetectorRef.detectChanges();
      });
    } else this.enabled = user.posSystemId?.toLowerCase() != PosSystemNames.Clover.toLowerCase();

    if (!this.value) this.value = AllowedDeliveryChannel.qr;
    if (!(this.value & AllowedDeliveryChannel.qr)) this.value |= AllowedDeliveryChannel.qr;

    this.deliveryMethods.forEach((item) => {
      item.selected = Boolean(this.value & item.name) || item.name === AllowedDeliveryChannel.qr;
    });
  }

  private onTouched: any = () => null;
  private onChange: any = () => null;
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  public valueChanged(element, event) {
    if (event.checked) {
      element.selected = true;
    } else {
      element.selected = false;
    }
    this.updateValue();
  }

  public updateValue() {
    const selectedItems = this.deliveryMethods.filter((item) => item.selected == true);

    if (!selectedItems.some((x) => x.name === AllowedDeliveryChannel.qr)) throw new Error("Required `qr` is not selected.");
    const value = selectedItems.reduce((cur, x) => cur | x.name, 0);
    this.value = value;
  }
}
