import { AccountTemplateConfiguration } from "@app/models";
import { AccountConfigurationContentProviderDto } from "@app/models/content-providers";
import { AccountConfigurationDto as AccountConfigurationDtoCore } from "clearline-api";

export interface AccountConfigurationExternalConnectionDto {
  contentProviders?: AccountConfigurationContentProviderDto[];
}

export interface AccountConfigurationDto extends AccountConfigurationDtoCore {
  templatesConfiguration: AccountTemplateConfiguration;
  externalConnection: AccountConfigurationExternalConnectionDto;
}
