import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { TranslocoModule } from "@ngneat/transloco";
import {
  atLeastOneRequiredValidator,
  emailValidator,
  FormComponentsModule,
  phoneMask,
  phoneValidator,
  ToggleGroupWithLabelComponent
} from "ui-kit";
import { IframeButtonPostMessageDirective, IframeInputPostMessageDirective } from "../../directives";
import { ItemWithLabel } from "../../models";
import { i18nScope, provideClearlineCommonTranslocoScope } from "../../utils";

export interface SendingOptionsResult {
  customerEmail?: string;
  customerPhone?: string;
}

enum ContactType {
  Email = "email",
  Phone = "phone"
}

@Component({
  selector: "app-sending-options",
  templateUrl: "./sending-options.component.html",
  styleUrls: ["./sending-options.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    TranslocoModule,
    FormComponentsModule,
    ToggleGroupWithLabelComponent,
    IframeInputPostMessageDirective,
    IframeButtonPostMessageDirective
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideClearlineCommonTranslocoScope()]
})
export class SendingOptionsComponent {
  readonly i18nScope = i18nScope;
  readonly phoneMask = phoneMask;
  readonly ContactType = ContactType;

  selectedContactType: ContactType = ContactType.Email;
  form = new FormGroup(
    {
      customerEmail: new FormControl("", [emailValidator()]),
      customerPhone: new FormControl("", [phoneValidator(true)])
    },
    [atLeastOneRequiredValidator()]
  );
  contactTypeButtons: ItemWithLabel<ContactType>[] = [
    { label: `${i18nScope}.modal.sendingOptions.emailType`, value: ContactType.Email },
    { label: `${i18nScope}.modal.sendingOptions.phoneType`, value: ContactType.Phone }
  ];

  constructor(private dialogRef: MatDialogRef<SendingOptionsComponent>) {}

  onModeChange(value: string | number): void {
    this.selectedContactType = <ContactType>value;
  }

  onClose(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    this.dialogRef.close(this.form.getRawValue());
  }
}
