import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { RoleTypes } from "core-kit";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AccountService } from "./account.service";
import { AuthService } from "./auth.service";
import { UserPermissionService } from "./permission";

@Injectable()
export class AuthGuard {
  constructor(
    private authService: AuthService,
    private router: Router,
    private accountSvc: AccountService,
    private userPermissionService: UserPermissionService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.userPermissionService.ready$.pipe(
      map(() => {
        let result = this.authService.isLoggedIn;
        if (!result) {
          this.authService.login();
          return result;
        }

        result = this.checkRole(route, state) && this.userPermissionService.hasPermission(state.url);
        console.log(`==>> canActivate -> url ${state.url} -> ${result}`); // fixme remove after feature CAP-6676 test
        if (!result) {
          this.router.navigate(["/access-denied"]);

          return result;
        }

        result = this.isEmailVerified();
        if (!result) {
          this.router.navigate(["/verify"]);
          return result;
        }

        result = this.isManagerAccountConfigured(state);
        if (!result) {
          this.router.navigate(["/quick-setup"]);
          return result;
        }

        return result;
      })
    );
  }

  private checkRole(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let result = true;

    const roles = Object.keys(route.data).length ? (route.data.roles as string[]) : null;

    if (result && route.data && roles) {
      result = this.authService.isUserInRoles(roles);
      return result;
    }

    return true;
  }

  private isManagerAccountConfigured(state: RouterStateSnapshot) {
    const user = this.accountSvc.user;
    const result = user.doneQuickSetup || user.role !== RoleTypes.Manager;
    if (!result && !state.url.startsWith("/quick-setup")) {
      return false;
    }
    return true;
  }

  private isEmailVerified() {
    const user = this.accountSvc.user;
    const result = user && (user.email_verified || this.authService.requredMinimumRole(RoleTypes.PartnerAdmin));
    return result;
  }
}
