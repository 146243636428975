import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { TableCustomDataOptions, TableCustomDataType } from "@app/models";

enum TableCheckValue {
  Checked = "checked",
  Unchecked = "unchecked"
}

@Component({
  selector: "app-table-cell-custom-value",
  templateUrl: "./table-cell-custom-value.component.html",
  styleUrls: ["./table-cell-custom-value.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableCellCustomValueComponent {
  @Input() dataType: TableCustomDataType;
  @Input() dataOptions: TableCustomDataOptions;
  @Input() searchQuery: string;
  @Input() value: boolean | string;

  // For boolean value type:
  readonly checkIconInfo = {
    [TableCheckValue.Checked]: "mdi mdi-check font-size-18 text-success",
    [TableCheckValue.Unchecked]: ""
  };

  // todo: to add for string value type

  dataOptionsClick(event: Event): void {
    if (this.dataOptions.onClick) {
      event.preventDefault();
      event.stopPropagation();
      this.dataOptions.onClick();
    }
  }
}
