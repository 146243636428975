import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, makeStateKey, TransferState } from "@angular/core";
import { BASE_URL_TOKEN, CORE_KIT_CONFIG_TOKEN } from "../core-kit.module";
import { CoreKitConfig, IApplicationConfig, IAuthConfig } from "../models";

const APP_DATA_KEY = makeStateKey<IApplicationConfig>("appData");

@Injectable({
  providedIn: "root"
})
export class AppConfigService {
  constructor(
    private http: HttpClient,
    private transferState: TransferState,
    @Inject(BASE_URL_TOKEN) private baseUrl: string,
    @Inject(CORE_KIT_CONFIG_TOKEN) private config: CoreKitConfig
  ) {}

  public get isProduction(): boolean {
    return this.config.isProduction;
  }

  get appData(): IApplicationConfig {
    return this.transferState.get<IApplicationConfig>(APP_DATA_KEY, null as any);
  }

  getAppData(): Promise<IApplicationConfig> {
    const transferredAppData = this.transferState.get<IApplicationConfig>(APP_DATA_KEY, null as any as IApplicationConfig);
    if (!transferredAppData) {
      return this.http
        .get(this.apiUrl + "/Configuration")
        .toPromise()
        .then((config: { data?: IApplicationConfig }) => {
          const data: IApplicationConfig = config.data || ({} as IApplicationConfig);
          data.baseUrl = this.baseUrl;

          if (this.config.authConfig) {
            const authConfig = this.config.authConfig as IAuthConfig;
            if (data.domainId) {
              authConfig.clientId = authConfig.clientId + "-" + data.domainId;
            }
            authConfig.identityServerUrl = data.identityUrl;
            authConfig.logoutUrl = data.landingUrl + authConfig.logoutUrl;

            const redirectUrlKey = this.config.redirectUrlKey;
            if (redirectUrlKey && data[redirectUrlKey]) {
              authConfig.redirectUrl = data[redirectUrlKey] + authConfig.redirectUrl;
            }

            data.authConfig = authConfig;
          }

          if (this.config.selfServicePortalUrl) {
            data.selfServicePortalUrl = this.config.selfServicePortalUrl;
          }

          this.transferState.set<IApplicationConfig>(APP_DATA_KEY, data);
          return data;
        });
    }

    return new Promise((resolve, reject) => {
      resolve(transferredAppData);
    });
  }

  public get apiUrl() {
    const i = location.host.indexOf(".");
    const url = location.origin;
    const apiName = this.config.apiName;
    const baseName = this.config.baseName;

    if (apiName.includes("://")) {
      return apiName;
    } else if (i < 0) {
      return url.replace(baseName, apiName);
    } else {
      return window.location.protocol + "//" + apiName + window.location.host.substring(i);
    }
  }
}
