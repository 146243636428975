import { Pipe, PipeTransform } from "@angular/core";
import { SimpleLocation } from "@app/models";

@Pipe({
  name: "locationInfo",
  standalone: true,
  pure: true
})
export class LocationInfoPipe implements PipeTransform {
  transform(location: SimpleLocation, withName: boolean = false): string {
    const separator = ", ";
    const name: string = withName ? location.companyName : "";
    const locationZip = location.zip ? `${location.zip}` : "";
    const list: string[] = [name, location.address, location.city, location.state, locationZip];

    return list.filter((item: string) => !!item).join(separator);
  }
}
