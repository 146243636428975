import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CLEARLINE_API_CONFIG_TOKEN } from "../clearline-api.module";
import { ClearlineApiConfig } from "./config";
import { ApiResponseModel } from "./response-model";

@Injectable({
  providedIn: "root"
})
export class ApiService {
  constructor(public http: HttpClient, @Inject(CLEARLINE_API_CONFIG_TOKEN) private config: ClearlineApiConfig) {}

  public get<T>(url: string, params?: any): Observable<T> {
    url = this.getFullUrl(url);
    return this.http
      .get<ApiResponseModel<T>>(url, { params: ApiService.buildUrlSearchParams(params) })
      .pipe(map((resp) => resp?.data || (resp as unknown as T)));
  }

  public getRaw<T>(url: string, params?: any): Observable<T> {
    url = this.getFullUrl(url);
    return this.http.get<ApiResponseModel<T>>(url, { params: ApiService.buildUrlSearchParams(params) }).pipe(map((resp) => resp?.data));
  }

  public post<T>(url: string, data?: any, params?: any): Observable<T> {
    url = this.getFullUrl(url);
    return this.http.post<ApiResponseModel<T>>(url, data).pipe(map((resp) => resp?.data || (resp as unknown as T)));
  }

  public put<T>(url: string, data?: any, params?: any): Observable<T> {
    url = this.getFullUrl(url);
    return this.http
      .put<ApiResponseModel<T>>(url, data)
      .pipe(map((resp) => (typeof resp?.data !== "undefined" ? resp?.data : (resp as unknown as T))));
  }

  public delete<T>(url: string): Observable<T> {
    url = this.getFullUrl(url);
    return this.http.delete<T>(url);
  }

  public getTextByFullUrl(url: string): Observable<string> {
    return this.http.get(url, { responseType: "text" });
  }

  public uploadFile(url: string, file: File, params: Map<string, string> = new Map()): Observable<any> {
    url = this.getFullUrl(url);
    const formData = new FormData();
    formData.append("file", file, file.name);

    params.forEach((val, key) => formData.append(key, val));
    return this.http.post(url, formData);
  }

  protected getFullUrl(url: string): string {
    return `${this.config.apiUrl}/${url}`;
  }

  private static buildUrlSearchParams(params: any): HttpParams {
    let searchParams = new HttpParams();
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        searchParams = searchParams.append(key, params[key]);
      }
    }
    return searchParams;
  }
}
