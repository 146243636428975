import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, inject, Input, Output } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ItemWithLabel } from "@app/models";

type Orientation = "horizontal" | "vertical";

@Component({
  selector: "app-radio-group",
  templateUrl: "./radio-group.component.html",
  styleUrls: ["./radio-group.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioGroupComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioGroupComponent<T> implements ControlValueAccessor {
  @Input() orientation: Orientation = "horizontal";
  @Input() orientationSmallScreens: Orientation = "horizontal";
  @Input() groupName = ""; // unique name for the radio group
  @Input() title = "";
  @Input() label = "";
  @Input() isRequired = false;
  @Input() itemClassList = "form-check mb-3 me-3";
  @Input() isTranslatedLabels = false;
  @Input() items: ItemWithLabel<T>[];

  @Output() selected = new EventEmitter<T>();

  value: T;
  isDisabled = false;

  private cdr = inject(ChangeDetectorRef);

  writeValue(value: T): void {
    this.value = value;
    this.cdr.markForCheck();
  }

  registerOnChange(fn: (value: T) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.cdr.markForCheck();
  }

  onModelChange(value: T): void {
    this.value = value;
    this.selected.emit(value);
    this.onChange(value);
  }

  private onChange = (value: any) => {};
  private onTouched = () => {};
}
