export interface IKioskApplicationConfig {
  apiUrl: string;
  identityUrl: string;
  domainId: string;
  kioskClientId: string;
  kioskClientSecret: string;
  webUrl: string;
}

export interface IApplicationConfig extends IKioskApplicationConfig {
  baseUrl: string;
  cfsUrl: string;
  marketingCenterUrl: string;
  adminUIUrl: string;
  shortLinkUrl: string;
  landingUrl: string;
  selfServicePortalUrl: string;
  title: string;
  name: string;
  enableSms: boolean;
  authConfig: IAuthConfig;
  qrCodeConfiguration: IQrCodeConfig;
  smallLogoUrl: string;
  fullLogoUrl: string;
}

export interface IAuthConfig {
  identityServerUrl: string;
  clientId: string;
  scope: string;
  redirectUrl: string;
  logoutUrl: string;
}

export interface IQrCodeConfig {
  primaryColor: string;
  secondaryColor: string;
  backgroundColor: string;
  primaryTextColor: string;
  secondaryTextColor: string;
  buttonTextColor: string;
  buttonBackgroundColor: string;
}
