import { TemplateRef } from "@angular/core";

export interface ToastOptions {
  className: string;
  delay: number;
}

export interface Toast extends ToastOptions {
  content: string | TemplateRef<any>;
}
