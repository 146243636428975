import { DeliveryProviderType } from "clearline-api";
import { Platform } from "./platform";
import { PlatformAccountBase } from "./platform-account-base";

export class PlatformAccount extends PlatformAccountBase {
  id: number;
  username: string;
  password: string;
  userId?: number;
  platformId: number;
  platformAccountId?: string;
  platform?: Platform;
}
export class DeliveryPlatformAccount extends PlatformAccount {
  public deliveryProviderPlatformId: DeliveryProviderType;
}
