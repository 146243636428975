import { Injectable } from "@angular/core";
import {
  CashRegisterBaseConnectionInfo,
  CFSCommunicationChannels,
  CFSConnectionMessage,
  CFSConnectionSettings,
  CFSMessageInfo,
  CFSMessageType,
  CFSPairingState,
  CFSQrCodeInfo,
  CFSRequestResult,
  CFSScanQrCodeInfo,
  CMCCommunicationChannels,
  ConnectionStatus,
  DisplayQrCodeInfo
} from "../models";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { CommunicatorService } from "./communicator.service";
import { MessageEvent } from "pubnub";
import { StateService } from "./state.service";
import { distinctUntilChangedCallBack, extractCodeFromChannel, getFullChannelName } from "../utils";

@Injectable()
export class ManagerMessagingService {
  constructor(private communicator: CommunicatorService, private stateService: StateService) {}

  readonly connectionDelay = 8000;
  private pairingTimeout?: number;
  private cfsResetTimeout?: number;

  private pairingResultSubject$ = new BehaviorSubject<CFSRequestResult>(null as any);
  private cfsResetResultSubject$ = new BehaviorSubject<CFSRequestResult>(null as any);
  private showWidgetSrc$ = new BehaviorSubject<CFSMessageInfo>(null as any);
  pairingResult$ = this.pairingResultSubject$.asObservable().pipe(distinctUntilChanged(distinctUntilChangedCallBack));
  cfsResetResult$ = this.cfsResetResultSubject$.asObservable().pipe(distinctUntilChanged(distinctUntilChangedCallBack));
  scannedQrCode$ = new Subject<string>();
  shareHideQrCode$ = new Subject<CFSConnectionMessage>();
  state$ = this.stateService.state$;
  onShowWidget$: Observable<CFSMessageInfo> = this.showWidgetSrc$.asObservable();

  init(userId: string): void {
    this.communicator.init(userId);

    this.communicator.addListener({
      message: this.handleMessage.bind(this),
      presence: this.stateService.onPresenceEvent.bind(this.stateService)
    });
  }

  isUserIdApplied(userId: string): boolean {
    return this.communicator.isUserIdApplied(userId);
  }

  updateStateOnConnect(state: CFSPairingState): void {
    this.stateService.updateStateOnConnect(state);
  }

  runWatchingStatuses({ cfsCode, id, locationId }: CashRegisterBaseConnectionInfo, scanQrCode = false): void {
    // should be run only one on init or reconnect with new code (inner)
    if (cfsCode && id && locationId) {
      this.publishReturnStatus(cfsCode, id, locationId);
      this.subscribeOnGetStatus(cfsCode);
      this.subscribeOnHideQrCode(cfsCode);
      this.stateService.resetStatesHereNowOnDelay(getFullChannelName(CMCCommunicationChannels.RETURN_STATUS, cfsCode));
      this.stateService.hereNowOnDelay(cfsCode);

      if (scanQrCode) {
        this.subscribeOnScanQrCode(cfsCode);
      }
    }
  }

  finishPairing(): void {
    // clear pairing data on pairing handle complete
    if (this.pairingTimeout) {
      clearTimeout(this.pairingTimeout);
    }

    this.pairingResultSubject$.next(null as any);
  }

  startCashRegisterReset(code: string, settings: CFSConnectionSettings): void {
    this.publishResetCashRegister(code, settings);

    this.stateService.isCFSActive(code).then((isActive: boolean) => {
      const { cashRegisterId } = settings;
      if (isActive) {
        const subscriptionChannel = getFullChannelName(CMCCommunicationChannels.CONFIRM_RESET, code);
        const subscriptions = [subscriptionChannel];

        this.communicator.subscribe(subscriptions);
        this.publishResetCashRegister(code, settings);

        this.cfsResetTimeout = setTimeout(() => {
          console.log("start CFS reset timeout", this.cfsResetResultSubject$.value); // todo: add ConsoleLogHandlerService to common project
          if (!this.cfsResetResultSubject$.value) {
            this.cfsResetResultSubject$.next({ confirmed: false, code, errorType: "noConnection", cashRegisterId });
          }
        }, this.connectionDelay) as unknown as number;
      } else {
        this.cfsResetResultSubject$.next({ confirmed: false, code, errorType: "noConnection", cashRegisterId });
      }
    });
  }

  finishCashRegisterReset(): void {
    if (this.cfsResetTimeout) {
      clearTimeout(this.cfsResetTimeout);
    }

    this.cfsResetResultSubject$.next(null as any);
  }

  // Subscriptions:

  getSubscribedChannels(): string[] {
    return this.communicator.getSubscribedChannels();
  }

  subscribeOnGetStatus(code: string) {
    if (code) {
      const subscriptionChannel = getFullChannelName(CMCCommunicationChannels.RETURN_STATUS, code);

      console.log("===> pubnub subscribe", code); // todo: add ConsoleLogHandlerService to common project
      this.communicator.subscribe([subscriptionChannel], true);
    }
  }

  subscribeOnScanQrCode(code: string) {
    if (code) {
      const subscriptionChannel = getFullChannelName(CMCCommunicationChannels.SCAN_QR_CODE, code);

      this.communicator.subscribe([subscriptionChannel], false);
    }
  }

  subscribeOnScannedQrCode(code: string): void {
    if (code) {
      const subscriptionChannel = getFullChannelName(CMCCommunicationChannels.SCANNED_QR_CODE, code);
      const subscriptionScanQrCodeChannel = getFullChannelName(CMCCommunicationChannels.SCAN_QR_CODE, code);

      this.communicator.subscribe([subscriptionChannel, subscriptionScanQrCodeChannel]);
    }
  }

  subscribeOnHideQrCode(code: string): void {
    if (code) {
      const subscriptionChannel = getFullChannelName(CMCCommunicationChannels.HIDE_QR_CODE, code);

      this.communicator.subscribe([subscriptionChannel]);
    }
  }

  subscribeOnShowWidget(code: string) {
    if (code) {
      const subscriptionChannel = getFullChannelName(CMCCommunicationChannels.SHOW_WIDGET, code);

      this.communicator.subscribe([subscriptionChannel], false);
      console.log("Subscribed on ShowWidget channel with code: ", code); // fixme: remove console log after testing
    }
  }

  // Publish:

  publishReturnStatus(code: string, cashRegisterId: number, locationId: number): void {
    const channel = getFullChannelName(CFSCommunicationChannels.RETURN_STATUS, code);
    const settings: CFSConnectionSettings = { cashRegisterId, locationId };

    console.log(code, "returnStatus", settings); // todo: add ConsoleLogHandlerService to common project
    this.communicator.publish({ type: CFSMessageType.RETURN_STATUS, settings }, channel);
  }

  publishToPair(info: CFSConnectionMessage, pinCode: string): void {
    const channel = getFullChannelName(CFSCommunicationChannels.PAIRING, pinCode);

    this.communicator.publish({ ...info, type: CFSMessageType.PAIR }, channel);
  }

  publishUpdateCarousel(settings: CFSConnectionSettings, pinCode: string): void {
    const channel = getFullChannelName(CFSCommunicationChannels.UPDATE_CAROUSEL, pinCode);

    this.communicator.publish({ type: CFSMessageType.UPDATE_CFS_CAROUSEL, settings }, channel);
  }

  publishShowQrCode(info: CFSQrCodeInfo, code: string): void {
    const channel = getFullChannelName(CFSCommunicationChannels.SHOW_QR_CODE, code);

    this.communicator.publish(
      {
        type: CFSMessageType.SHOW_QR_CODE,
        generateQrCodeInfo: info
      },
      channel
    );
  }

  publishHideQrCode(info: DisplayQrCodeInfo, code: string, settings: CFSConnectionSettings): void {
    const channel = getFullChannelName(CFSCommunicationChannels.HIDE_QR_CODE, code);

    this.communicator.publish(
      {
        type: CFSMessageType.HIDE_QR_CODE,
        hideQrCodeInfo: info,
        settings
      },
      channel
    );
  }

  publishScanQrCode(info: CFSScanQrCodeInfo, code: string, settings: CFSConnectionSettings): void {
    this.subscribeOnScannedQrCode(code);

    const channel = getFullChannelName(CFSCommunicationChannels.SCAN_QR_CODE, code);

    this.communicator.publish(
      {
        type: CFSMessageType.SCAN_QR_CODE,
        scanQrCodeInfo: info,
        settings
      },
      channel
    );
  }

  private publishResetCashRegister(code: string, settings: CFSConnectionSettings): void {
    const channel = getFullChannelName(CFSCommunicationChannels.RESET_CASH_REGISTER, code);
    console.log("publishResetCashRegister", code, settings); // todo: add ConsoleLogHandlerService to common project

    this.communicator.publish(
      {
        type: CFSMessageType.RESET_CASH_REGISTER,
        settings
      },
      channel
    );
  }

  // Rest:
  startPairing(code: string, cashRegisterId: number, settings: CFSConnectionSettings, oldCode = "", withScan = false): void {
    this.stateService.isCFSActive(code).then((isActive: boolean) => {
      if (isActive) {
        const subscriptionChannel = getFullChannelName(CMCCommunicationChannels.CONFIRM_PAIRING, code);
        const subscriptionScanQrCodeChannel = getFullChannelName(CMCCommunicationChannels.SCAN_QR_CODE, code);
        const subscriptions = [subscriptionChannel];

        if (withScan) {
          subscriptions.push(subscriptionScanQrCodeChannel);
        }

        this.communicator.subscribe(subscriptions);
        this.publishToPair({ status: ConnectionStatus.PAIRED, connectedCode: code, settings }, code);

        this.pairingTimeout = setTimeout(() => {
          console.log("startPairing timeout", this.pairingResultSubject$.value); // todo: add ConsoleLogHandlerService to common project
          if (!this.pairingResultSubject$.value) {
            this.handlePairingFailed(code, cashRegisterId, oldCode);
          }
        }, this.connectionDelay) as unknown as number;
      } else {
        this.handlePairingFailed(code, cashRegisterId, oldCode);
      }
    });
  }

  resetScannedQrCodeResult(): void {
    this.scannedQrCode$.next("");
  }

  destroyMessages(withScan: boolean = false): void {
    if (withScan) {
      this.resetScannedQrCodeResult();
    }

    this.communicator.unsubscribeAll();
  }

  private handlePairingFailed(code: string, cashRegisterId: number, oldCode: string): void {
    this.pairingResultSubject$.next({ confirmed: false, code, cashRegisterId, oldCode });
    this.stateService.resetStatesHereNowOnDelay(getFullChannelName(CMCCommunicationChannels.RETURN_STATUS, code));
    this.stateService.hereNowOnDelay(code);
  }

  private async handleMessage(messageEvent: MessageEvent): Promise<void> {
    const message: CFSConnectionMessage = messageEvent.message;
    const { status, settings } = message;
    console.log("message event: ", messageEvent); // todo: add ConsoleLogHandlerService to common project

    if (!status) {
      this.handleMessageByType(messageEvent);
    }
  }

  private handleMessageByType(messageEvent: MessageEvent): void {
    const message: CFSConnectionMessage = messageEvent.message;
    const { type, status, settings } = message;

    switch (type) {
      case CFSMessageType.SCANNED_QR_CODE:
        this.handleScannedQrCode(message);
        break;
      case CFSMessageType.CONFIRM_PAIRING:
        this.handleVerifyCodeMessageCallback(messageEvent);
        break;
      case CFSMessageType.CONFIRM_RESET:
        this.sendCfsResetResult(messageEvent);
        break;
      case CFSMessageType.SHOW_CMC_WIDGET:
        this.showWidget(messageEvent);
        break;
      case CFSMessageType.HIDE_QR_CODE:
      case CFSMessageType.QR_CODE_SCANNED:
        this.shareHideQrCode$.next(message);
    }
  }

  private showWidget(messageEvent: MessageEvent): void {
    const { message, channel } = messageEvent;
    const cfsCode: string = extractCodeFromChannel(channel);
    const { locationId, cashRegisterId } = message.settings || {};

    this.showWidgetSrc$.next({ message, cfsCode, locationId, cashRegisterId });
  }

  private handleVerifyCodeMessageCallback(messageEvent: MessageEvent): void {
    const { message, channel } = messageEvent;
    const pin: string = message.pinCode;
    const cashRegisterId: number = message.settings?.cashRegisterId;
    const state: CFSPairingState = message.settings?.state;

    this.unsubscribeFromChannel(channel);
    this.pairingResultSubject$.next({ confirmed: true, code: pin, cashRegisterId, message, state });
  }

  private sendCfsResetResult(messageEvent: MessageEvent): void {
    const { message, channel } = messageEvent;
    const pin: string = message.pinCode;
    const cashRegisterId: number = message.settings?.cashRegisterId;

    this.unsubscribeFromChannel(channel);
    this.cfsResetResultSubject$.next({ confirmed: true, code: pin, cashRegisterId, message });
  }

  private unsubscribeFromChannel(channel: string): void {
    this.communicator.unsubscribeFromChannel(channel);
  }

  private handleScannedQrCode(message: CFSConnectionMessage): void {
    const scannedResult: string = message.result;

    if (scannedResult) {
      this.scannedQrCode$.next(scannedResult);
    }
  }
}
