import {
  ActivityByPeriod,
  ActivityByPeriodGetDto,
  AppsActivityByPeriod,
  ChartOptions,
  CustomLinks,
  CustomLinksByPeriod,
  CustomLinksByPeriodGetDto,
  EngagementOutcome,
  EngagementOutcomeByPeriod,
  EngagementOutcomeByPeriodGetDto,
  KPIs,
  SalesPersonActivityByPeriod,
  TotalActivitiesCardInfo,
  TotalCustomLinksCardInfo,
  TotalEngagementOutcomeCardInfo,
  TotalKPIsCardInfo
} from "@app/models";

import { Injectable } from "@angular/core";
import { DashboardAppActivitiesUtilities } from "@app/services/dashboard/dashboard-app-activities.utilities";
import { DashboardKPIs } from "@app/services/dashboard/dashboard-kpis.utilities";
import { DashboardLocationActivitiesUtilities } from "@app/services/dashboard/dashboard-location-activities.utilities";
import { DashboardSalesPersonActivitiesUtilities } from "@app/services/dashboard/dashboard-sales-person-activities.utilities";
import { DashboardCustomLinksUtilities } from "@app/services/dashboard/dashboard-short-links.utilities";
import { DashboardTotalActivities } from "@app/services/dashboard/dashboard-total-activities.utilities";
import { EChartsOption } from "echarts";
import { DateFilterService } from "../date-filter.service";
import { DashboardEngagementOutcomes } from "./dashboard-engagement-outcomes.utilities";

@Injectable({
  providedIn: "root"
})
export class DashboardService {
  locationActivitiesUtilities: DashboardLocationActivitiesUtilities;
  appActivitiesUtilities: DashboardAppActivitiesUtilities;

  constructor(public dateFilterService: DateFilterService) {}

  getTotalCustomLinksCardInfo(list: CustomLinks[]): TotalCustomLinksCardInfo {
    return DashboardCustomLinksUtilities.getCustomLinksForCard(list);
  }

  getTotalCustomLinksChartOptions(info: CustomLinksByPeriodGetDto<CustomLinksByPeriod>): Partial<ChartOptions> {
    return DashboardCustomLinksUtilities.getChartOptions(info, this.dateFilterService.getCurrentTimeFrame());
  }

  getTotalKPIsCardInfo(list: KPIs[]): TotalKPIsCardInfo {
    return DashboardKPIs.getKPIsForCard(list);
  }

  getKPIsChartOptions(data: KPIs[]): Partial<ChartOptions> {
    return DashboardKPIs.getChartOptions(data, this.dateFilterService.getCurrentTimeFrame());
  }

  getTotalEngagementOutcomesCardInfo(list: EngagementOutcome[]): TotalEngagementOutcomeCardInfo {
    return DashboardEngagementOutcomes.getEngagementOutcomeForCard(list);
  }

  getTotalEngagementOutcomesChartOptions(info: EngagementOutcomeByPeriodGetDto<EngagementOutcomeByPeriod>): Partial<ChartOptions> {
    return DashboardEngagementOutcomes.getChartOptions(info, this.dateFilterService.getCurrentTimeFrame());
  }

  getTotalActivitiesCardInfo(list: ActivityByPeriod[]): TotalActivitiesCardInfo {
    return DashboardTotalActivities.getActivitiesForCard(list);
  }

  getTotalActivitiesChartOptions(info: ActivityByPeriodGetDto<ActivityByPeriod>): Partial<ChartOptions> {
    return DashboardTotalActivities.getChartOptions(info, this.dateFilterService.getCurrentTimeFrame());
  }

  getLocationActivitiesChartOptions(info: ActivityByPeriodGetDto<ActivityByPeriod>): EChartsOption {
    if (!this.locationActivitiesUtilities) {
      this.locationActivitiesUtilities = new DashboardLocationActivitiesUtilities(this);
    }
    return this.locationActivitiesUtilities.getChartOptions(info);
  }

  getSalesPersonActivitiesChartOptions(info: ActivityByPeriodGetDto<SalesPersonActivityByPeriod>): Partial<ChartOptions> {
    return DashboardSalesPersonActivitiesUtilities.getChartOptions(info);
  }

  getAppActivitiesChartOptions(info: ActivityByPeriodGetDto<AppsActivityByPeriod>): EChartsOption {
    if (!this.appActivitiesUtilities) {
      this.appActivitiesUtilities = new DashboardAppActivitiesUtilities(this);
    }
    return this.appActivitiesUtilities.getChartOptions(info);
  }

  getAppActivitiesCardInfo(list: AppsActivityByPeriod[]): TotalActivitiesCardInfo {
    if (!this.appActivitiesUtilities) {
      this.appActivitiesUtilities = new DashboardAppActivitiesUtilities(this);
    }
    return this.appActivitiesUtilities.getActivitiesForCard(list);
  }

  closeOtherChartsMenu(activityType: "apps" | "locations"): void {
    if (activityType === "apps") {
      this.locationActivitiesUtilities.toolboxFeatureClickHandler();
    } else if (activityType === "locations") {
      this.appActivitiesUtilities.toolboxFeatureClickHandler();
    }
  }
}
