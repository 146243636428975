import { Directive, HostListener, Input } from "@angular/core";
import { isIframe } from "../utils";

@Directive({
  selector: "[libIframeInputPostMessage]",
  standalone: true
})
export class IframeInputPostMessageDirective {
  /**
   * The ID of the input element, used in the postMessage data.
   */
  @Input() inputId = "";

  @HostListener("focus") onFocus(): void {
    if (isIframe()) {
      this.sendPostMessage("inputFocus", this.inputId);
    }
  }

  @HostListener("blur") onBlur(): void {
    if (isIframe()) {
      this.sendPostMessage("inputBlur", this.inputId);
    }
  }

  private sendPostMessage(eventName: string, inputId: string): void {
    const targetWindow = window.top;

    if (targetWindow) {
      const data = { type: eventName, inputId: inputId };

      targetWindow.postMessage(data, "*");
    }
  }
}
