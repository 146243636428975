import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from "@angular/core";
import { DashboardService } from "@app/services";
import { ActivityByPeriodGetDto, AppsActivityByPeriod, DashboardChartOptions } from "@app/models";
import { DashboardAppActivitiesUtilities } from "@app/services/dashboard";
import { BehaviorSubject, Subject } from "rxjs";
import { auditTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { EChartsType } from "echarts/types/dist/echarts";
import * as echarts from "echarts";
import { EChartsOption } from "echarts";

@Directive({
  selector: "[appdActivitiesByAppsChart]"
})
export class ActivitiesByAppsChartDirective implements OnInit, AfterViewInit, OnDestroy {
  constructor(private dashboardService: DashboardService, private chartDom: ElementRef) {}

  @Input() set activitiesList(activitiesList: ActivityByPeriodGetDto<AppsActivityByPeriod>) {
    if (activitiesList) {
      this.handleInitChart();

      if (this.myChart) {
        this.updateChartData(activitiesList);
      }
    }
  }

  private readonly legendContainerSelector = 'svg g + g path[stroke-width="0"][stroke-dasharray][stroke-linecap="butt"]';

  private myChart: EChartsType;
  private chartOptions: EChartsOption;
  private resize$ = new BehaviorSubject<number>(null);
  private unsubscribe$ = new Subject<void>();

  private static getContainerMinHeight(legendContainerHeight: number) {
    return `${legendContainerHeight + DashboardChartOptions.legendGap + DashboardChartOptions.chartDiameter}px`;
  }

  @HostListener("window:resize", ["$event"]) handleResizeEvent(event: Event) {
    this.resize$.next((event.target as any).innerWidth);
  }

  ngOnInit(): void {
    this.handleWindowResize();
  }

  ngAfterViewInit(): void {
    this.handleInitChart();
  }

  private handleWindowResize(): void {
    let setTimeOutId: number;
    const auditTimeDelay = 350;
    const setTimeoutDelay = 150;

    this.resize$.pipe(auditTime(auditTimeDelay), distinctUntilChanged(), takeUntil(this.unsubscribe$)).subscribe(() => {
      setTimeOutId = this.handleChartOptionsOnResize(setTimeOutId, setTimeoutDelay);
    });
  }

  private handleChartOptionsOnResize(setTimeOutId: number, setTimeoutDelay: number): number {
    this.handleChartOptions(window.innerWidth);

    // setTimeout need for correct chart data update on resize window:
    if (setTimeOutId) {
      clearTimeout(setTimeOutId);
    }

    setTimeOutId = setTimeout(() => {
      this.handleChartOptions(window.innerWidth);
    }, setTimeoutDelay) as unknown as number;

    return setTimeOutId;
  }

  private handleInitChart(): void {
    if (!this.myChart && this.chartDom.nativeElement) {
      this.myChart = echarts.init(this.chartDom.nativeElement, null, {
        renderer: "svg"
      });
    }
  }

  private updateChartData(activitiesList: ActivityByPeriodGetDto<AppsActivityByPeriod>): void {
    this.chartOptions = this.dashboardService.getAppActivitiesChartOptions(activitiesList);

    this.myChart.clear();
    this.myChart.setOption(this.chartOptions);

    this.handleChartOptions(window.innerWidth);
  }

  private handleChartOptions(windowWidth: number): void {
    if (this.myChart && this.chartOptions) {
      const legendContainer: SVGAElement = this.chartDom.nativeElement.querySelector(this.legendContainerSelector);

      if (windowWidth <= DashboardChartOptions.smallDesktopScreen) {
        DashboardAppActivitiesUtilities.updateOptionsOnSmallScreen(this.chartOptions);
        this.handleChartOnLegendSizes(legendContainer);

        if (windowWidth <= DashboardChartOptions.smallMobileScreen) {
          DashboardAppActivitiesUtilities.updateOptionsOnSmallestScreen(this.chartOptions);
        }
      } else {
        DashboardAppActivitiesUtilities.updateOptionsOnLargeScreen(this.chartOptions);
        this.handleChartOnLegendSizes(legendContainer);
      }

      this.myChart.clear();
      this.myChart.setOption(this.chartOptions);
      this.myChart.resize();
    }
  }

  private handleChartOnLegendSizes(legendContainer: SVGAElement): void {
    const legendContainerHeight: number = legendContainer?.getBBox()?.height;

    if (legendContainerHeight) {
      this.chartDom.nativeElement.style.minHeight = ActivitiesByAppsChartDirective.getContainerMinHeight(legendContainerHeight);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
