export interface ApiResponseModel<T> {
  data: T;
}
export interface PageModel {
  total: number;
  page: number;
  pageSize: number;
}
export interface ApiListResponseModel<T> {
  data: T;
  page: PageModel;
}
