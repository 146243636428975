import { Injectable } from "@angular/core";
import { DataService } from "@app/core";
import { SubscriptionAddons, SubscriptionPlan, SubscriptionPlanType } from "@app/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class SubscriptionPlanService {
  private serviceUrl = "SubscriptionPlan";
  constructor(private http: DataService) {}

  // todo: should be fixed on backend side
  getListForNewRegistration(): Observable<SubscriptionPlan[]> {
    return this.http
      .get(`${this.serviceUrl}`)
      .pipe(map((x: SubscriptionPlan[]) => x.filter((o) => o.planType !== SubscriptionPlanType.ActivationBulk)));
  }

  getList(sourceType: SubscriptionPlanType): Observable<SubscriptionPlan[]> {
    return this.http.get(`${this.serviceUrl}/${sourceType}`);
  }

  getByProduct(productId: string): Observable<SubscriptionPlan> {
    return this.http.get(`${this.serviceUrl}/byProduct/${productId}`);
  }

  getLocationPlan(locationId: number): Observable<SubscriptionPlan> {
    return this.http.getRaw(`${this.serviceUrl}/locationPlan/${locationId}`);
  }

  getAddons(): Observable<SubscriptionAddons[]> {
    return this.http.get(`${this.serviceUrl}/addons`);
  }
}
