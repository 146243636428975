import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ClickPreventDirective } from "ui-kit";
import { RewardCardView } from "../../models";

@Component({
  selector: "lib-loyalty-program-rewards",
  templateUrl: "./loyalty-program-rewards.component.html",
  styleUrls: ["./loyalty-program-rewards.component.scss"],
  standalone: true,
  imports: [CommonModule, ClickPreventDirective],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoyaltyProgramRewardsComponent {
  @Input() rewards: RewardCardView[] = [];
  @Input() isReadOnly = false;

  @Output() rewardClick = new EventEmitter<RewardCardView>();
}
