<div *ngIf="dataType === 'check'">
  <span class="table-icon-wrapper">
    <i [ngClass]="value ? checkIconInfo.checked : checkIconInfo.unchecked"></i>
  </span>
</div>

<div *ngIf="dataType === 'active'">
  <span>{{ (value ? 'grid.active' : 'grid.notActive' ) | transloco }}</span>
</div>

<div *ngIf="dataType === 'status'">
  <!--              placeholder for status component-->
</div>

<div *ngIf="dataType === 'link'">
  <a
    *ngIf="dataOptions.linkUrl && dataOptions.linkName"
    [href]="dataOptions.linkUrl"
    [innerHTML]="dataOptions.linkName | highlight:searchQuery"
    (click)="dataOptionsClick($event)"
  ></a>

  <a
    *ngIf="dataOptions.redirectTo"
    [routerLink]="dataOptions.redirectTo"
    [target]="dataOptions.redirectTarget || '_self'"
    [innerHTML]="dataOptions.linkName | highlight:searchQuery"
  ></a>
</div>
