import { Campaign, SubscriptionAddons } from ".";

export interface SubscriptionPlanGetDto {
  id: number;
  name?: string;
  description?: string;
  limits: SubscriptionPlanLimits;
  options: SubscriptionPlanOptions;
  price: number;
  periodInDays: number;
  productId?: string;
  features?: SubscriptionPlanFeature[];
  planType: SubscriptionPlanType;
  widgets?: Campaign[];
}

export interface SubscriptionPlan extends SubscriptionPlanGetDto {
  locationQuantity: number;
  addons: SubscriptionAddons[];
}

export class SubscriptionPlanLimits {
  public dailyLimit: number;
  public periodLimit: number;
  public dailyWarningCap: number;
  public periodOverCapacity: number;
  public periodWarningCap: number;
  public couponLimit?: number;
  public reviewRequestLimit?: number;
  public customShortLinkLimit: number;
  public bulkSize: number;
  public socialLinkLimit: number;
  public isUnlimited: boolean;
}

export interface SubscriptionPlanOptions {
  showOnPlanChooser: boolean;
  hasDashboard: boolean;
  hasCmc: boolean;
  hasCfs: boolean;
  hasTrackingPixel: boolean;
  hasOutboundMarketing: boolean;
  hasReportingAndAnalytics: boolean;
  hasProducts: boolean;
  hasExternalContacts: boolean;
  hasPosIntegration: boolean;
  hasConnectors: boolean;
  hasCfsPrint: boolean;
  hasDistribution: boolean;
  hasCustomers: boolean;
  canViewBulks: boolean;
  canCreateBulks: boolean;
  canDeleteBulks: boolean;
  hasAdvancedTools: boolean;
  hasUrlsAndQrCodes: boolean;
  hasAppsShortUrlsAndQrs: boolean;
  hasCustomShortUrlsAndQrs: boolean;
  hasBulkLinksAndQrsList: boolean;
  hasLoyaltyProgram: boolean;
  hasLoyaltyProgramReporting: boolean;
  hasThirdPartyAnalytics: boolean;
  hasDevices: boolean;
  hasPrintableQrCodeFlyers: boolean;
  hasPrintReceipt: boolean;
  hasMarketingCampaigns: boolean;
}

export enum SubscriptionPlanType {
  Onboarding = "Onboarding",
  ActivationBulk = "ActivationBulk"
}

export interface SubscriptionPlanFeature {
  Name: string;
  Items: string[];
}
