import { Component, OnInit } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { map, mergeMap } from "rxjs/operators";

import { ActivatedRoute, Params } from "@angular/router";
import { TemplateService } from "@app/services";
import { CfsTemplate, TemplatesCarouselData } from "@app/models";
import { DefaultTemplateType } from "clearline-api";

@Component({
  selector: "app-templates-carousel-preview",
  templateUrl: "./templates-carousel-preview.component.html",
  styleUrls: ["./templates-carousel-preview.component.scss"]
})
export class TemplatesCarouselPreviewComponent implements OnInit {
  private locationId: number;

  carouselDataList$: Observable<TemplatesCarouselData | undefined>;

  constructor(private templateService: TemplateService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.setDataOnRouteParams();
  }

  private setDataOnRouteParams(): void {
    this.carouselDataList$ = this.route.params.pipe(
      mergeMap((params: Params) => {
        this.locationId = params["locationId"];

        if (!this.locationId) {
          return throwError(null);
        }

        return this.templateService.getCfsItems(this.locationId);
      }),
      map((templates: CfsTemplate[]) => {
        const templateList: CfsTemplate[] = this.getFilteredTemplates(templates);

        return {
          templateList,
          withPause: false
        };
      })
    );
  }

  private getFilteredTemplates(templates: CfsTemplate[] = []): CfsTemplate[] {
    return templates.filter((template: CfsTemplate) => {
      return template.templateType === DefaultTemplateType.CfsAndPrint || template.templateType === DefaultTemplateType.Cfs;
    });
  }
}
