import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { AuthService, Permission, UserPermissionService } from "@app/core";
import { AccountService } from "@app/core/services/account.service";
import { AppConfigService } from "@app/core/services/app-config.service";
import { PlatformAccountService, ViewAsPartnerService } from "@app/services";
import { Unsubscriber } from "@app/shared/components";
import { MetisMenu } from "metismenujs";
import { getNavMenuItemsDeep, NavMenuItem, navMenuItemsByRole } from "./menus";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent extends Unsubscriber implements OnInit, AfterViewInit, OnChanges {
  @Input() isCondensed = false;
  @ViewChild("ngxSimplebar") ngxSimplebarRef;
  @ViewChild("sideMenu") sideMenuRef: ElementRef;
  @ViewChildren("sideNavLink") sideNavLinkRef: QueryList<ElementRef<HTMLAnchorElement>>;
  readonly labelTranslationKey = "common.menu.";
  readonly digitalScreensDashboardLink = "http://app.clearlinetv.com/";
  private _menu: MetisMenu;
  logoutTooltip = this._userPermissionService.logoutTooltip;
  menuItems: NavMenuItem[] = [];

  constructor(
    private _router: Router,
    private _configSvc: AppConfigService,
    private _platformAccountSvc: PlatformAccountService,
    private _accountSvc: AccountService,
    private _authService: AuthService,
    private _userPermissionService: UserPermissionService,
    private _viewAsPartnerService: ViewAsPartnerService
  ) {
    super();
  }

  ngOnInit() {
    this.menuItems = navMenuItemsByRole[this._accountSvc.user?.role] || [];

    this._updateNestingVisibility();
    this.sub = this._userPermissionService.userPermission$.subscribe((userPermission) => {
      this._showAllMenu();

      for (const key in userPermission) {
        this._setMenuItemVisibility(key as unknown as Permission, userPermission[key]);
      }

      if (this._userPermissionService.customTransactionsReportName) {
        this._setMenuItemLabel(Permission.CustomTransactionReport, this._userPermissionService.customTransactionsReportName, true);
      }

      this._updateNestingVisibility();
      this._scrollToActiveMenu();
    });
  }

  ngAfterViewInit() {
    this._menu = new MetisMenu(this.sideMenuRef.nativeElement);
    this._setMenuActiveStates();

    this._router.events.forEach((x) => {
      if (x instanceof NavigationEnd) {
        this._setMenuActiveStates();
        this._scrollToActiveMenu();
      }
    });
  }

  ngOnChanges() {
    if ((!this.isCondensed && this.sideMenuRef) || this.isCondensed) {
      setTimeout(() => {
        this._menu = new MetisMenu(this.sideMenuRef.nativeElement);
      });
    } else if (this._menu) {
      this._menu.dispose();
    }
  }

  onRouterLink(): void {
    this._viewAsPartnerService.deactivateFeature();
  }

  onClick(fnName: string, params: unknown): void {
    this._viewAsPartnerService.deactivateFeature();

    if (typeof this[fnName] === "function") {
      this[fnName](params);
    }
  }

  /** Shows all menus except `customDisplayHandling`. */
  private _showAllMenu() {
    this.menuItems.forEach((x) => {
      if (!x.customDisplayHandling) {
        x.hidden = false;
      }
    });
  }

  private _setMenuItemVisibility(identifier: Permission, isVisible: boolean) {
    const menuItem = this._getMenuItem(identifier);
    if (menuItem) menuItem.hidden = !isVisible;
  }

  private _setMenuItemLabel(identifier: Permission, label: string, untranslatedLabel = false) {
    const menuItem = this._getMenuItem(identifier);

    if (menuItem && label) {
      menuItem.label = label;
      menuItem.untranslatedLabel = untranslatedLabel;
    }
  }

  private _getMenuItem(identifier: Permission, searchSubItems = true): NavMenuItem {
    const menuItems = searchSubItems ? getNavMenuItemsDeep(this.menuItems) : this.menuItems;
    const res = menuItems.find((x) => x.identifier === identifier) || null;
    return res;
  }

  private _updateNestingVisibility() {
    getNavMenuItemsDeep(this.menuItems).forEach((menuItem) => {
      if (menuItem.subItems) menuItem.hidden = menuItem.subItems.every((x) => x.hidden);
    });
  }

  private _setMenuActiveStates() {
    SidebarComponent._removeAllClasses("mm-active");
    SidebarComponent._removeAllClasses("mm-show");

    const links = this.sideNavLinkRef.map((x) => x.nativeElement);

    // Find active link by page path:
    let path = window.location.pathname;
    let activeLink = links.find((x) => x.pathname === path);
    if (!activeLink) {
      const slashInd = path.lastIndexOf("/");
      path = path.substring(0, slashInd);
      activeLink = links.find((x) => x.pathname === path);
    }
    if (!activeLink) return;

    // Add classes:
    activeLink.classList.add("active");
    const parent1Li = activeLink.parentElement as HTMLLIElement;
    if (!parent1Li) return;

    parent1Li.classList.add("mm-active");
    const parent2Ul = parent1Li.parentElement.closest("ul");
    if (!parent2Ul || parent2Ul.id === "side-menu") return;

    parent2Ul.classList.add("mm-show");
    const parent3 = parent2Ul.parentElement;
    if (!parent3 || parent3.id === "side-menu") return;

    parent3.classList.add("mm-active");
    const childAnchor = parent3.querySelector(".has-arrow");
    const childDropdown = parent3.querySelector(".has-dropdown");
    if (childAnchor) {
      childAnchor.classList.add("mm-active");
    }
    if (childDropdown) {
      childDropdown.classList.add("mm-active");
    }
    const parent4 = parent3.parentElement;
    if (!parent4 || parent4.id === "side-menu") return;

    parent4.classList.add("mm-show");
    const parent5 = parent4.parentElement;
    if (!parent5 || parent5.id === "side-menu") return;

    parent5.classList.add("mm-active");
    const child2Anchor = parent5.querySelector(".is-parent");
    if (!child2Anchor || child2Anchor.id === "side-menu") return;

    child2Anchor.classList.add("mm-active");
  }

  private _scrollToActiveMenu() {
    setTimeout(() => {
      const activeElement = document.getElementsByClassName("mm-active").item(0) as HTMLElement;
      const scrollElement = this.ngxSimplebarRef?.SimpleBar?.getScrollElement();
      if (activeElement == null || scrollElement == null) return;

      const offsetTop = activeElement.offsetTop;
      if (offsetTop > 500) {
        scrollElement.scrollTop = offsetTop + 300;
      }
    }, 300);
  }

  public static removeAllClasses() {
    SidebarComponent._removeAllClasses("mm-active");
    SidebarComponent._removeAllClasses("mm-show");
  }

  /** Remove `className` from all document elements. */
  private static _removeAllClasses(className: "mm-active" | "mm-show") {
    const elements = document.getElementsByClassName(className);
    while (elements[0]) {
      elements[0].classList.remove(className);
    }
  }

  /// Menu commands: uses from HTML as `this[item.function](item.param)`
  openPlatform(platformId: number) {
    this._platformAccountSvc.openPlatform(platformId, this._accountSvc.userId);
  }

  openExternalFeature(externalFeatureCode: string) {
    const url = this._platformAccountSvc.getExternalExternalFeatureUrlByCode(externalFeatureCode);
    window.open(url, "_blank");
  }

  openCmc() {
    window.open(this._configSvc.appData.marketingCenterUrl, "_blank");
  }

  openDigitalScreensDashboard() {
    window.open(this.digitalScreensDashboardLink, "_blank");
  }

  logout() {
    this._authService.logout();
  }
}
