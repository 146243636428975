import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output } from "@angular/core";
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from "@angular/forms";
import { Patterns } from "@app/shared/validators";
import { WidgetSettingsBaseComponent } from "../widget-settings-base";
import { AddToWaitlistWidgetSettings } from "@app/pages/components/widget-settings/add-to-waitlist-widget-settings/add-to-waitlist-widget-settings";
import { AuthService } from "@app/core";

@Component({
  selector: "app-add-to-waitlist-widget-settings",
  templateUrl: "./add-to-waitlist-widget-settings.component.html",
  styleUrls: ["./add-to-waitlist-widget-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: AddToWaitlistWidgetSettingsComponent
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddToWaitlistWidgetSettingsComponent),
      multi: true
    }
  ]
})
export class AddToWaitlistWidgetSettingsComponent extends WidgetSettingsBaseComponent implements OnInit {
  private _widget: AddToWaitlistWidgetSettings;
  public validators: Array<ValidatorFn> = [Validators.required, Validators.pattern(Patterns.url), Validators.maxLength(2000)];
  @Output() widgetSettingsReady = new EventEmitter();
  @Input() set widgetSettingsJson(value: string) {
    if (value) this.requestReviewWidgetSetting = JSON.parse(value) as AddToWaitlistWidgetSettings;
  }

  public set requestReviewWidgetSetting(value: AddToWaitlistWidgetSettings) {
    if (value && !this._widget) {
      setTimeout(() => {
        this.form.controls.addToWaitListUrl.setValue(value.addToWaitListUrl);
      });
    }
    this.onChange(value);
    this.changeDetectorRef.markForCheck();
  }

  constructor(private changeDetectorRef: ChangeDetectorRef, public authService: AuthService) {
    super(authService);
  }
  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      addToWaitListUrl: new UntypedFormControl("", [Validators.required, Validators.pattern(Patterns.url), Validators.maxLength(2000)])
    });
    this.widgetSettingsReady.emit();
  }
}
