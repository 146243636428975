import { Injectable } from "@angular/core";

import { Platform, PlatformAccount, PlatformTypes, ExternalFeature, ExternalFeatureCategories, RoleTypes } from "@app/models";
import { DataService } from "@app/core/services/data.service";
import { Observable } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { VendastaService } from "./vendasta.service";
import { AccountService, AppConfigService } from "@app/core";

@Injectable({ providedIn: "root" })
export class PlatformAccountService {
  private serviceUrl = "platform";

  constructor(
    private http: DataService,
    private configSvc: AppConfigService,
    private accountSvc: AccountService,
    private vendastaService: VendastaService
  ) {}

  getPlatformAccountById(id: number): Observable<PlatformAccount> {
    return this.http.get(`${this.serviceUrl}/${id}`);
  }
  getAll(): Observable<Platform[]> {
    return this.http.get(`${this.serviceUrl}/allPlatforms`);
  }
  getPlatformAccounts(userId: number): Observable<PlatformAccount[]> {
    return this.http.get(`${this.serviceUrl}/userPlatformAccouns/${userId}`);
  }
  getLocationPlatformAccounts(locationId: number): Observable<PlatformAccount[]> {
    return this.http.get(`${this.serviceUrl}/platformAccouns/location/${locationId}`);
  }
  getBrandPlatformAccounts(brandId: number): Observable<PlatformAccount[]> {
    return this.http.get(`${this.serviceUrl}/brandPlatformAccouns/${brandId}`);
  }
  getAvaliableUserPlatforms(userId: number): Observable<Platform[]> {
    return this.getPlatformAccounts(userId).pipe(
      mergeMap((accounts) => {
        return this.getAll().pipe(map((platforms) => platforms.filter((p) => !accounts.some((ac) => ac.platformId === p.id))));
      })
    );
  }
  getAvaliableBrandPlatforms(brandId: number): Observable<Platform[]> {
    return this.getBrandPlatformAccounts(brandId).pipe(
      mergeMap((accounts) => {
        return this.getAll().pipe(map((platforms) => platforms.filter((p) => !accounts.some((ac) => ac.platformId === p.id))));
      })
    );
  }
  addPlatformAccount(account: PlatformAccount) {
    return this.http.post(`${this.serviceUrl}/addAccount`, account);
  }
  addBrandPlatformAccount(brandId: number, account: PlatformAccount) {
    return this.http.post(`${this.serviceUrl}/addBrandAccount/${brandId}`, account);
  }
  updatePlatformAccount(account: PlatformAccount) {
    return this.http.post(`${this.serviceUrl}/updateAccount`, account);
  }
  deletePlatformAccount(accountId: number) {
    return this.http.delete(`${this.serviceUrl}/deletePlatformAccount/${accountId}`);
  }

  getConfiguredExternalFeatures(): Observable<ExternalFeature[]> {
    return this.http.get(`${this.serviceUrl}/allPredefinedExternalFeatures`);
  }
  getConfiguredAccountExternalFeatures(categoryId: ExternalFeatureCategories): Observable<ExternalFeature[]> {
    return this.http.get(`${this.serviceUrl}/externalFeatures/account/configured/${categoryId}`);
  }
  getConfiguredExternalFeaturesWithPlatform(): Observable<ExternalFeature[]> {
    return this.getConfiguredExternalFeatures().pipe(
      mergeMap((prs) =>
        this.getAll().pipe(
          map((pls) => {
            const plObj = {};
            pls.forEach((pl) => (plObj[pl.id] = pl));
            return prs.map((pr) => {
              pr.platform = plObj[pr.platformId];
              return pr;
            });
          })
        )
      )
    );
  }

  navigateToExternalFeature(accountId: string, externalFeatureCode: string) {
    const externalFeatureUrl = `${this.configSvc.appData.identityUrl}/RetentionCenter/${accountId}/externalFeature/${externalFeatureCode}`;
    window.open(externalFeatureUrl, "_blank");
  }
  openPlatform(platformId: number, userId: number) {
    this.getPlatformAccounts(userId).subscribe((pa) => {
      const vendastaPlatform = pa.find((p) => p.platformId === PlatformTypes.Vendasta);
      if (vendastaPlatform && !this.accountSvc.isUserInRole(RoleTypes.Admin)) {
        this.vendastaService.getUserAccount(vendastaPlatform.username).subscribe((accId) => {
          let externalFeatureCode = "";
          if (platformId === PlatformTypes.TwoReward) externalFeatureCode = "2reward";
          if (platformId === PlatformTypes.SmsUSA) externalFeatureCode = "globalsms";
          if (platformId === PlatformTypes.SmsGlobal) externalFeatureCode = "globallogin";
          this.navigateToExternalFeature(accId, externalFeatureCode);
        });
      } else {
        let returnUrl = "";
        if (platformId === PlatformTypes.TwoReward) returnUrl = "https://login.2reward.me/index.php?pag=dashboard";
        if (platformId === PlatformTypes.SmsUSA) returnUrl = "https://login.clearlinemobile.com/responsive/messaging.asp";
        if (platformId === PlatformTypes.SmsGlobal) returnUrl = "http://sms.clearlinemobile.com/analyze/dashboard/";
        window.open(returnUrl);
      }
    });
  }
  getCouponExternalSetupUrl() {
    return `${this.configSvc.appData.identityUrl}/ExternalFeature/digitalcoupons`;
  }
  getExternalExternalFeatureUrlByCode(externalFeatureCode: string) {
    return `${this.configSvc.appData.identityUrl}/ExternalFeature/${externalFeatureCode}`;
  }
}
