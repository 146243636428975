import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DataService } from "@app/core/services/data.service";
import { CreateNewRegistrationModel, NewRegistrationModel, NewRegistrationType } from "@app/models";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class NewRegistrationService {
  serviceUrl = "newRegistration";

  constructor(private http: DataService) {}

  getAll(partnerIds: number[] = [], typeIds: NewRegistrationType[] = []): Observable<NewRegistrationModel[]> {
    const params = this.getHttpParams(partnerIds, typeIds);

    return this.http.get(`${this.serviceUrl}?${params.toString()}`);
  }

  getById(id: number): Observable<NewRegistrationModel> {
    return this.http.get(`${this.serviceUrl}/${id}`);
  }

  delete(id: number): Observable<void> {
    return this.http.delete(`${this.serviceUrl}/${id}`);
  }

  create(createModel: CreateNewRegistrationModel): Observable<number> {
    return this.http.post(`${this.serviceUrl}`, createModel);
  }

  private getHttpParams(partnerIds: number[], typeIds: NewRegistrationType[]): HttpParams {
    let params = new HttpParams();

    partnerIds.forEach((id) => {
      params = params.append("partnerIds", id.toString());
    });

    typeIds.forEach((id) => {
      params = params.append("registrationTypes", id.toString());
    });

    return params;
  }
}
