import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { PrintableTemplateComponent, PrintTemplateComponent, TemplatePageComponent, TemplatePreviewPageComponent } from "./template-page";
import { TemplateRoutingModule } from "./template-routing.module";
import { TemplatesCarouselComponent, TemplatesCarouselPreviewComponent } from "./templates-carousel-preview";
import { CfsTemplateComponent } from "clearline-common";

@NgModule({
  declarations: [
    PrintTemplateComponent,
    TemplatePreviewPageComponent,
    TemplatePageComponent,
    PrintableTemplateComponent,
    TemplatesCarouselPreviewComponent,
    TemplatesCarouselComponent
  ],
  imports: [CommonModule, TemplateRoutingModule, CarouselModule, CfsTemplateComponent]
})
export class TemplateModule {}
