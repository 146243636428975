import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { TimeFrameTypes } from "./enums";

export interface DatePickerRangeInfo {
  fromDate: NgbDate;
  toDate: NgbDate;
}

export interface PeriodType {
  timeFrame?: TimeFrameTypes;
  rangeInfo?: DatePickerRangeInfo;
}
