import { Injectable } from "@angular/core";
import { Account } from "@app/models";
import { AccountsService as AccountsServiceCore } from "clearline-api";
import { BehaviorSubject, Observable } from "rxjs";
import { take, tap } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class AccountsService extends AccountsServiceCore {
  private currentAccount$ = new BehaviorSubject<Account>(null);

  getCurrentAccount(): Observable<Account> {
    return this.currentAccount$.value
      ? this.currentAccount$.pipe(take(1))
      : this.api.get(`${this.serviceUrl}/current`).pipe(tap((account: Account) => this.currentAccount$.next(account)));
  }
}
