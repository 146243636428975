import { Injectable } from "@angular/core";
import { DataService } from "@app/core";
import { Observable } from "rxjs";
import {
  AccountPosSystemSettings,
  AccountPosSystemSettingsDto,
  LocationCustomShortLinkDto,
  LocationCustomShortLinkResultDto,
  POSSystemInfo,
  PosSystemReceiptPromotionConfigurationDto
} from "@app/models";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class PosSystemService {
  constructor(private http: DataService) {}
  private serviceUrl = "PosSystem";

  getPosSystems(): Observable<POSSystemInfo[]> {
    return this.http.get(`${this.serviceUrl}`);
  }

  getPosSystemByCode(posSystemById: string): Observable<POSSystemInfo> {
    return this.http.get(`${this.serviceUrl}/code/${posSystemById}`);
  }

  getPosSystemById(posSystemById: number): Observable<POSSystemInfo> {
    return this.http.get(`${this.serviceUrl}/${posSystemById}`);
  }

  getAccountPosSystemSettings(): Observable<AccountPosSystemSettings> {
    return this.http
      .get(`${this.serviceUrl}/settings`)
      .pipe(map((data: AccountPosSystemSettingsDto) => AccountPosSystemSettings.fromDto(data)));
  }

  savePosSettings(data: AccountPosSystemSettings): Observable<AccountPosSystemSettings> {
    const dto: AccountPosSystemSettingsDto = { ...data, configurationJson: data.configuration };
    return this.http
      .post(`${this.serviceUrl}/settings`, dto)
      .pipe(map((data: AccountPosSystemSettingsDto) => AccountPosSystemSettings.fromDto(data)));
  }

  getReceiptSettings(): Observable<PosSystemReceiptPromotionConfigurationDto> {
    return this.http.get(`${this.serviceUrl}/receiptPromotion`);
  }

  saveReceiptSettings(data: PosSystemReceiptPromotionConfigurationDto): Observable<PosSystemReceiptPromotionConfigurationDto> {
    return this.http.post(`${this.serviceUrl}/receiptPromotion`, data);
  }

  getReceiptCustomUrl(): Observable<LocationCustomShortLinkResultDto[]> {
    return this.http.get(`${this.serviceUrl}/receiptPromotion/customUrls`);
  }

  saveReceiptCustomUrl(list: LocationCustomShortLinkDto[]): Observable<LocationCustomShortLinkDto[]> {
    return this.http.post(`${this.serviceUrl}/receiptPromotion/customUrls`, list);
  }
}
