<header id="page-topbar" *transloco="let t; read: 'common.topBar'">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a class="logo logo-light">
          <span class="logo-sm">
            <img src="{{ smallLogoUrl }}" alt="" height="22" />
          </span>
          <span class="logo-lg">
            <img src="{{ fullLogoUrl }}" alt="" height="64" />
          </span>
        </a>
      </div>

      <button
        type="button"
        class="btn btn-sm px-3 font-size-16 header-item waves-effect"
        id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)"
      >
        <img src="assets/images/sidebar-icon/toolge-icon.svg" alt="" />
      </button>
    </div>

    <div class="d-flex">
      <div class="dropdown d-inline-block" ngbDropdown>
        <button
          type="button"
          class="btn header-item waves-effect"
          id="page-header-user-dropdown"
          ngbDropdownToggle
          (click)="openProfileMenu()"
        >
          <span class="ms-1 font-bold" key="t-henry">{{ user?.name }} {{ user?.family_name }}</span>
          <i class="mdi mdi-chevron-down"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <!-- <a class="dropdown-item" href="#"><i class="bx bx-user font-size-16 align-middle me-1"></i> <span key="t-profile">Profile</span></a>
            <a class="dropdown-item d-block" href="#"><span class="badge bg-success float-end">11</span><i class="bx bx-wrench font-size-16 align-middle me-1"></i> <span key="t-settings">Settings</span></a>

            -->
          <a class="dropdown-item" (click)="goToProfile()"
            ><i class="bx bx-user font-size-16 align-middle me-1"></i> <span key="t-logout">{{ t("userProfile") }}</span></a
          >

          <div class="dropdown-divider"></div>

          <ng-container *ngIf="subscription && subscription.periodLimit">
            <div class="text-item">
              <b>{{ t("creditBalance") }}</b>
            </div>
            <div class="text-item" *ngIf="!subscription.isTrial">
              {{ t("p&bDate") }} <i>{{ periodEndDate }}</i>
            </div>
            <div class="text-item" *ngIf="subscription.isTrial" style="color: red">{{ periodEndDays }} {{ t("trialDaysLeft") }}</div>
            <!-- <div class="text-item">
              {{t("dailyLimit")}} <i>{{t("used")}} <b>{{subscription.dailyCount}}</b> {{t("of")}} <b>{{subscription.dailyLimit}}</b> {{t("messages")}}</i>
            </div>-->
            <div class="text-item">
              {{ subscription.isTrial ? t("trialLimit") : t("monthlyLimit") }}
              <i
                >{{ t("used") }} <b>{{ subscription.count }}</b> {{ t("of") }} <b>{{ subscription.periodLimit }}</b> {{ t("messages") }}</i
              >
            </div>

            <div *ngIf="isAdmin || (isManager && hasCmc)">
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="{{ marketingCenterUrl }}" target="blank">
                <i class="bx bx-dollar-circle font-size-16 align-middle me-1"></i>
                <span>{{ t("marketingCenter") }}</span>
              </a>

              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="{{ selfServicePortalUrl }}" target="blank">
                <i class="bx bx-dollar-circle font-size-16 align-middle me-1"></i>
                <span>{{ t("myPlan") }}</span>
              </a>
            </div>

            <div class="dropdown-divider"></div>
          </ng-container>

          <a class="dropdown-item text-danger" (click)="logout()"
            ><i class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i> <span key="t-logout">{{ t("logout") }}</span></a
          >

          <div class="dropdown-divider"></div>

          <a class="dropdown-item" href="https://clearline.crunch.help" target="_blank">
            <i class="bx bx-help-circle font-size-16 align-middle me-1"></i>
            <span>{{ t("help&FAQ") }}</span>
          </a>
        </div>
      </div>

      <!-- <div class="dropdown d-inline-block" >
          <button type="button" class="btn header-item noti-icon right-bar-toggle waves-effect">
            <i class="bx bx-cog bx-spin"></i>
          </button>
        </div> -->
    </div>
  </div>
</header>
