import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../core";
import { Account, AccountSettingsGetDto, AccountSettingsPostDto } from "../dto/account";

@Injectable({ providedIn: "root" })
export class AccountsService {
  protected serviceUrl = "account";

  constructor(protected api: ApiService) {}

  getAccounts(partnerIds: number[] = []): Observable<Account[]> {
    const queryParams = partnerIds.map((number) => `partnerIds=${number}`).join("&");
    const queryString = partnerIds.length ? `?${queryParams}` : "";

    return this.api.get(`${this.serviceUrl}${queryString}`);
  }

  getCurrentAccount(): Observable<Account> {
    return this.api.get(`${this.serviceUrl}/current`);
  }

  getAccount(accountId: number): Observable<Account> {
    return this.api.get(`${this.serviceUrl}/${accountId}`);
  }

  updateAccount(account: Account): Observable<null> {
    return this.api.put(`${this.serviceUrl}/${account.id}`, account);
  }

  getAccountSettings(): Observable<AccountSettingsGetDto> {
    return this.api.get(`${this.serviceUrl}/settings`);
  }

  updateAccountSettings(settings: AccountSettingsPostDto): Observable<void> {
    return this.api.post(`${this.serviceUrl}/settings`, settings);
  }
}
