<div class="btn-group" ngbDropdown container="body">
  <button ngbDropdownToggle role="button" id="{{ 'dropdown-' + row.id }}" class="btn dropdown-toggle card-drop" type="button">
    <i class="mdi mdi-dots-vertical font-size-18"></i>
  </button>

  <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end" *transloco="let tc; read: 'common.controls'">
    <button
      *ngFor="let action of actionList"
      ngbDropdownItem
      [disabled]="action.disabled ? action.disabled(row) : false"
      (click)="onAction(action.actionType, action.customTypeValue)"
      [ngSwitch]="action.actionType"
      [class.text-danger]="action.actionType === 'delete' && !(action.disabled && action.disabled(row))">
      <ng-container *ngIf="action.label">{{ action.label | transloco }}</ng-container>
      <ng-container *ngIf="!action.label">
        <ng-container *ngSwitchCase="'edit'">{{ tc('edit') }}</ng-container>
        <ng-container *ngSwitchCase="'view'">{{ tc('view') }}</ng-container>
        <ng-container *ngSwitchCase="'delete'">{{ tc('delete') }}</ng-container>
      </ng-container>
    </button>
  </div>
</div>
