import { Injectable } from "@angular/core";
import {
  DigitalAssetGetDto,
  DigitalAssetService,
  MediaContentDto,
  MediaContentService,
  NULL_OBSERVABLE,
  DigitalAssetScreenSize,
  TemplateService
} from "clearline-api";
import { combineLatest, forkJoin, Observable, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { ContentSettings, ScreenSizesJsonAsset, Template, TemplatePreviewOptions } from "../../models";

@Injectable({ providedIn: "root" })
export class TemplatePreviewService {
  constructor(
    private templateService: TemplateService,
    private mediaContentService: MediaContentService,
    private digitalAssetService: DigitalAssetService
  ) {}

  getLottieOptions(template: Template): Observable<TemplatePreviewOptions | null> {
    const { configuration } = template;

    const configurationOptions$ = (_template: Template) => {
      const _configuration = _template.configuration;
      const _templateProperties = _template.templateProperties;
      const { mediaContentId, defaultParameters } = _configuration || {};

      return mediaContentId
        ? this.mediaContentService.getMediaContent(mediaContentId).pipe(
            mergeMap((mediaContent: MediaContentDto) => this.getOptionByMediaContent(mediaContent)),
            map((options: TemplatePreviewOptions | null) => {
              return { ...options, contentSettings: _templateProperties, defaultParameters } as TemplatePreviewOptions;
            }),
            catchError(() => NULL_OBSERVABLE)
          )
        : NULL_OBSERVABLE;
    };
    const restOptions$ = (_template: Template) => {
      const contentSettings: ContentSettings = _template.templateProperties as ContentSettings;
      const assetId = contentSettings?.assetId;

      return assetId
        ? this.digitalAssetService.getAsset(assetId).pipe(
            mergeMap((asset: DigitalAssetGetDto) => {
              const contentUrl = (asset as DigitalAssetGetDto)?.contentUrl;

              return combineLatest([of(asset), this.getContentByUrl$(contentUrl)]);
            }),
            map(([digitalAsset, json]: [DigitalAssetGetDto, string]) => {
              const options: TemplatePreviewOptions = { contentSettings, digitalAsset, json };

              return digitalAsset ? options : null;
            }),
            catchError(() => NULL_OBSERVABLE)
          )
        : NULL_OBSERVABLE;
    };

    return configuration?.mediaContentId ? configurationOptions$(template) : restOptions$(template);
  }

  getOptionByMediaContent(mediaContent: MediaContentDto): Observable<TemplatePreviewOptions | null> {
    const digitalAssets: DigitalAssetGetDto[] = mediaContent?.digitalAssets || [];
    const contentUrlAsset: ScreenSizesJsonAsset = digitalAssets.reduce((acc: ScreenSizesJsonAsset, item: DigitalAssetGetDto) => {
      if (item.configuration) {
        const screenSize: DigitalAssetScreenSize = item.configuration.screenSize;

        acc[screenSize] = item.contentUrl;
      }

      return acc;
    }, {} as ScreenSizesJsonAsset);

    return this.getScreenSizesJsonAsset$(contentUrlAsset).pipe(
      map((screenSizesJsonAsset: ScreenSizesJsonAsset) => {
        return { mediaContent, screenSizesJsonAsset };
      }),
      catchError(() => NULL_OBSERVABLE)
    );
  }

  private getScreenSizesJsonAsset$(contentUrlAsset: ScreenSizesJsonAsset): Observable<ScreenSizesJsonAsset> {
    const jsonList$: Observable<ScreenSizesJsonAsset | null>[] = Object.keys(contentUrlAsset).map((key: string) => {
      const contentUrl: string = contentUrlAsset[key as DigitalAssetScreenSize] as string;

      return this.getContentByUrl$(contentUrl).pipe(map((json: string) => (json ? { [key as DigitalAssetScreenSize]: json } : null)));
    });

    return forkJoin(jsonList$).pipe(
      map((assetList) => {
        return assetList.reduce((acc, item: ScreenSizesJsonAsset | null) => {
          if (item) {
            acc = { ...acc, ...item };
          }
          return acc;
        }, {} as ScreenSizesJsonAsset) as ScreenSizesJsonAsset;
      })
    );
  }

  private getContentByUrl$(contentUrl: string = ""): Observable<string> {
    if (contentUrl) {
      return this.templateService.getContentByUrl(contentUrl as string).pipe(catchError(() => of("")));
    }

    return of("");
  }
}
