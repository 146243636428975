import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output } from "@angular/core";
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from "@angular/forms";
import { ScheduleAppointmentWidgetSettings } from "@app/pages/components/widget-settings/schedule-appointment-widget-settings/schedule-appointment-widget-settings";
import { Patterns } from "@app/shared/validators";
import { TranslocoService } from "@ngneat/transloco";
import { WidgetSettingsBaseComponent } from "../widget-settings-base";
import { AuthService } from "@app/core";

export class Reminder {
  checked: boolean;
  value: number;

  name: string;
}

@Component({
  selector: "app-schedule-appointment-widget-settings",
  templateUrl: "./schedule-appointment-widget-settings.component.html",
  styleUrls: ["./schedule-appointment-widget-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ScheduleAppointmentWidgetSettingsComponent
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ScheduleAppointmentWidgetSettingsComponent),
      multi: true
    }
  ]
})
export class ScheduleAppointmentWidgetSettingsComponent extends WidgetSettingsBaseComponent implements OnInit {
  reminders: Reminder[] = [];
  public validators: Array<ValidatorFn> = [Validators.required, Validators.pattern(Patterns.url), Validators.maxLength(2000)];
  private _widget: ScheduleAppointmentWidgetSettings;
  @Output() widgetSettingsReady = new EventEmitter();
  @Input() set widgetSettingsJson(value: string) {
    if (value) this.widgetSetting = JSON.parse(value) as ScheduleAppointmentWidgetSettings;
  }

  public set widgetSetting(value: ScheduleAppointmentWidgetSettings) {
    if (value && !this._widget) {
      setTimeout(() => {
        this.form.controls.appointmentUrl.setValue(value.appointmentUrl);
        this.form.controls.remindInDays.setValue(value.remindInDays);
        this.reminders.forEach((reminder) => {
          reminder.checked = ((2 ** reminder.value) & value.remindInDays) == 2 ** reminder.value;
        });
        this.changeDetectorRef.detectChanges();
      });
    }
    this.onChange(value);
    this.changeDetectorRef.markForCheck();
  }

  constructor(private changeDetectorRef: ChangeDetectorRef, private translateSvc: TranslocoService, public authService: AuthService) {
    super(authService);
  }

  ngOnInit(): void {
    this.reminders = [
      { checked: false, value: 0, name: this.translateSvc.translateObject("widgets.scheduleAppointment.sday") },
      { checked: false, value: 1, name: "1 " + this.translateSvc.translateObject("widgets.scheduleAppointment.day") },
      { checked: false, value: 2, name: "2 " + this.translateSvc.translateObject("widgets.scheduleAppointment.days") },
      { checked: false, value: 3, name: "3 " + this.translateSvc.translateObject("widgets.scheduleAppointment.days") }
    ];
    this.form = new UntypedFormGroup({
      appointmentUrl: new UntypedFormControl("", [Validators.required, Validators.pattern(Patterns.url), Validators.maxLength(2000)]),
      remindInDays: new UntypedFormControl(0)
    });

    this.widgetSettingsReady.emit();
  }

  onChangeSelection(value: number) {
    let remindInDays = 0;
    this.reminders.forEach((reminder) => {
      if (reminder.checked) {
        remindInDays = this.form.controls.remindInDays.value | Math.pow(2, value);
      }
      if (reminder.checked) {
        remindInDays = this.form.controls.remindInDays.value ^ Math.pow(2, value);
      }
    });
    this.form.controls.remindInDays.setValue(remindInDays);
  }
}
